import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { setAlert } from '../../actions/alert';
import { register } from '../../actions/auth';
import PropTypes from 'prop-types';
import {
  Card,
  CardBody,
  Form,
  Input,
  InputGroup,
  Button,
  Label,
  FormGroup
} from 'reactstrap';
import './Registration.scss';
import LargeAlert from '../layout/LargeAlert';
import PasswordStrengthBar from 'react-password-strength-bar';
import ToggleSwitch from '../layout/ToggleSwitch';
import passwordValidator from 'fnbpasswordvalidator';
import * as EmailValidator from 'email-validator';

const Register = ({ setAlert, register, isAuthenticated }) => {
  const [twoWayComms, setTwoWayComms] = useState(false);

  const [formData, setFormData] = useState({
    parent1_first_name: '',
    parent1_last_name: '',
    parent2_first_name: '',
    parent2_last_name: '',
    parent2_email: '',
    accountType: 'adoptiveParent',
    email: '',
    password: '',
    password2: '',
    bp_first_name: '',
    bp_last_name: '',
    bpemail: '',
    bpmessage: '',
    subscriptiontype: 'Annual',
    subscriptionactive: 'false',
    subscriptionCreation: 'web',
    twowaycommunication: twoWayComms,
    privatemessages: false
  });

  const {
    parent1_first_name,
    parent1_last_name,
    parent2_first_name,
    parent2_last_name,
    parent2_email,
    email,
    password,
    password2,
    bp_first_name,
    bp_last_name,
    bpemail,
    bpmessage,
    subscriptiontype,
    subscriptionactive,
    subscriptionCreation,
    privatemessages,
    twowaycommunication,
    accountType
  } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const [firstFocus, setFirstFocus] = React.useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);

    function CheckPassword() {
      let passwordDetails = passwordValidator.validate(password, {
        prohibitedWords: [parent1_first_name, parent1_last_name, email],
        min: 8,
        specialCharacters: true
      });
      if (!passwordDetails.valid) {
        setAlert(passwordDetails.message, 'danger');
      } else {
        register({
          parent1_first_name,
          parent1_last_name,
          parent2_first_name,
          parent2_last_name,
          parent2_email,
          email,
          password,
          bp_first_name,
          bp_last_name,
          bpemail,
          bpmessage,
          subscriptiontype,
          subscriptionactive,
          privatemessages,
          twowaycommunication,
          subscriptionCreation,
          accountType
        });
      }
    }

    if (password !== password2) {
      setAlert('Passwords do not match', 'danger');
    } else if (
      parent2_email !== '' &&
      !EmailValidator.validate(parent2_email)
    ) {
      setAlert('Other Adoptive Parent Email Address is Not Valid', 'danger');
    } else if (!EmailValidator.validate(email)) {
      setAlert('Email Address is Not Valid', 'danger');
    } else if (!EmailValidator.validate(bpemail)) {
      setAlert('Birth Parent Email Address is Not Valid', 'danger');
    } else {
      CheckPassword();
    }
  };

  if (isAuthenticated) {
    return <Redirect to="/timeline" />;
  }

  function toggleTwoWayComms() {
    setTwoWayComms(!twoWayComms);
    setFormData({ ...formData, twowaycommunication: !twowaycommunication });
  }

  return (
    <Fragment>
      <LargeAlert />
      <div className="fullPage">
        <div className="text-center form-container">
          <Card className="form-card">
            <CardBody>
              <Form
                className="form registration-form center-form"
                onSubmit={(e) => onSubmit(e)}
              >
                <center>
                  <h1 className="panel-header" style={{ paddingBottom: '0px' }}>
                    Create an Account
                  </h1>
                  <p className="lead">
                    <i
                      className="fas fa-user"
                      style={{ paddingTop: '0px' }}
                    ></i>{' '}
                    Account Type: Individual
                  </p>
                  <div className="separator">Your Info</div>
                  <FormGroup>
                    <Label className="inputLabel">First Name</Label>
                    <InputGroup
                      className={
                        'no-border Input-lg registration-input-group' +
                        (firstFocus ? ' Input-group-focus' : '')
                      }
                    >
                      <Input
                        onFocus={() => setFirstFocus(true)}
                        onBlur={() => setFirstFocus(false)}
                        type="text"
                        name="parent1_first_name"
                        id="apFirstName"
                        value={parent1_first_name}
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </InputGroup>
                  </FormGroup>
                  <Label className="inputLabel">Last Name</Label>
                  <InputGroup
                    className={'no-border Input-lg registration-input-group'}
                  >
                    <Input
                      type="text"
                      name="parent1_last_name"
                      value={parent1_last_name}
                      onChange={(e) => onChange(e)}
                      required
                    />
                  </InputGroup>
                  <Label className="inputLabel">Email Address</Label>
                  <InputGroup
                    className={'no-border Input-lg registration-input-group'}
                  >
                    <Input
                      onFocus={() => setFirstFocus(true)}
                      onBlur={() => setFirstFocus(false)}
                      type="email"
                      name="email"
                      value={email}
                      onChange={(e) => onChange(e)}
                      required
                    ></Input>
                  </InputGroup>
                  <p style={{ fontSize: '1em' }} className="ac-text">
                    In order to protect your account, please make sure your
                    password:
                    <ul className="passwordOptions">
                      <li className="passwordOptions">
                        Is longer than 8 characters
                      </li>
                      <li className="passwordOptions">
                        Contains 1 uppercase letter, 1 lowercase letter, 1
                        number and a special character {`(!"#$%&'*+)`}
                      </li>
                      <li className="passwordOptions">
                        Does not match or contain your name or email address,
                        e.g. do not use 'name123'.
                      </li>
                    </ul>
                  </p>
                  <Label className="inputLabel">Password</Label>
                  <InputGroup
                    className={'no-border Input-lg registration-input-group'}
                  >
                    <Input
                      type="password"
                      name="password"
                      minLength="8"
                      value={password}
                      onChange={(e) => onChange(e)}
                    />
                  </InputGroup>
                  <center>
                    <PasswordStrengthBar
                      password={password}
                      style={{ width: '60%', marginTop: '-5px' }}
                    />
                  </center>
                  <Label className="inputLabel">Confirm Password</Label>
                  <InputGroup
                    className={'no-border Input-lg registration-input-group'}
                  >
                    <Input
                      type="password"
                      name="password2"
                      minLength="8"
                      value={password2}
                      onChange={(e) => onChange(e)}
                    />
                  </InputGroup>
                  <div className="separator">Other Adoptive Parent</div>
                  <div
                    style={{
                      paddingLeft: '4px',
                      paddingTop: '-10px',
                      fontSize: '1.3em',
                      color: '#e75833',
                      fontFamily: 'Raleway',
                      fontWeight: '700'
                    }}
                  >
                    (if applicable)
                  </div>
                  <Label className="inputLabel">First Name</Label>
                  <InputGroup
                    className={'no-border Input-lg registration-input-group'}
                  >
                    <Input
                      type="text"
                      name="parent2_first_name"
                      value={parent2_first_name}
                      onChange={(e) => onChange(e)}
                    />
                  </InputGroup>
                  <Label className="inputLabel">Last Name</Label>
                  <InputGroup
                    className={'no-border Input-lg registration-input-group'}
                  >
                    <Input
                      type="text"
                      name="parent2_last_name"
                      value={parent2_last_name}
                      onChange={(e) => onChange(e)}
                    />
                  </InputGroup>
                  <Label className="inputLabel">Email</Label>
                  <InputGroup
                    className={'no-border Input-lg registration-input-group'}
                  >
                    <Input
                      type="email"
                      name="parent2_email"
                      value={parent2_email}
                      onChange={(e) => onChange(e)}
                    />
                  </InputGroup>
                  <div className="form-group"></div>
                  <div className="separator">Birth Parent Info</div>
                  <p>
                    Please provide birth parent contact information below so we
                    may invite them to set up an account.
                  </p>
                  <div className="form-group">
                    <Label className="inputLabel">First Name</Label>
                    <InputGroup
                      className={'no-border Input-lg registration-input-group'}
                    >
                      <Input
                        type="text"
                        name="bp_first_name"
                        value={bp_first_name}
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </InputGroup>
                    <Label className="inputLabel">Last Name</Label>
                    <InputGroup
                      className={'no-border Input-lg registration-input-group'}
                    >
                      <Input
                        type="text"
                        name="bp_last_name"
                        value={bp_last_name}
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </InputGroup>
                    <Label className="inputLabel">Email</Label>
                    <InputGroup
                      className={'no-border Input-lg registration-input-group'}
                    >
                      <Input
                        type="email"
                        name="bpemail"
                        value={bpemail}
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </InputGroup>
                  </div>
                  <div className="separator">
                    Optional Communications Settings
                  </div>
                  <h4>Two-Way Communication</h4>
                  <center>
                    <ToggleSwitch
                      value={twowaycommunication}
                      name={twowaycommunication}
                      theme="default"
                      enabled={false}
                      onStateChanged={toggleTwoWayComms}
                    />
                  </center>
                  <p style={{ textAlign: 'center', width: '75%' }}>
                    If Two-Way Communication is on, the Birth Parent will be
                    able to “Like” and Comment on your posts and share their own
                    posts with you. If Two-Way Communication is off, the Birth
                    Parent will be able to “Like” your posts, but will not be
                    able to leave comments or share their own posts with you.
                  </p>
                  <InputGroup
                    className={'no-border Input-lg registration-input-group'}
                  ></InputGroup>
                  <hr />
                  <div className="row">
                    <div className="col">
                      <Link to="/">
                        <Button
                          className="btn btn-gray checkout-button"
                          value="Cancel"
                        >
                          Cancel
                        </Button>
                      </Link>
                    </div>

                    <div className="col">
                      <Button
                        type="submit"
                        className="btn btn-primary checkout-button btn-ac-green"
                        value="Submit and Check Out >>"
                      >
                        Continue to Payment
                      </Button>
                    </div>
                  </div>
                </center>
              </Form>
            </CardBody>
          </Card>
        </div>
      </div>
    </Fragment>
  );
};

Register.propTypes = {
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { setAlert, register })(Register);
