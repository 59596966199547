import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col
} from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import isBefore from 'date-fns/isBefore';
import { createEvent } from '../../actions/event';

const SaveEvent = ({ event, createEvent }) => {
  const [liveModal, setLiveModal] = useState(false);
  const [error, setError] = useState('');
  const initialState = {
    title: '',
    start: '',
    end: '',
    recurring: false,
    rrule: {
      freq: 'monthly',
      interval: 0
    },
    extendedProps: {
      note: '',
      location: ''
    }
  };
  const [formData, setFormData] = useState(initialState);

  useEffect(() => {
    event && setFormData(event);
  }, [event]);

  const handleChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSave = () => {
    setError('');
    const today = Date.now();
    const startDate = formData.start || formData.rrule.dtstart;
    const endDate = formData.end;

    // Validate Data
    if (startDate && isBefore(new Date(startDate), new Date(today))) {
      setError('Please select a future due date.');
      setInterval(() => setError(''), 5000);
      return;
    }
    if (!startDate) {
      setError('Please include a due date. ');
      setInterval(() => setError(''), 5000);
      return;
    }

    if (endDate && isBefore(new Date(endDate), new Date(startDate))) {
      setError('End date must be after start date');
      setInterval(() => setError(''), 5000);
      return;
    }
    if (formData.recurring === false && endDate !== '') {
      setError('Can not set an end date if this not a recurring event');
      setInterval(() => setError(''), 5000);
      setFormData({
        ...formData,
        end: '',
        rrule: {
          ...formData.rrule,
          until: ''
        }
      });
      return;
    }
    if (formData.title === '') {
      setError('Please include a title.');
      setInterval(() => setError(''), 5000);
      return;
    }
    if (formData.recurring) {
      delete formData.start;
      delete formData.end;
      formData.rrule.dtstart =
        formData.rrule?.dtstart &&
        new Date(formData.rrule.dtstart).toISOString();
      formData.rrule.until =
        formData.rrule?.until && new Date(formData.rrule.until).toISOString();
    } else {
      delete formData.rrule;
      delete formData.duration;
      formData.start = formData.start && new Date(formData.start).toISOString();
      formData.end = formData.end && new Date(formData.end).toISOString();
    }
    delete formData.recurring;
    createEvent(formData);
    setLiveModal(false);
    setFormData(initialState);
  };

  return (
    <>
      <Button
        className="btn-ac-green"
        style={{ fontSize: '1.2em' }}
        type="button"
        onClick={() => setLiveModal(true)}
      >
        <i className="now-ui-icons ui-1_simple-add pl-1"></i> Add Reminder
      </Button>
      <Modal isOpen={liveModal} toggle={() => setLiveModal(false)}>
        <div className="modal-header">
          <h5
            className="panel-header"
            style={{ fontSize: '1.5em' }}
            id="exampleModalLiveLabel"
          >
            Add New Reminder
          </h5>
          <button
            aria-label="Close"
            className="close"
            type="button"
            onClick={() => setLiveModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <p
          style={{
            padding: '0px 30px 0px 30px',
            fontWeight: '500',
            fontFamily: 'Source Sans Pro'
          }}
        >
          Schedule a reminder for future updates. We’ll nudge you via email
          three times - two weeks, one week and three days before your update is
          due.{' '}
        </p>
        {error && <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>}
        <ModalBody>
          <Form>
            <FormGroup>
              <Label>Title</Label>
              <Input
                placeholder="Ex. “One Year Update”"
                name="title"
                value={formData.title}
                onChange={(e) => handleChange(e)}
                required
              />
            </FormGroup>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Due Date</Label>
                  <Input
                    type="date"
                    name="start"
                    value={formData.start}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        start: e.target.value,
                        rrule: {
                          ...formData.rrule,
                          dtstart: e.target.value
                        }
                      });
                    }}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label>End Date (Optional)</Label>
                  <Input
                    type="date"
                    name="end"
                    value={formData.end}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        end: e.target.value,
                        rrule: {
                          ...formData.rrule,
                          freq: 'monthly',
                          until: e.target.value
                        }
                        /** Below code sets the duration of each event when the event is recurring. Using above line to set end of recurrence instead, or can add another select box to set event duration and end of event recurrence. */
                        //  duration: {
                        //    days: differenceInCalendarDays(
                        //      new Date(e.target.value),
                        //      new Date(formData.start)
                        //    )
                        //  }
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Recurring event?</Label>
                  <Input
                    type="select"
                    name="rrule.interval"
                    value={formData.rrule.interval}
                    onChange={(e) => {
                      if (e.target.value === '0') {
                        setFormData({
                          ...formData,
                          recurring: false,
                          rrule: {
                            ...formData.rrule,
                            interval: 0
                          }
                        });
                      } else {
                        setFormData({
                          ...formData,
                          recurring: true,
                          rrule: {
                            ...formData.rrule,
                            interval: +e.target.value
                          }
                        });
                      }
                    }}
                  >
                    <option value="0">No</option>
                    <option value="1">Monthly</option>
                    <option value="3">Quarterly</option>
                    <option value="6">Biannually</option>
                    <option value="12">Annually</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <Label>Note (optional):</Label>
              <Input
                placeholder="Ex. “Include photos from Family Reunion”"
                name="extendedProps.note"
                value={formData.extendedProps.note}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    extendedProps: {
                      ...formData.extendedProps,
                      note: e.target.value
                    }
                  })
                }
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            type="button"
            className="btn-ac-green"
            onClick={() => setLiveModal(false)}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            type="button"
            className="btn-ac-green"
            onClick={handleSave}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

SaveEvent.propTypes = {
  event: PropTypes.object.isRequired,
  createEvent: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { createEvent })(SaveEvent);
