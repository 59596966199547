import { SET_ALERT, REMOVE_ALERT } from './types';

// Function to set the alert. Get's message from input and sets the message at the top of the page. This is the Alert system in server.js
export const setAlert = (msg, alertType, timeout = 5000) => dispatch => {
  const id = Date.now();
  dispatch({
    type: SET_ALERT,
    payload: { msg, alertType, id }
  });

  setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), timeout);
};

// Experimental function to set longer term messages, was testing this for the email not confirmed message.
export const setMessage = (msg, alertType, timeout = 60000) => dispatch => {
  const id = Date.now();
  dispatch({
    type: SET_ALERT,
    payload: { msg, alertType, id }
  });

  setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), timeout);
};
