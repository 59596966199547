import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import PostItem from '../posts/PostItem';
import { getPost } from '../../actions/post';
import { isMobile } from 'react-device-detect';

const Post = ({ getPost, post: { post, loading }, match }) => {
  useEffect(() => {
    getPost(match.params.id);
  }, [getPost, match.params.id]);

  return loading || post === null ? (
    <Spinner />
  ) : (
    <Fragment>
      <div
        className="col-lg-5 col-sm-10 spacer singlepost"
        style={isMobile ? {} : { marginLeft: '30%' }}
      >
        <PostItem key={post.id} post={post} showActions={false} />
      </div>
      &nbsp;
      <center>
        <Link to="/timeline" className="btn btn-dark">
          Back To Timeline
        </Link>
      </center>
    </Fragment>
  );
};

Post.propTypes = {
  getPost: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  post: state.post
});

export default connect(mapStateToProps, { getPost })(Post);
