import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import emailGraphic from '../../assets/img/Check_Your_Email_Graphic.png';
import Spinner from '../layout/Spinner';

const ConfirmEmail = ({ user: { loading, email, confirmed } }) => {
  if (loading) {
    return (
      <center>
        <Spinner />
      </center>
    );
  }
  if (!loading && confirmed) {
    return (
      <center>
        <Spinner />
      </center>
    );
  } else {
    return (
      <center>
        <div style={{ width: '60%' }}>
          <center>
            <img
              src={emailGraphic}
              alt="Check Your Email"
              style={{ width: '200px' }}
            />
            <p className="panel-header">Please verify your email</p>
            <p className="ac-text">
              Before you can access your account, we need you to verify your
              email address. <br /> An email containing a verification link was
              sent to {email}.
            </p>
          </center>
        </div>
      </center>
    );
  }
};

ConfirmEmail.propTypes = {
  user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps, {})(ConfirmEmail);
