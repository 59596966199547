/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import { useUpdatePassword } from '../../hooks/useUpdatePassword';
import useUser from '../../hooks/useUser';
import LargeAlert from '../layout/LargeAlert';
import { Card, CardHeader } from 'reactstrap';
import { setAlert } from '../../actions/alert';
import PasswordStrengthBar from 'react-password-strength-bar';
import passwordValidator from 'fnbpasswordvalidator';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Only for when user.requirePasswordChange = true

const ChangePassword = ({ match, setAlert }) => {
  const { user } = useUser();
  const [currentPw, setCurrentPw] = React.useState();
  const [form, setForm] = React.useState({ password: '', confirm: '' });
  const [processing, setProcessing] = useState(false);
  const { error, success, update } = useUpdatePassword(currentPw);
  const handleChange = ({ target }) => {
    setForm((current) => ({ ...current, [target.name]: target.value }));
  };

  useEffect(() => {
    setCurrentPw(user?.resetToken);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.resetToken]);

  function CheckPassword() {
    const { password, confirm } = form;
    let passwordDetails = passwordValidator.validate(password, {
      prohibitedWords: ['first_name', 'last_name', 'email'],
      min: 8,
      specialCharacters: true
    });
    if (!passwordDetails.valid) {
      setProcessing(false);
      setAlert(passwordDetails.message, 'danger');
    } else {
      update(currentPw, password);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const { password, confirm } = form;
    if (password !== confirm) {
      setAlert(`Passwords don't match`, 'danger');
    } else {
      setProcessing(true);
      CheckPassword();
    }
  };

  if (!user?.requirePasswordChange) {
    return <Redirect to="/timeline" />;
  }

  return (
    <>
      <section className="container sec-pad-adjust">
        <Card className="card-plain reset-password-card">
          <CardHeader className="text-center">
            <h3
              style={{ textAlign: 'left', paddingBottom: '0px' }}
              className="panel-header"
            >
              Create New Password
            </h3>
            <p
              style={{ textAlign: 'left', fontSize: '1em' }}
              className="ac-text"
            >
              In order to protect your account, please make sure your password:
              <ul>
                <li>Is longer than 8 characters</li>
                <li>
                  Contains 1 uppercase letter, 1 lowercase letter, 1 number and
                  a special character {`(!"#$%&'*+)`}
                </li>
                <li>
                  Does not match or contain your name or email, e.g. do not use
                  'name123'.
                </li>
              </ul>
            </p>
          </CardHeader>
          <LargeAlert />
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="adminPassword">Create Password</label>
              <input
                type="password"
                value={form.password}
                name="password"
                className="form-control"
                id="adminPassword"
                minLength="7"
                onChange={handleChange}
              />
            </div>
            <PasswordStrengthBar password={form.password} />
            <div className="form-group">
              <label htmlFor="adminConfirm">Confirm Password</label>
              <input
                type="password"
                value={form.confirm}
                name="confirm"
                className="form-control"
                id="adminConfirm"
                minLength="7"
                onChange={handleChange}
              />
            </div>
            <center>
              <button type="submit" className="btn-ac-green">
                Submit
              </button>
            </center>
          </form>
        </Card>
      </section>
    </>
  );
};

ChangePassword.propTypes = {
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { setAlert })(ChangePassword);
