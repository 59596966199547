import React from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router';
import {
  Row,
  Card,
  CardHeader,
  Form,
  Spinner,
  CardBody,
  Modal,
  CardFooter
} from 'reactstrap';
import { paymentHandler } from '../../actions/stripe';
import CardSection from './CardSection';
import { setAlert } from '../../actions/alert';
import { Link } from 'react-router-dom';

export default function CheckoutForm() {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [success, setSuccess] = React.useState();
  const [processing, setProcessing] = React.useState(false);
  const [cardStatus, setCardStatus] = React.useState({});

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setProcessing(true);

    if (!cardStatus.complete) {
      setProcessing(false);
      setAlert('Please complete payment information', 'error');
      return;
    }

    if (cardStatus.error?.message !== undefined) {
      setProcessing(false);
      setAlert(cardStatus.error.message, 'error');
      return;
    }

    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        email: user.email
      }
    });

    const [subscription] = await paymentHandler(
      {
        ...result,
        collection_method: 'charge_automatically'
      },
      dispatch
    );
    console.log(`subscription: ${subscription}`);
    if (subscription.status === true) {
      setSuccess(true);
      setProcessing(false);
    } else if (subscription.status === 500) {
      console.log(subscription.status);
      setProcessing(false);
    } else if (subscription === undefined) {
      console.log(subscription.status);
      setProcessing(false);
    } else {
      const confirmation = await stripe.confirmCardPayment(
        subscription.client_secret
      );
      if (confirmation.error) {
        setProcessing(false);
        setAlert(confirmation.error.message, 'error');
        return console.log(confirmation.error.message, confirmation.error);
      }
      console.log('confirmation', confirmation);
      console.log(setSuccess(true));
    }
  };

  function handleChange(newValue) {
    setCardStatus(newValue);
    console.log(newValue);
  }
  return (
    <>
      <Modal isOpen={processing} style={{ marginTop: '5em' }}>
        <Card style={{ marginBottom: '0px' }}>
          <CardHeader>
            <h3 style={{ paddingLeft: '10px' }}>Payment Processing</h3>
          </CardHeader>
          <CardBody>
            <Spinner></Spinner>
          </CardBody>
          <CardFooter></CardFooter>
        </Card>
      </Modal>
      {success && <Redirect to="/create-profile" />}
      <Row>
        <Card
          className="mx-auto"
          rounded
          style={{
            width: '100%',
            minWidth: 320,
            maxWidth: 800,
            padding: '40px 20px'
          }}
        >
          <div>
            <center>
              <h1 className="panel-header" style={{ padding: '0px 0px' }}>
                Payment Info
              </h1>
              {user.subscriptionCreation === 'migration' ? (
                <>
                  <p>
                    Please enter your credit card information below, no payment
                    is due at this time. <br />
                    Payment information will be used for any future subscription
                    charges.
                  </p>
                </>
              ) : null}
              {/* If a user is a web user, they have no coupon */}
              {user.subscriptionCreation === 'web' ? (
                <>
                  <p className="ac-text">
                    Account Type: Individual - $78/year{' '}
                    <p style={{ fontSize: '.8em', fontWeight: '400' }}>
                      Renews Automatically, Cancel Anytime
                    </p>
                  </p>{' '}
                </>
              ) : null}
              {/* If a user is an agency user, they have a coupon applied by the API to remove 19 */}
              {user.subscriptionCreation === 'agency' && user.coupon === '' ? (
                <>
                  <p className="ac-text">
                    Account Type: Professional-Linked: $59/year{' '}
                    <p style={{ fontSize: '.8em', fontWeight: '400' }}>
                      Renews Automatically, Cancel Anytime.
                    </p>
                  </p>{' '}
                </>
              ) : null}
              {/* If a user is an agency user, they might have a coupon, that makes the first year free */}
              {user.subscriptionCreation === 'agency' && user.coupon !== '' ? (
                <>
                  <p className="ac-text">
                    Account Type: Professional-Linked - $0 First Year
                    <p style={{ fontSize: '.8em', fontWeight: '400' }}>
                      Please enter your credit card information below, no
                      payment is due at this time. <br />
                      Payment information will be used for any future
                      subscription charges.
                    </p>
                  </p>{' '}
                </>
              ) : null}

              <p
                className="panel-header"
                style={{
                  fontSize: '1.3em',
                  paddingBottom: 0,
                  paddingTop: '10px'
                }}
              >
                Please enter payment info below:
              </p>
            </center>
          </div>
          <Form onSubmit={handleSubmit} style={{ paddingBottom: '20px' }}>
            <center>
              <p style={{ color: 'red' }}>{cardStatus.error?.message}</p>
            </center>
            <CardSection onChange={handleChange} />
            <br />
            <center>
              <p
                className="ac-text"
                style={{ fontSize: '1.2em', fontWeight: '400' }}
              >
                By clicking &quot;Submit Payment&quot; below, you accept our{' '}
                <u>
                  <Link to="/terms-of-service" target="_blank">
                    Terms of Service
                  </Link>
                </u>{' '}
                and agree that your subscription will automatically renew{' '}
                {user.subscriptionCreation === 'web'
                  ? 'each year and your credit card will be charged the annual subscription fee until you cancel your subscription.'
                  : null}{' '}
                {user.subscriptionCreation === 'agency'
                  ? 'each year and your credit card will be charged the annual subscription fee until you cancel your subscription.'
                  : null}{' '}
              </p>
              <button
                type="submit"
                className="btn btn-ac-green"
                disabled={!stripe}
              >
                Submit Payment
              </button>
            </center>
          </Form>
        </Card>
      </Row>
    </>
  );
}
