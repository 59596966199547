import axios from 'axios';
import { API_URL } from '../config/default.json';
import {
  SUBSCRIBED_TO_NEWSLETTER,
  NEWSLETTER_ERROR,
  CREATE_TICKET,
  TICKET_ERROR
} from './types';
import { setAlert } from './alert';

// Add post
export const subscribeToNewsletter = (data) => async (dispatch) => {
  console.log(JSON.stringify(data));
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const res = await axios.post(
      `${API_URL}/newsletter/subscribe`,
      data,
      config
    );

    dispatch({
      type: SUBSCRIBED_TO_NEWSLETTER,
      payload: res.data
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: NEWSLETTER_ERROR,
      payload: {
        msg: err.message,
        status: err.name
      }
    });
    if (err.message === 'Request failed with status code 400') {
      dispatch(
        setAlert('You are already subscribed to our mailing list', 'error')
      );
    }
    if (err.message !== 'Request failed with status code 400') {
      dispatch(
        setAlert('Oops, something went wrong. Please try again.', 'error')
      );
    }
  }
};

// Add post
export const createTicket = (data) => async (dispatch) => {
  console.log(JSON.stringify(data));
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const name = data.firstName + data.lastName !== '' ? data.lastName : '';
  const phone = data.phone === '' ? 'No Phone Provided' : data.phone;

  const body = {
    name: name,
    email: data.email,
    phone: phone,
    message: data.message
  };

  try {
    const res = await axios.post(`${API_URL}/ticket/create`, body, config);

    dispatch({
      type: CREATE_TICKET,
      payload: res.data
    });

    if (data.type === 'contact') {
      dispatch(setAlert('Contact Us Form Received', 'success'));
    }
    if (data.type === 'support') {
      dispatch(setAlert('Tech Support Request Received.', 'success'));
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: TICKET_ERROR,
      payload: {
        msg: err.message,
        status: err.name
      }
    });
    dispatch(
      setAlert('Oops, something went wrong. Please try again.', 'error')
    );
  }
};

// Add post
export const createContact = (data) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const name = data.firstName + data.lastName !== '' ? data.lastName : '';
  const phone = data.phone === '' ? 'No Phone Provided' : data.phone;

  const body = {
    name: name,
    email: data.email,
    phone: phone,
    message: data.message
  };

  try {
    const res = await axios.post(`${API_URL}/ticket/contact`, body, config);

    dispatch({
      type: CREATE_TICKET,
      payload: res.data
    });

    dispatch(setAlert('Contact Us Form Received', 'success'));
  } catch (err) {
    console.log(err);
    dispatch({
      type: TICKET_ERROR,
      payload: {
        msg: err.message,
        status: err.name
      }
    });
    dispatch(
      setAlert('Oops, something went wrong. Please try again.', 'error')
    );
  }
};
