import React, { Fragment, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Col, Button, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

import hiwBackground from '../../assets/img/hiwBackground.png';
import hiw1 from '../../assets/img/hiwapro1.png';
import hiw2 from '../../assets/img/hiwapro2.png';
import hiw3 from '../../assets/img/hiwapro3.png';
import hiw4 from '../../assets/img/hiwapro4.png';
import NewsletterSignup from '../forms/newsletterSignup';
import adoptionProDashboardPreview from '../../assets/img/adoptionProDashboardPreview.png';

const HiwAdoptionProfessional = (pathname) => {
  const [modalStatus, setModalStatus] = useState(false);
  const [modalStatus2, setModalStatus2] = useState(false);

  function toggle() {
    setModalStatus(!modalStatus);
  }

  function toggle2() {
    setModalStatus2(!modalStatus2);
  }

  var mainSectionStyle = {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${hiwBackground})`
  };

  return (
    <Fragment>
      <Helmet>
        <title>Adopt Connect - How It Works - Adoption Professional</title>
      </Helmet>
      <Modal
        isOpen={modalStatus}
        toggle={toggle}
        style={{ maxWidth: '1200px' }}
      >
        <ModalHeader toggle={toggle}>Timeline Preview</ModalHeader>
        <ModalBody>
          <img
            src={adoptionProDashboardPreview}
            alt="Timeline Preview"
            width="100%"
          />
        </ModalBody>
      </Modal>
      <Modal
        isOpen={modalStatus2}
        toggle={toggle2}
        style={{ maxWidth: '1200px' }}
      >
        <ModalHeader toggle={toggle2}>Timeline Preview</ModalHeader>
        <ModalBody>
          <img
            src="https://adoptconnect.b-cdn.net/sampleTimeline.gif"
            alt="Timeline Preview"
            width="100%"
          />
        </ModalBody>
      </Modal>
      <div style={mainSectionStyle} className="banner">
        <h1 className="banner banneroverlay">How it Works</h1>
      </div>
      <section className="container sec-pad-adjust">
        <h4
          className="panel-header"
          style={{ lineHeight: '1.3em', fontSize: '2.3em' }}
        >
          AdoptConnect relieves the Adoption Professional’s burden of
          post-adoption communication management, but still allows for oversight
          and accountability.
        </h4>
        <div className="" style={{ width: '100%' }}>
          <h4 className="raleway ac-orange" style={{ fontSize: '2.6em' }}>
            Straightforward Pricing
          </h4>
        </div>
        <div className="row">
          <Col md="12" xs="auto">
            <center>
              <h3 className="panel-header" style={{ paddingBottom: '0px' }}>
                $59/year for Adoption Professional-Linked Accounts
              </h3>
            </center>
          </Col>
          <Col md="12" xs="auto">
            <h4 className="panel-header" style={{ fontSize: '2.3em' }}>
              Adoptive Parents subscription automatically renews annually. No
              cancellation fees or contracts.
            </h4>
            <h4
              className="panel-header"
              style={{ fontSize: '2.3em', paddingTop: 0 }}
            >
              AdoptConnect is always free for Adoption Professionals and Birth
              Parents.
            </h4>
          </Col>
          <Col md="8" xs="auto" style={{ paddingLeft: '10%' }}>
            <h4
              className="panel-header"
              style={{ fontSize: '2.3em', textAlign: 'left' }}
            >
              Professional Account Features:
            </h4>
            <ul>
              <li className="ac-text">Customized Dashboard </li>
              <li className="ac-text">Past Due Update Notifications </li>
              <li className="ac-text">Adoptive Family Activity Reports</li>
              <li className="ac-text">
                24/7 Adoption-Informed Customer Service
              </li>
              <li className="ac-text">
                Social Impact: Gives back to adoption-related non-profits
              </li>
            </ul>
          </Col>
          <Col md="4" xs="auto" className="hiw-button-2">
            <Link to="/how-it-works-adoption-professional-2">
              <Button
                color="#47604e"
                className="ralewayBold"
                style={{
                  backgroundColor: 'rgb(231, 88, 51)',
                  color: 'white',
                  width: '300px',
                  height: '60px',
                  fontSize: '3.4em',
                  border: '4px solid #F6916C',
                  borderRadius: '10px',
                  padding: '0px 20px'
                }}
              >
                <div style={{ letterSpacing: '3px' }}>SIGN UP</div>
              </Button>
            </Link>
          </Col>
        </div>
        <div className="" style={{ width: '100%' }}>
          <h4 className="raleway ac-orange" style={{ fontSize: '2.6em' }}>
            Simple Set-Up
          </h4>
        </div>
        <div className="row">
          <Col className="hiwAPcol" md="6" xs="6" sm="4">
            <img className="hiwAPImg" src={hiw1} alt=""></img>
          </Col>
          <Col
            className="hiwAPcol hiwColText ac-text d-flex"
            md="6"
            xs="6"
            sm="4"
          >
            Schedule a call to to learn more about AdoptConnect and set up a
            free Professional Account for your agency or firm.
          </Col>
          <Col
            className="hiwAPcol hiwColText ac-text d-flex"
            md="6"
            xs="6"
            sm="4"
          >
            Complete online registration and access your dashboard.
          </Col>
          <Col className="hiwAPcol" md="6" xs="6" sm="4">
            <img className="hiwAPImg" src={hiw2} alt=""></img>
          </Col>
          <Col className="hiwAPcol" md="6" xs="6" sm="4">
            <img className="hiwAPImg" src={hiw3} alt=""></img>
          </Col>
          <Col
            className="hiwAPcol hiwColText ac-text d-flex"
            md="6"
            xs="6"
            sm="4"
          >
            Set up new family accounts.
          </Col>
          <Col
            className="hiwAPcol hiwColText ac-text d-flex"
            md="6"
            xs="6"
            sm="4"
          >
            Receive notifications when accounts are activated and linked.
          </Col>
          <Col className="hiwAPcol" md="6" xs="6" sm="4">
            <img className="hiwAPImg" src={hiw4} alt=""></img>
          </Col>
        </div>
        <center>
          <Button className="btn-ac-green btn-preview-popup" onClick={toggle}>
            View Sample Adoption Professional Dashboard
          </Button>
          <br />
          <Button onClick={toggle2} className="btn-ac-green btn-preview-popup">
            View Sample AdoptConnect Timeline
          </Button>

          <Col md="8" xs="12">
            <h4 className="panel-header" style={{ fontSize: '2.3em' }}>
              “AdoptConnect is a great alternative for adoption professionals
              who want to ensure that contact agreements are being followed, but
              do not want to micromanage the process.”
            </h4>
            <h4
              className="panel-header-2"
              style={{ fontSize: '2em', color: 'black', paddingTop: 0 }}
            >
              - Katherine M., L.C.S.W.
            </h4>
          </Col>
          <Col md="4" xs="12" sm="12">
            <h4 className="panel-header-2" style={{ marginBottom: '0' }}>
              Ready to Connect?
            </h4>
            <div style={{ textAlign: 'center' }}>
              <Link to="/how-it-works-adoption-professional-2">
                <Button
                  color="#47604e"
                  className="ralewayBold"
                  style={{
                    backgroundColor: 'rgb(231, 88, 51)',
                    color: 'white',
                    width: '300px',
                    height: '60px',
                    fontSize: '3.4em',
                    border: '4px solid #F6916C',
                    borderRadius: '10px',
                    padding: '0px 20px'
                  }}
                >
                  <div style={{ letterSpacing: '3px' }}>SIGN UP</div>
                </Button>
              </Link>
            </div>
          </Col>
        </center>
        <Row style={{ border: '1px solid black', margin: '2em 2em' }}>
          <Col md="9" style={{ paddingLeft: '10px' }}>
            <center>
              <h4
                className="panel-header-3 text-left"
                style={{ paddingTop: '0px', paddingBottom: '0px' }}
              >
                Share AdoptConnect!
              </h4>
              <p
                className="ac-text text-left"
                style={{ paddingBottom: '10px' }}
              >
                Use this link to tell your clients about AdoptConnect.
              </p>
            </center>
          </Col>
          <Col md="3">
            <a href="mailto:?subject=Check%20out%20AdoptConnect!&amp;body=Hello!%20I%20wanted%20to%20let%20you%20know%20about%20this%20online%20platform%20called%20AdoptConnect%20that%20helps%20adoptive%20parents%20and%20birth%20parents%20stay%20connected%20after%20adoption.%20Instead%20of%20posting%20updates%20on%20social%20media%2C%20sending%20them%20through%20an%20agency%20or%20in%20the%20mail%2C%20adoptive%20parents%20can%20now%20safely%20and%20easily%20share%20photos%2C%20video%2C%20and%20written%20updates%20with%20their%20child%27s%20birth%20parents%20on%20AdoptConnect.%20Find%20Out%20More%20-%20www.adoptconnect.com">
              <Button
                rounded
                color="#47604e"
                className="ralewayBold btn-share btn-center"
                style={{
                  backgroundColor: '#56786c',
                  color: 'white',
                  marginTop: '30%',
                  width: '200px'
                }}
              >
                Share
              </Button>
            </a>
          </Col>
        </Row>
      </section>
      <div style={{ margin: '60px 0px 0px 0px' }}>
        <hr width="60%" />
        <NewsletterSignup />
      </div>
    </Fragment>
  );
};

export default HiwAdoptionProfessional;
