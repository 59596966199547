import React, { Fragment, useState, useEffect, Suspense } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { setAlert } from '../../actions/alert';
import { registerWithInvitation, getInvitationData } from '../../actions/auth';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardBody,
  Form,
  Input,
  InputGroup,
  Button,
  Spinner
} from 'reactstrap';
import passwordValidator from 'fnbpasswordvalidator';
import LargeAlert from '../layout/LargeAlert';
import { useWait, Waiter } from 'react-wait';

const Register = ({
  setAlert,
  registerWithInvitation,
  isAuthenticated,
  match,
  profile,
  getInvitationData,
  invitations: { invitation, loading }
}) => {
  const [formData, setFormData] = useState({
    parent1_first_name: '',
    parent1_last_name: '',
    accountType: 'birthParent',
    email: '',
    password: '',
    password2: '',
    subscriptiontype: 'birthParent',
    subscriptionactive: 'true',
    twowaycommunication: 'true',
    privatemessages: 'false',
    invitationID: match.params.id
  });

  const { startWaiting, endWaiting } = useWait();

  useEffect(() => {
    getInvitationData(match.params.id);
  }, [getInvitationData, match.params.id]);

  const {
    parent1_first_name,
    parent1_last_name,
    email,
    password,
    password2,
    subscriptiontype,
    subscriptionactive,
    accountType,
    invitationID
  } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  function CheckPassword() {
    let passwordDetails = passwordValidator.validate(password, {
      min: 8,
      specialCharacters: true
    });
    if (!passwordDetails.valid) {
      setAlert(passwordDetails.message, 'danger');
    } else {
      registerWithInvitation({
        password,
        subscriptiontype,
        subscriptionactive,
        accountType,
        invitationID,
        parent1_first_name,
        parent1_last_name,
        email
      });
      startWaiting('creating user');
      setTimeout(() => {
        endWaiting('creating user');
      }, 4000);
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault();

    if (password !== password2) {
      setAlert('Passwords do not match', 'danger');
    } else {
      CheckPassword();
    }
  };

  if (isAuthenticated) {
    return <Redirect to="/create-profile" />;
  }

  if (!loading && invitation.bpname === invitation._id) {
    return <Redirect to="/" />;
  }

  if (loading) {
    return <Spinner></Spinner>;
  }

  if (!loading && invitation.completed === true) {
    setAlert('Invitation Already Used', 'danger');
    return <Redirect to="/" />;
  } else {
    return (
      <Suspense fallback="Loading...">
        <Fragment>
          <div className="fullPage">
            <div className="text-center form-container">
              <Card className="form-card">
                <CardHeader style={{ padding: '20px' }}>
                  <h1 className="large text-primary">
                    Welcome, {invitation.bp_first_name}!
                  </h1>
                  <p className="ac-text">
                    You have been invited by {invitation.apname} to connect on
                    AdoptConnect
                  </p>
                  <p className="ac-text">
                    Please enter a password below to create your account.
                  </p>
                  <center>
                    <p style={{ fontSize: '1em' }} className="ac-text">
                      In order to protect your account, please make sure your
                      password:
                      <ul
                        className="passwordOptions"
                        style={{ listStyleType: 'disc' }}
                      >
                        <li className="passwordOptions">
                          Is longer than 8 characters
                        </li>
                        <li className="passwordOptions">
                          Contains 1 uppercase letter, 1 lowercase letter, 1
                          number and a special character {`(!"#$%&'*+)`}
                        </li>
                        <li className="passwordOptions">
                          Does not match or contain your name or email address,
                          e.g. do not use 'name123'.
                        </li>
                      </ul>
                    </p>
                  </center>
                </CardHeader>
                <CardBody>
                  <LargeAlert />
                  <Form className="form" onSubmit={(e) => onSubmit(e)}>
                    <center>
                      <InputGroup
                        className={
                          'no-border Input-lg registration-input-group'
                        }
                      >
                        <Input
                          type="password"
                          placeholder="Password"
                          name="password"
                          minLength="8"
                          value={password}
                          onChange={(e) => onChange(e)}
                        />
                      </InputGroup>
                      <InputGroup
                        className={
                          'no-border Input-lg registration-input-group'
                        }
                      >
                        <Input
                          type="password"
                          placeholder="Confirm Password"
                          name="password2"
                          minLength="8"
                          value={password2}
                          onChange={(e) => onChange(e)}
                        />
                      </InputGroup>
                      <Button
                        type="submit"
                        className="btn btn-ac-green"
                        value="Submit"
                      >
                        Submit
                      </Button>
                    </center>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </Fragment>
      </Suspense>
    );
  }
};

Register.propTypes = {
  setAlert: PropTypes.func.isRequired,
  registerWithInvitation: PropTypes.func.isRequired,
  getInvitationData: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  invitations: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  invitations: state.invitations,
  profile: state.profile
});

export default connect(mapStateToProps, {
  setAlert,
  registerWithInvitation,
  getInvitationData
})(Register);
