import React from 'react';

const privacyPolicy = (props) => {
  return (
    <div>
      <section className="container sec-pad-adjust">
        <h4>Privacy Policy</h4>
        <hr />
        <p>1. INFORMATION COLLECTED</p>

        <p>
          We collect personal information when you register with AdoptConnect
          via our websites, mobile sites or mobile applications (collectively,
          our &ldquo;Sites and Apps&rdquo;) and when you use any of our products
          or services. Personal information is any information about you that is
          personally identifiable, such as your name, address, email address or
          phone number, and any other information that is not otherwise publicly
          available.
        </p>

        <p>
          In addition, when Adoptive Parents or Agencies send email invitations
          to Birth Parents or to Adoptive Parents to allow them to create an
          account, those email addresses are personal information.
        </p>

        <p></p>

        <p>
          We or our third-party service providers may also collect information
          relating to your devices, including your device model, operating
          system, browser type, unique device identifier, IP address, mobile
          phone number, mobile network carrier, location, and event information
          (such as application installations).
        </p>

        <p></p>

        <p>
          If you order products or pay for services, we may need your shipping
          address, billing name and address, as well as your credit card number
          and expiration date. That information will be provided to our
          third-party payment processor, Stripe, and is subject to their privacy
          and security policies. AdoptConnect does not retain your credit card
          or other financial information, other than the last four digits of the
          card used for payment, the date of payment, the total amount of the
          transaction, and the zip code associated with the card used for
          payment, which are required for our tax reporting requirements.
        </p>

        <p></p>

        <p>COOKIES AND SIMILAR TECHNOLOGIES</p>

        <p>
          We use cookies, pixel tags, and/or other similar technologies to
          collect visitor information. Cookies, for example, are alphanumeric
          identifiers that we transfer to your computer&rsquo;s hard drive
          through your web browser. They make it possible for us to store the
          contents of your Marketplace shopping cart until you are ready to
          check out, recognize your browser when you visit and provide you with
          information about products that interest you. By doing this, we can
          personalize your return visits and save you time during checkout. It
          is possible to prevent cookies from being used in your browser by
          turning the feature off, but in order to enjoy the benefits of being a
          registered user, your browser must be set to accept cookies.
        </p>

        <p></p>

        <p></p>

        <p>AUTHORIZED THIRD PARTIES</p>

        <p>
          We may use third-party service providers to serve ads on our behalf
          across the Internet and sometimes on our Sites and Apps. They may
          collect anonymous information about your visits to our Sites and Apps
          and your interaction with our products and services. They may also use
          information about your visits to our Sites and Apps and other sites
          and mobile applications to target ads for products and services. For
          instance, they may collect web log data from you (such as IP address
          and information about your browser or operating system) or place or
          recognize a unique cookie on your browser to enable you to receive
          customized ads. Through this process, demographic or other interest
          data may be associated with your browser or device in a non-personally
          identifiable manner. No personally identifiable information is
          collected in this process. They do not know the name, address, email
          address, phone number or any personally identifiable information about
          the user.
        </p>

        <p></p>

        <p>
          The NAI (Network Advertising Initiative) allows for the central opting
          out of all of its members&rsquo; cookies from a single opt-out page.
          If you prefer not to receive relevant advertising on web pages, please
          click on the following link:
          http://www.networkadvertising.org/choices/. If you delete your
          cookies, use a different browser or buy a new computer, you will need
          to renew your opt-out choice.
        </p>

        <p></p>

        <p>
          We also work with third party service providers to monitor certain
          pages of our Sites and Apps for such purposes as reporting traffic and
          other advertising statistics. Where authorized by us, these third
          party providers may use cookies and/or other monitoring technologies
          to compile anonymous statistics about our visitors. No personally
          identifiable information is transferred to these third party service
          providers.
        </p>

        <p></p>

        <p>
          We may also allow third parties to provide us with analytics about
          your visits to our Sites and Apps. The third party analytics services
          may use cookies, web beacons and other technologies to collect
          information about your use of the Sites and Apps and other websites,
          including your IP address, web browser, pages viewed, time spent on
          pages, and links clicked. This information is provided to us on an
          aggregated, non-PII basis, and may be used to, among other things,
          analyze and track data, determine the popularity of certain content
          and better understand our users&rsquo; online activity.
        </p>

        <p></p>

        <p>
          We use your information for the following general purposes: to fulfill
          your requests for products and services, administrative purposes, to
          improve our services, to contact you, and for internal business
          purposes. We use third party information you provide to us solely to
          provide services you specifically request.
        </p>

        <p></p>

        <p>
          We absolutely never sell your personal information to anyone. Period.
        </p>

        <p>
          We also never use your personal information to customize ads for you.
          Any ads you see on Adopt Connect are there because we think that they
          provide a great product for members of the adoption community
          generally.
        </p>

        <p></p>

        <p>USE OF INFORMATION</p>

        <p>
          Your accounts may also contain a Reminder feature where you can
          designate due dates for updates and other recurring events. You can
          use this information to help share updates and messages on important
          dates. We may send you email reminders of the personalized dates you
          have entered in this calendar. Reminder information is highly
          confidential and will not be shared with any third parties.
        </p>

        <p></p>

        <p>
          As we continue to develop our business, we may undergo a business
          transition such as acquiring another company, merging with an existing
          company, or selling all or a portion of our assets. In such
          transitions, customer information is typically one of the business
          assets that is transferred or acquired by a third party. In the
          unlikely event that we or substantially all of our assets are acquired
          or enter a court proceeding, you acknowledge that such transfers may
          occur and that your personal information can continue to be used as
          set forth in this privacy policy.
        </p>

        <p></p>

        <p>
          California&rsquo;s &ldquo;Do Not Track&rdquo; Notice: &ldquo;Do Not
          Track&rdquo; (&ldquo;DNT&rdquo;) is a preference you can set in your
          web browser to let the sites you visit know that you do not want them
          collecting information about you. Our sites do not currently respond
          to &ldquo;Do Not Track&rdquo; settings. For further details regarding
          DNT, visit donottrack.us.
        </p>

        <p></p>

        <p>CHILDREN&rsquo;S INFORMATION</p>

        <p>
          Our Sites and Apps are not intended for children, and we will not
          knowingly collect personally identifiable information from children.
          If we become aware that a subscriber is under the age of 13, we will
          remove their personally identifiable information from our files.
        </p>

        <p></p>

        <p>ACCESS TO YOUR ACCOUNT INFORMATION AND PREFERENCES</p>

        <p>
          Individual Plan users have the right and ability to edit your member
          account information on our Sites and Apps at any time. You can opt-in
          or out of receiving future communications from us about special offers
          and new product information, you can unsubscribe by following
          instructions contained in the messages you receive.
        </p>

        <p></p>

        <p>
          Partner Plan users agree that certain information, including but not
          limited to, user email addresses, account status, non-compliance with
          due dates for agreed upon posts and updates, and the ability to view
          the existence (not content) of posts and comments on posts will be
          shared with and/or accessible to the User&rsquo;s Adoption
          Professional, as set forth in Terms and Conditions (LINK).
        </p>

        <p></p>

        <p>CONFIDENTIALITY AND SECURITY</p>

        <p>
          Although no data transmission can be guaranteed to be 100% secure, we
          take reasonable steps to protect all Personal Information. We maintain
          strict administrative, technical, and physical procedures to protect
          information stored in our servers, which are located in the United
          States. Access to information is limited (through user/password
          credentials and software systems) to those employees who require it to
          perform their job functions. We use industry-standard Secure Socket
          Layer (SSL) encryption technology to safeguard the account
          registration process and sign-up information. Other security
          safeguards include but are not limited to data encryption, firewalls,
          and physical access controls to building and files.
        </p>

        <p>
          We use a secure server (SSL) to encrypt your personal financial
          information. We use other physical, electronic and procedural
          safeguards to protect the security and confidentiality of the
          information we have collected, and to prevent unauthorized access to
          or disclosure of your information.
        </p>

        <p></p>

        <p>THIRD PARTY SITES</p>

        <p>
          Our Sites and Apps may contain links to other sites for news and other
          information. Our Privacy Policy only applies to our Sites and Apps,
          and we are not responsible for the privacy practices or the content of
          other sites which are linked to or integrated with our Sites and Apps.
          You should check the privacy policies of those sites before providing
          your personal information to them.
        </p>

        <p></p>

        <p>CLASS ACTION WAIVER</p>

        <p>
          ANY DISPUTE RESOLUTION PROCEEDINGS, WHETHER IN ARBITRATION OR COURT,
          WILL BE CONDUCTED ONLY ON AN INDIVIDUAL BASIS AND NOT IN A CLASS OR
          REPRESENTATIVE ACTION OR AS A NAMED OR UNNAMED MEMBER IN A CLASS,
          CONSOLIDATED, REPRESENTATIVE OR PRIVATE ATTORNEY GENERAL LEGAL ACTION.
          YOUR ACCESS AND CONTINUED USE OF ANY OF OUR SITES AND APPS SIGNIFIES
          YOUR EXPLICIT CONSENT TO THIS WAIVER.
        </p>

        <p></p>

        <p>QUESTIONS AND SUGGESTIONS</p>

        <p>
          If you have questions about this Privacy Policy, you can contact us at
          team@adoptconnect.com.
        </p>

        <p></p>

        <p>UPDATES TO PRIVACY POLICY</p>

        <p>
          We may modify this Privacy Policy from time to time by posting updates
          on this page. Please check back periodically to view any updates.
        </p>
      </section>
    </div>
  );
};

privacyPolicy.propTypes = {};

export default privacyPolicy;
