import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Form, Input, Button, Spinner } from 'reactstrap';
import { subscribeToNewsletter } from '../../actions/newsletter';

const NewsletterSignup = ({ subscribeToNewsletter, session }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: ''
  });
  const [inProgress, setInProgress] = useState(false);

  const { email, firstName, lastName } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    subscribeToNewsletter(formData);
    setInProgress(true);
  };

  if (session.subscribedToNewsletter === true) {
    return (
      <Container className="newsletter-signup-container">
        <hr></hr>
        <center>
          <p className="single-panel" style={{ textAlign: 'center' }}>
            Thank you for joining our mailing list!
          </p>
        </center>
      </Container>
    );
  }

  if (inProgress && session.error === undefined) {
    return (
      <Container className="newsletter-signup-container">
        <hr></hr>
        <center>
          <Spinner />
        </center>
      </Container>
    );
  }

  if (session.error?.msg !== undefined) {
    return (
      <Container className="newsletter-signup-container">
        <hr></hr>
        <center>
          <p className="single-panel" style={{ textAlign: 'center' }}>
            You are already subscribed to our mailing list
          </p>
        </center>
      </Container>
    );
  }

  return (
    <Container className="newsletter-signup-container">
      <center>
        <>
          <p className="single-panel" style={{ textAlign: 'center' }}>
            Join our mailing list! <br />
            We promise, no spam. <br />
            Just valuable open adoption information and resources.
          </p>
          <Form onSubmit={onSubmit}>
            <section style={{ width: '70%' }}>
              <div className="row">
                <div className="col-md-6 newsletter-field">
                  <Input
                    id="newsletter-input"
                    type="text"
                    name="firstName"
                    value={firstName}
                    placeholder="First Name"
                    onChange={(e) => onChange(e)}
                  />
                </div>
                <div className="col-md-6 newsletter-field">
                  <Input
                    id="newsletter-input"
                    type="text"
                    name="lastName"
                    value={lastName}
                    placeholder="Last Name"
                    onChange={(e) => onChange(e)}
                  />
                </div>
                <div
                  className="col-md-9 newsletter-field"
                  style={{ justifyItems: 'center' }}
                >
                  <Input
                    id="newsletter-input"
                    type="email"
                    name="email"
                    value={email}
                    placeholder="Email Address"
                    onChange={(e) => onChange(e)}
                  />
                </div>
                <Button type="submit" className="btn-ac-green btn-center">
                  Sign Up
                </Button>
              </div>
            </section>
          </Form>
        </>
      </center>
    </Container>
  );
};

NewsletterSignup.propTypes = {
  subscribeToNewsletter: PropTypes.func.isRequired,
  session: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  auth: state.auth,
  session: state.session
});

export default connect(mapStateToProps, { subscribeToNewsletter })(
  NewsletterSignup
);
