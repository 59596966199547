import React, { useState, useRef } from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col, Container } from 'reactstrap';
import Progress from './Progress';
import { uploadMedia, resetProgress, deleteMedia } from '../../actions/media';
import { Player } from 'video-react';

import '../../../node_modules/video-react/styles/scss/video-react.scss';

/**
 * TODO:
 *  1. Add button to "add" more instead of deleting and starting over
 *  2. Add array sorting function to make image at first index main picture
 *  3.
 *
 */

const VideoUpload = ({
  auth: { user },
  setMedia,
  uploadMedia,
  resetProgress,
  deleteMedia,
  media: { progress, temp }
}) => {
  const [showProgress, setShowProgress] = useState(false);
  const fileInput = useRef();
  const [files, setFiles] = useState();
  const [imagePreviewUrls, setImagePreviewUrls] = useState([
    'https://res.cloudinary.com/puget-sound-networks/image/upload/v1607151274/AC-Web-Assets/image_placeholder_beldhi.jpg'
  ]);
  const [uploaded, setUploaded] = useState();

  const handleClick = () => {
    fileInput.current.click();
  };

  const handleRemove = async () => {
    resetProgress();
    setMedia([]);
    setFiles();
    setUploaded();
    setImagePreviewUrls(['']);
    fileInput.current.value = null;
    if (uploaded?.length) {
      await deleteMedia(temp.map(({ public_id }) => public_id));
    }
  };

  const handleImageChange = (e) => {
    e.preventDefault();
    resetProgress();
    setImagePreviewUrls([]);
    setMedia([]);
    Array.from(e.target.files).forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = (event) => {};
      reader.readAsDataURL(file);
      handleSubmit(Array.from(e.target.files));
    });
  };

  const handleSubmit = (photos) => {
    (async () => {
      setShowProgress(true);
      const images = await Promise.all(
        photos.map((photo) =>
          uploadMedia(photo, `post,user_${user._id},delete`)
        )
      );
      console.log(JSON.stringify(images));
      setUploaded(images);
      setMedia(images);
      setShowProgress(false);
    })();
  };

  return (
    <Container fluid>
      <Row>
        {showProgress !== true ? null : (
          <div
            className="w-100 mb-3"
            style={{
              padding: 20,
              maxWidth: '100%',
              overflow: 'visible',
              justifyContent: 'center'
            }}
          >
            <center>
              <Progress progress={progress} />
            </center>
          </div>
        )}
        {uploaded !== undefined ? (
          <div
            className="w-100 mb-3"
            style={{
              padding: 20,
              maxWidth: '100%',
              overflow: 'visible',
              display: 'inline-flex',
              justifyContent: 'center'
            }}
          >
            <center>
              {showProgress === false ? null : <Progress progress={progress} />}
            </center>
            <Player>
              <source src={uploaded[0]} />
            </Player>
          </div>
        ) : (
          <> </>
        )}
      </Row>

      <Row>
        <div
          className="w-100"
          style={{
            maxHeight: 140,
            maxWidth: '90vw',
            padding: 20,
            overflow: 'auto',
            display: 'inline-flex',
            justifyContent: 'center'
          }}
        >
          {imagePreviewUrls?.slice(1).map((imagePreviewUrl, index) => (
            <img
              key={'key' + imagePreviewUrl[index] + imagePreviewUrl[30] + index}
              src={imagePreviewUrl}
              alt=""
              className="shadow mx-3"
              style={{ height: '100%' }}
              // onClick={() =>
              //   setImagePreviewUrls(current => {
              //     return current;
              //   })
              // }
            />
          ))}
        </div>
      </Row>
      <Row>
        <Col className="d-flex justify-content-center">
          <div>
            {uploaded === undefined ? (
              <Button className="btn-round btn-ac-green" onClick={handleClick}>
                Add Video
              </Button>
            ) : (
              <span>
                <Button
                  color="danger"
                  className="btn-round"
                  onClick={handleRemove}
                >
                  <i className="fa fa-times" /> Remove
                </Button>
                {+progress !== 100 && (
                  <Button
                    className="btn-round"
                    color="default"
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                )}
              </span>
            )}
          </div>
        </Col>
      </Row>
      <div className="fileinput">
        <input
          type="file"
          accept="video/*"
          multiple
          onChange={handleImageChange}
          ref={fileInput}
          data-max-size="10"
        />
      </div>
    </Container>
  );
};

VideoUpload.propTypes = {
  auth: PropTypes.object.isRequired,
  media: PropTypes.object.isRequired,
  deleteMedia: PropTypes.func.isRequired,
  resetProgress: PropTypes.func.isRequired,
  uploadMedia: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  media: state.media
});

export default connect(mapStateToProps, {
  uploadMedia,
  resetProgress,
  deleteMedia
})(VideoUpload);
