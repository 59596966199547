import React, {Fragment} from 'react'
import PropTypes from 'prop-types'

const MarketplaceItem = ({ auth, item: {
    name,
    description,
    link,
    img,
    _id
} }) => {
  return (
    <Fragment>
        <div className="col-md-4">
            <div className="feature feature-1">
            <img src={img} width="100%" alt={name} ></img>
            <div className="feature__body boxed boxed--border">
                <hr></hr>
                <h3>{name}</h3>
                <p>
                    {description}
                </p>
                <hr></hr>
                <a className="btn" href={link}>Buy Now</a>
            </div>
        </div>
        </div>
</Fragment>
)}

MarketplaceItem.propTypes = {
    item: PropTypes.object.isRequired
}

export default MarketplaceItem
