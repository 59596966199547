import React from 'react';

const hiwAdoptionProfessional2 = (props) => {
  return (
    <section className="container sec-pad-adjust">
      <div className="header">
        <h1 className="panel-header">Schedule A Call</h1>
        <h2 className="panel-header-2" style={{ padding: '0px 0px' }}>
          First things first. Let’s talk!
        </h2>
        <p className="ac-text text-align-center">
          Schedule a 20 minute call with one of our founders to learn how
          AdoptConnect can make your clients’ open adoption communication easier
          and more secure.
        </p>
        <p className="ac-text text-align-center">
          AdoptConnect is always free for adoption professionals.
        </p>
      </div>
      <div>
        <iframe
          title="Agency Scheduling Form"
          src="https://ac-agency.youcanbook.me/?noframe=true&skipHeaderFooter=true"
          id="ycbmiframeac-agency"
          style={{
            width: '100%',
            height: '1000px',
            border: '0px',
            backgroundcolor: 'transparent'
          }}
          frameBorder="0"
          allowtransparency="true"
        ></iframe>
        {window.addEventListener &&
          window.addEventListener(
            'message',
            function (event) {
              if (event.origin === 'https://ac-agency.youcanbook.me') {
                document.getElementById('ycbmiframeac-agency').style.height =
                  event.data + 'px';
              }
            },
            false
          )}
      </div>
    </section>
  );
};

export default hiwAdoptionProfessional2;
