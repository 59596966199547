import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import NewsletterSignup from '../forms/newsletterSignup';

const GetStarted = () => {
  var mainSectionStyle = {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url('https://res.cloudinary.com/puget-sound-networks/image/upload/f_auto/v1594937599/ac/About_Us_Banner_pdxzxf.png')`
  };

  return (
    <Fragment>
      <Helmet>
        <title>AdoptConnect - Get Started</title>
      </Helmet>
      <div style={mainSectionStyle} className="banner">
        <h1 className="banner banneroverlay">About Us</h1>
      </div>
      <section
        className="container sec-pad-adjust"
        style={{ marginBottom: '30px' }}
      >
        <div className="spacer"></div>
        <center>
          <div style={{ width: '100%' }}>
            <h2
              className="panel-header-2"
              style={{ textAlign: 'center', paddingBottom: '0px' }}
            >
              Our Mission
            </h2>
            <p
              className="ac-text"
              style={{ fontSize: '1.8em', textAlign: 'left' }}
            >
              We aim to support open adoption relationships through technology,
              education and community.
            </p>
            <p
              className="ac-text"
              style={{ fontSize: '1.8em', textAlign: 'left' }}
            >
              With every choice we make, from security, to pricing, to the
              overall user experience, we aim to honor the child at the center
              of each adoption.
            </p>
            <p
              className="ac-text"
              style={{ fontSize: '1.8em', textAlign: 'left' }}
            >
              We believe that with clear boundaries and a sincere desire for
              meaningful connection…open adoption works!
            </p>
            <h2 className="panel-header-2" style={{ paddingBottom: '0px' }}>
              Our Story
            </h2>
            <p
              className="ac-text"
              style={{ fontSize: '1.8em', textAlign: 'left' }}
            >
              After practicing adoption law for almost twenty years,
              AdoptConnect co-founders, Celeste Liversidge and Jill Cucullu,
              were all too familiar with the challenges many adoptive parents
              and birth parents face while trying to stay in contact after
              adoption.
            </p>
            <p
              className="ac-text"
              style={{ fontSize: '1.8em', textAlign: 'left' }}
            >
              Outdated and unsecured methods of communication made staying in
              contact after adoption much more difficult than it needed to be.
              Recognizing the need for a modernized approach, Jill and Celeste
              turned their focus toward using technology to fill that void.
            </p>
            <p
              className="ac-text"
              style={{ fontSize: '1.8em', textAlign: 'left' }}
            >
              Since 2017, we’ve been working alongside tech and design experts
              to create an easy-to-use, secure digital platform that supports
              birth parents, adoptive parents, adoptees, and adoption
              professionals as they navigate open adoption communication.
            </p>
            <h2 className="panel-header-2" style={{ textAlign: 'center' }}>
              Our Team
            </h2>
            <p
              className="ac-text"
              style={{ fontSize: '1.8em', textAlign: 'left' }}
            >
              We know adoption. The AdoptConnect team consists of adoptees,
              birth parents, adoptive parents, and adoption professionals. Heck,
              even our developer has a personal connection to adoption!
            </p>
          </div>
          <h2 className="panel-header" style={{ paddingBottom: '0px' }}>
            Ready to Connect?
          </h2>
          <Link to="/how-it-works">
            <Button
              className="ralewayBold"
              style={{ backgroundColor: '#e75833', color: 'white' }}
            >
              Get Started
            </Button>
          </Link>
        </center>
      </section>
      <NewsletterSignup />
    </Fragment>
  );
};

GetStarted.propTypes = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(GetStarted);
