import React, {Fragment} from 'react'
import { Offline } from "react-detect-offline";
import { createBrowserHistory } from 'history';

const redirector = createBrowserHistory({
    forceRefresh: true
  });

  // Listen for changes to the current location.
  redirector.listen((location, action) => {
    // location is an object like window.location
    console.log(action, location.pathname, location.state);
  });

const OfflinePage = () => {

return (
<Fragment>
    <div>
    <Offline>Only shown offline (surprise!)</Offline>
    </div>
</Fragment>
)
}

export default OfflinePage;