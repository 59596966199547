import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setNewPassword } from '../../actions/auth';
import { setAlert } from '../../actions/alert';
import LargeAlert from '../layout/LargeAlert';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroup,
  Container,
  Button,
  Spinner,
  Row,
  Col
} from 'reactstrap';
import PasswordStrengthBar from 'react-password-strength-bar';
import passwordValidator from 'fnbpasswordvalidator';
import { Redirect } from 'react-router-dom';

const ResetPassword = ({ setNewPassword, setAlert, match, session }) => {
  const [formData, setFormData] = useState({
    newPassword: '',
    confirmPassword: ''
  });

  const { newPassword, confirmPassword } = formData;
  const [processing, setProcessing] = useState(false);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  function CheckPassword() {
    let passwordDetails = passwordValidator.validate(newPassword, {
      prohibitedWords: ['first_name', 'last_name', 'email'],
      min: 8,
      specialCharacters: true
    });
    if (!passwordDetails.valid) {
      setProcessing(false);
      setAlert(passwordDetails.message, 'danger');
    } else {
      setNewPassword({ id: match.params.id, newPassword: newPassword });
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setAlert(`Passwords don't match`, 'danger');
    } else {
      setProcessing(true);
      CheckPassword(newPassword);
    }
  };

  if (session.passwordSet) {
    return <Redirect to="/login" />;
  }

  return (
    <div className="fullPage">
      <div className="content">
        <Container className="login-container">
          <Row>
            <center>
              <Col md="12">
                <Card className="card-plain reset-password-card">
                  <Form
                    onSubmit={onSubmit}
                    className="form reset-password-form"
                  >
                    {processing ? (
                      <CardHeader className="text-center">
                        <center>
                          <Spinner />
                        </center>
                        <p
                          style={{ textAlign: 'left', fontSize: '1em' }}
                          className="ac-text"
                        >
                          Password Reset Processing
                        </p>
                      </CardHeader>
                    ) : (
                      <>
                        <CardHeader className="text-center">
                          <h3
                            style={{ textAlign: 'left', paddingBottom: '0px' }}
                            className="panel-header"
                          >
                            Create New Password
                          </h3>
                          <p
                            style={{ textAlign: 'left', fontSize: '1em' }}
                            className="ac-text"
                          >
                            In order to protect your account, please make sure
                            your password:
                            <ul>
                              <li>Is longer than 8 characters</li>
                              <li>
                                Contains 1 uppercase letter, 1 lowercase letter,
                                1 number and a special character
                              </li>
                              <li>
                                Does not match or contain your name or email
                                address, e.g. do not use 'name123'.
                              </li>
                            </ul>
                          </p>
                        </CardHeader>
                        <CardBody>
                          <LargeAlert />
                          <InputGroup>
                            <Input
                              placeholder="New Password"
                              type="password"
                              name="newPassword"
                              value={newPassword}
                              onChange={(e) => onChange(e)}
                              required
                            ></Input>
                          </InputGroup>
                          <center>
                            <PasswordStrengthBar
                              password={newPassword}
                              style={{ width: '90%', marginTop: '-5px' }}
                            />
                          </center>
                          <InputGroup>
                            <Input
                              placeholder="Confirm Password"
                              type="password"
                              name="confirmPassword"
                              value={confirmPassword}
                              onChange={(e) => onChange(e)}
                              required
                            ></Input>
                          </InputGroup>
                        </CardBody>
                        <CardFooter className="text-center">
                          <Button className="btn" type="submit" value="login">
                            Change Password
                          </Button>
                        </CardFooter>
                      </>
                    )}
                  </Form>
                </Card>
              </Col>
            </center>
          </Row>
        </Container>
      </div>
    </div>
  );
};

ResetPassword.propTypes = {
  setNewPassword: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  auth: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  auth: state.auth,
  session: state.session
});

export default connect(mapStateToProps, { setNewPassword, setAlert })(
  ResetPassword
);
