import {
  GET_DOCUMENTS,
  DOCUMENTS_ERROR,
  ADD_DOCUMENT,
  DOCUMENT_DELETED
} from '../actions/types';

const initialState = {
  documents: [],
  documentsLoading: true,
  error: {}
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_DOCUMENT:
      return {
        ...state,
        documents: [payload, ...state.documents],
        loading: false
      };
    case GET_DOCUMENTS:
      return {
        ...state,
        documents: payload.data,
        documentsLoading: false
      };
    case DOCUMENTS_ERROR:
      return {
        ...state,
        error: payload,
        documentsLoading: false
      };
    case DOCUMENT_DELETED:
      return {
        ...state,
        documents: state.documents.filter(
          (document) => document._id !== payload
        ),
        documentsLoading: false
      };
    default:
      return state;
  }
}
