import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { loadUser, confirmEmail } from '../../actions/auth';
import Spinner from '../layout/Spinner';
import { CardHeader, Button, Card } from 'reactstrap';

const Confirm = ({
  match,
  loadUser,
  confirmEmail,
  auth: { user, isAuthenticated, loading }
}) => {
  useEffect(() => {
    loadUser();
    confirmEmail(match.params.id);
  }, [confirmEmail, loadUser, match.params.id]);

  // While the email address is being confirmed on the server a spinner is
  // shown that gives visual feedback. Once the email has been confirmed the
  // spinner is stopped and turned into a button that takes the user back to the
  // <Timeline > component.
  if (isAuthenticated && !loading) {
    return (
      <div className="confirm">
        {match.params.id === user.confirmationtoken && !user.confirmed ? (
          <Fragment>
            <Spinner />
            <h1>Confirming</h1>
          </Fragment>
        ) : (
          <Fragment>
            {isAuthenticated ? (
              <Redirect to="/timeline" />
            ) : (
              <center>
                <div className="row center-card">
                  <Card
                    className="col-md-7"
                    style={{ padding: '40px 10px 40px 10px' }}
                  >
                    <CardHeader>
                      <h3>Please Login to Confirm Account</h3>
                      <Link to={`/login?nxtpg=/confirm/${match.params.id}`}>
                        <Button>Login</Button>
                      </Link>
                    </CardHeader>
                  </Card>
                </div>
              </center>
            )}
          </Fragment>
        )}
      </div>
    );
  } else {
    return (
      <Fragment>
        {isAuthenticated ? (
          <Redirect to="/timeline" />
        ) : (
          <center>
            <div className="row center-card">
              <Card
                className="col-md-7"
                style={{ padding: '40px 10px 40px 10px' }}
              >
                <CardHeader>
                  <h3>Please Login to Confirm Account</h3>
                  <Link to={`/login?nxtpg=/confirm/${match.params.id}`}>
                    <Button>Login</Button>
                  </Link>
                </CardHeader>
              </Card>
            </div>
          </center>
        )}
      </Fragment>
    );
  }
};

Confirm.propTypes = {
  loadUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  confirmEmail: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.auth.user
});

export default connect(mapStateToProps, { loadUser, confirmEmail })(Confirm);
