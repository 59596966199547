import axios from 'axios';
import { DEMO_REQUEST } from './types';
import { API_URL } from '../config/default.json';

export const requestDemoAccount = (data) => async (dispatch) => {
  try {
    const firstName = data.firstName;
    const lastName = data.lastName;
    const email = data.email;

    const formData = {
      firstName: firstName,
      lastName: lastName,
      email: email
    };

    const res = await axios.post(`${API_URL}/demo/requestaccount`, formData);
    console.log('RESPONSE:', res);
    dispatch({
      type: DEMO_REQUEST,
      payload: res.data
    });
  } catch (err) {
    console.log('ERROR', err);
  }
};
