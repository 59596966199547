import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import { getBlogPostByID } from '../../actions/blog';
import { BLOG_URL } from '../../config/default.json';

const Post = ({ getBlogPostByID, blog: { blogpost, loading }, match }) => {
  useEffect(() => {
    getBlogPostByID(match.params.id);
  }, [getBlogPostByID, match.params.id]);

  return loading || blogpost === null ? (
    <Spinner />
  ) : (
    <Fragment>
      <center>
        <div
          style={{
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${BLOG_URL}${blogpost.cover_image?.url})`,
            zIndex: '-1'
          }}
          className="blogBanner"
        >
          <div className="blogBannerShade">
            <h1 className="blogTitle banner banneroverlay">{blogpost.Title}</h1>
            <center>
              <div className="flex blogSeparator" />
            </center>
            <h3
              className="banner blogBannerOverlaySubtitle"
              style={{ paddingTop: '20px' }}
            >
              {blogpost.Subtitle}
            </h3>
          </div>
        </div>
      </center>
      <div className="spacer" />
      <div className="blogbody">
        <div dangerouslySetInnerHTML={{ __html: blogpost.body }} />
      </div>
    </Fragment>
  );
};

Post.propTypes = {
  getBlogPostByID: PropTypes.func.isRequired,
  blog: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  blog: state.blog
});

export default connect(mapStateToProps, { getBlogPostByID })(Post);
