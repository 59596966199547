import React from 'react';
import { Transformation } from 'cloudinary-react';
import Image from './Image';

const ProfileImage = ({
  publicId = 'ac/profile_placeholder',
  width = 250,
  height = 250,
  ...props
}) => {
  return (
    <Image publicId={publicId} {...props}>
      <Transformation
        width={width}
        height={height}
        gravity="faces"
        crop="fill"
      />
    </Image>
  );
};

export default ProfileImage;
