import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const Toaster = ({ auth: { user, loading } }) => {
  return loading ? (
    ''
  ) : (
    <Fragment>
      {user?.confirmed === true ? (
        <div />
      ) : (
        <div className="alert alert-danger" role="alert">
          <center>
            <h3 className="alert-heading">Account Not Activated</h3>
            <h4>Please confirm email to enable account</h4>
          </center>
        </div>
      )}
    </Fragment>
  );
};

Toaster.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, {})(Toaster);
