import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import {
  createProfile,
  getCurrentProfile,
  deleteAccount
} from '../../actions/profile';
import UploadImage from './../upload-image/UploadProfileImage';
import { getMyInvites } from '../../actions/auth';
import TwoWayCommunicationToggle from './TwoWayCommunicationToggle';
import { isBrowser, isIPad13, isMobile } from 'react-device-detect';

import infoIcon from '../../assets/img/info_icon.png';
import gearIcon from '../../assets/img/gear_icon.png';
import profilePhotoIcon from '../../assets/img/profile_icon.png';
import invitationIcon from '../../assets/img/invitation_icon.png';
import Invitations from './Invitations';

const EditProfile = ({
  profile: { profile, loading },
  user,
  getCurrentProfile,
  getMyInvites
}) => {
  const [formData, setFormData] = useState({
    bio: '',
    location: '',
    image: ''
  });
  const [editOptions, setEditOptions] = useState('PersonalInfo');

  useEffect(() => {
    getCurrentProfile();
    getMyInvites();
    profile &&
      setFormData({
        bio: profile.bio,
        location: profile.location,
        image:
          profile.user?.image?.squareUrl ||
          profile?.user?.avatar ||
          'https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png'
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, getCurrentProfile, getMyInvites]);

  const { image } = formData;

  return (
    <Container>
      {isIPad13 ? (
        <>
          <Row className="spacer-bottom" />
          <h4 style={{ fontSize: '3em' }}>My Account</h4>
          <Row className="profile-edit-container">
            <Col md="4">
              <Row>
                <ul className="profile-edit-menu">
                  {editOptions !== 'PersonalInfo' ? (
                    <li className="profile-edit-options">
                      <img
                        src={infoIcon}
                        alt="Info icon"
                        style={{
                          width: '40px',
                          verticalAlign: 'middle',
                          marginLeft: '10px'
                        }}
                      />
                      <p
                        className="profile-edit-option-text"
                        onClick={(input) => setEditOptions('PersonalInfo')}
                      >
                        Contact Information
                      </p>
                    </li>
                  ) : (
                    <li className="profile-edit-options edit-option-selected">
                      <img
                        src={infoIcon}
                        alt="Info icon"
                        style={{
                          width: '40px',
                          height: '40px',
                          marginLeft: '10px'
                        }}
                      />
                      <p
                        className="profile-edit-option-text"
                        onClick={(input) => setEditOptions('PersonalInfo')}
                      >
                        Contact Information
                      </p>
                    </li>
                  )}
                  {editOptions !== 'Profile' ? (
                    <li className="profile-edit-options">
                      <img
                        src={profilePhotoIcon}
                        alt="Info icon"
                        style={{
                          width: '40px',
                          height: '40px',
                          marginLeft: '10px'
                        }}
                      />
                      <p
                        className="profile-edit-option-text"
                        onClick={(input) => setEditOptions('Profile')}
                      >
                        My Profile Photo
                      </p>
                    </li>
                  ) : (
                    <li className="profile-edit-options edit-option-selected">
                      <img
                        src={profilePhotoIcon}
                        alt="Info icon"
                        style={{
                          width: '40px',
                          height: '40px',
                          marginLeft: '10px'
                        }}
                      />
                      <p
                        className="profile-edit-option-text"
                        onClick={(input) => setEditOptions('Profile')}
                      >
                        My Profile Photo
                      </p>
                    </li>
                  )}
                  {user.accountType === 'birthParent' ||
                  user.subscriptionCreation === 'agency' ? null : (
                    <>
                      {editOptions !== 'Invitations' ? (
                        <li className="profile-edit-options">
                          <img
                            src={invitationIcon}
                            alt="Info icon"
                            style={{
                              width: '40px',
                              verticalAlign: 'middle',
                              marginLeft: '10px'
                            }}
                          />
                          <p
                            className="profile-edit-option-text"
                            onClick={(input) => setEditOptions('Invitations')}
                          >
                            Invitations
                          </p>
                        </li>
                      ) : (
                        <li className="profile-edit-options edit-option-selected">
                          <img
                            src={invitationIcon}
                            alt="Info icon"
                            style={{
                              width: '40px',
                              verticalAlign: 'middle',
                              marginLeft: '10px'
                            }}
                          />
                          <p
                            className="profile-edit-option-text"
                            onClick={(input) => setEditOptions('Invitations')}
                          >
                            Invitations
                          </p>
                        </li>
                      )}
                    </>
                  )}
                  {user.accountType === 'birthParent' ? null : (
                    <div>
                      {editOptions !== 'Preferences' ? (
                        <li className="profile-edit-options">
                          <img
                            src={gearIcon}
                            alt="Info icon"
                            style={{
                              width: '40px',
                              verticalAlign: 'middle',
                              marginLeft: '10px'
                            }}
                          />
                          <p
                            className="profile-edit-option-text"
                            onClick={(input) => setEditOptions('Preferences')}
                          >
                            Settings
                          </p>
                        </li>
                      ) : (
                        <li className="profile-edit-options edit-option-selected">
                          <img
                            src={gearIcon}
                            alt="Info icon"
                            style={{
                              width: '40px',
                              verticalAlign: 'middle',
                              marginLeft: '10px'
                            }}
                          />
                          <p
                            className="profile-edit-option-text"
                            onClick={(input) => setEditOptions('Preferences')}
                          >
                            Settings
                          </p>
                        </li>
                      )}
                    </div>
                  )}
                </ul>
              </Row>
            </Col>
            <Col
              md="8"
              className="profile-edit-box"
              style={{ borderLeftWidth: '1px' }}
            >
              {editOptions === 'Profile' ? (
                <section>
                  <h4>My Profile Photo</h4>
                  <UploadImage setFormData={setFormData} initialState={image} />
                </section>
              ) : null}
              {editOptions === 'PersonalInfo' ? (
                <section>
                  {user.accountType === 'adoptiveParent' ? (
                    <h4>Adoptive Parent Profile</h4>
                  ) : null}
                  {user.accountType === 'birthParent' ? (
                    <h4>Birth Parent Profile</h4>
                  ) : null}
                  <hr />
                  <p>
                    <span className="editProfileTitle">First Name:</span>{' '}
                    {user.parent1_first_name}
                  </p>
                  <p>
                    <span className="editProfileTitle">Last Name:</span>{' '}
                    {user.parent1_last_name}
                  </p>
                  <p>
                    <span className="editProfileTitle">Email:</span>{' '}
                    {user.email}
                  </p>
                  <hr />
                  {user.accountType === 'birthParent' ||
                  user.parent2_email === undefined ? null : (
                    <>
                      <h4 style={{ color: '#E75B37', marginTop: '0' }}>
                        Other Parent
                      </h4>
                      <p>
                        <span className="editProfileTitle">First Name: </span>
                        {user.parent2_first_name !== ''
                          ? user.parent2_first_name
                          : 'No First Name Entered'}
                      </p>
                      <p>
                        <span className="editProfileTitle">Last Name: </span>
                        {user.parent2_last_name !== ''
                          ? user.parent2_last_name
                          : 'No Last Name Entered'}
                      </p>
                      <p>
                        <span className="editProfileTitle">Email: </span>
                        {user.parent2_email !== undefined
                          ? user.parent2_email
                          : 'No Email Entered'}
                      </p>
                      <hr />
                    </>
                  )}
                </section>
              ) : null}
              {editOptions === 'Preferences' ? (
                <div>
                  <TwoWayCommunicationToggle />
                </div>
              ) : null}
            </Col>
          </Row>
        </>
      ) : null}
      {isBrowser ? (
        <>
          <Row className="spacer-bottom" />
          <Row className="profile-edit-container">
            {isMobile ? null : <Col md="1"></Col>}
            <Col md="3" className="profile-options">
              <h4 style={{ fontSize: '2em', marginTop: '0px' }}>My Account</h4>
              <Row style={{ marginBottom: '100px' }}>
                <ul className="profile-edit-menu">
                  {editOptions !== 'PersonalInfo' ? (
                    <li className="profile-edit-options">
                      <img
                        src={infoIcon}
                        alt="Info icon"
                        style={{
                          width: '40px',
                          verticalAlign: 'middle',
                          marginLeft: '10px'
                        }}
                      />
                      <p
                        className="profile-edit-option-text"
                        onClick={(input) => setEditOptions('PersonalInfo')}
                      >
                        Contact Information
                      </p>
                    </li>
                  ) : (
                    <li className="profile-edit-options edit-option-selected">
                      <img
                        src={infoIcon}
                        alt="Info icon"
                        style={{
                          width: '40px',
                          height: '40px',
                          marginLeft: '10px'
                        }}
                      />
                      <p
                        className="profile-edit-option-text"
                        onClick={(input) => setEditOptions('PersonalInfo')}
                      >
                        Contact Information
                      </p>
                    </li>
                  )}
                  {editOptions !== 'Profile' ? (
                    <li className="profile-edit-options">
                      <img
                        src={profilePhotoIcon}
                        alt="Info icon"
                        style={{
                          width: '40px',
                          height: '40px',
                          marginLeft: '10px'
                        }}
                      />
                      <p
                        className="profile-edit-option-text"
                        onClick={(input) => setEditOptions('Profile')}
                      >
                        My Profile Photo
                      </p>
                    </li>
                  ) : (
                    <li className="profile-edit-options edit-option-selected">
                      <img
                        src={profilePhotoIcon}
                        alt="Info icon"
                        style={{
                          width: '40px',
                          height: '40px',
                          marginLeft: '10px'
                        }}
                      />
                      <p
                        className="profile-edit-option-text"
                        onClick={(input) => setEditOptions('Profile')}
                      >
                        My Profile Photo
                      </p>
                    </li>
                  )}
                  {user.accountType === 'birthParent' ||
                  user.subscriptionCreation === 'agency' ? null : (
                    <>
                      {editOptions !== 'Invitations' ? (
                        <li className="profile-edit-options">
                          <img
                            src={invitationIcon}
                            alt="Info icon"
                            style={{
                              width: '40px',
                              verticalAlign: 'middle',
                              marginLeft: '10px'
                            }}
                          />
                          <p
                            className="profile-edit-option-text"
                            onClick={(input) => setEditOptions('Invitations')}
                          >
                            Invitations
                          </p>
                        </li>
                      ) : (
                        <li className="profile-edit-options edit-option-selected">
                          <img
                            src={invitationIcon}
                            alt="Info icon"
                            style={{
                              width: '40px',
                              verticalAlign: 'middle',
                              marginLeft: '10px'
                            }}
                          />
                          <p
                            className="profile-edit-option-text"
                            onClick={(input) => setEditOptions('Invitations')}
                          >
                            Invitations
                          </p>
                        </li>
                      )}
                    </>
                  )}
                  {user.accountType === 'birthParent' ? null : (
                    <div>
                      {editOptions !== 'Preferences' ? (
                        <li className="profile-edit-options">
                          <img
                            src={gearIcon}
                            alt="Info icon"
                            style={{
                              width: '40px',
                              verticalAlign: 'middle',
                              marginLeft: '10px'
                            }}
                          />
                          <p
                            className="profile-edit-option-text"
                            onClick={(input) => setEditOptions('Preferences')}
                          >
                            Settings
                          </p>
                        </li>
                      ) : (
                        <li className="profile-edit-options edit-option-selected">
                          <img
                            src={gearIcon}
                            alt="Info icon"
                            style={{
                              width: '40px',
                              verticalAlign: 'middle',
                              marginLeft: '10px'
                            }}
                          />
                          <p
                            className="profile-edit-option-text"
                            onClick={(input) => setEditOptions('Preferences')}
                          >
                            Settings
                          </p>
                        </li>
                      )}
                    </div>
                  )}
                </ul>
              </Row>
            </Col>
            <Col md="7" className="profile-edit-box">
              {editOptions === 'Profile' ? (
                <section>
                  <h4>My Profile Photo</h4>
                  <UploadImage setFormData={setFormData} initialState={image} />
                </section>
              ) : null}
              {editOptions === 'PersonalInfo' ? (
                <section>
                  {user.accountType === 'adoptiveParent' ? (
                    <h4>Adoptive Parent Profile</h4>
                  ) : null}
                  {user.accountType === 'birthParent' ? (
                    <h4>Birth Parent Profile</h4>
                  ) : null}
                  <hr />
                  <p>
                    <span className="editProfileTitle">First Name:</span>{' '}
                    {user.parent1_first_name}
                  </p>
                  <p>
                    <span className="editProfileTitle">Last Name:</span>{' '}
                    {user.parent1_last_name}
                  </p>
                  <p>
                    <span className="editProfileTitle">Email:</span>{' '}
                    {user.email}
                  </p>
                  <hr />
                  To change your email address, please contact:
                  support@adoptconnect.com
                  <hr />
                  {user.accountType === 'birthParent' ||
                  user.parent2_email === undefined ? null : JSON.stringify(
                      user.parent2_email
                    ) === '""' ? null : (
                    <>
                      <h4 style={{ color: '#E75B37', marginTop: '0' }}>
                        Other Parent
                      </h4>
                      <p>
                        <span className="editProfileTitle">First Name: </span>
                        {user.parent2_first_name !== ''
                          ? user.parent2_first_name
                          : 'No First Name Entered'}
                      </p>
                      <p>
                        <span className="editProfileTitle">Last Name: </span>
                        {user.parent2_last_name !== ''
                          ? user.parent2_last_name
                          : 'No Last Name Entered'}
                      </p>
                      <p>
                        <span className="editProfileTitle">Email: </span>
                        {user.parent2_email !== undefined
                          ? user.parent2_email
                          : 'No Email Entered'}
                      </p>
                      <hr />
                    </>
                  )}
                </section>
              ) : null}
              {editOptions === 'Invitations' ? <Invitations /> : null}
              {editOptions === 'Preferences' ? (
                <div>
                  <TwoWayCommunicationToggle />
                </div>
              ) : null}
            </Col>
            <Col md="1"></Col>
          </Row>
        </>
      ) : null}
      {isMobile ? (
        <div>
          <section>
            <h4>My Profile Photo</h4>
            <UploadImage setFormData={setFormData} initialState={image} />
          </section>
          <section>
            {user.accountType === 'adoptiveParent' ? (
              <h4>Adoptive Parent Profile</h4>
            ) : null}
            {user.accountType === 'birthParent' ? (
              <h4>Birth Parent Profile</h4>
            ) : null}
            <hr />
            <p>
              <span className="editProfileTitle">First Name:</span>{' '}
              {user.parent1_first_name}
            </p>
            <p>
              <span className="editProfileTitle">Last Name:</span>{' '}
              {user.parent1_last_name}
            </p>
            <p>
              <span className="editProfileTitle">Email:</span> {user.email}
            </p>
            <hr />
            To change your email address, please contact:
            support@adoptconnect.com
            <hr />
            {user.accountType === 'birthParent' ||
            user.parent2_email === undefined ? null : JSON.stringify(
                user.parent2_email
              ) === '""' ? null : (
              <>
                <h4 style={{ color: '#E75B37', marginTop: '0' }}>
                  Other Parent
                </h4>
                <p>
                  <span className="editProfileTitle">First Name: </span>
                  {user.parent2_first_name !== ''
                    ? user.parent2_first_name
                    : 'No First Name Entered'}
                </p>
                <p>
                  <span className="editProfileTitle">Last Name: </span>
                  {user.parent2_last_name !== ''
                    ? user.parent2_last_name
                    : 'No Last Name Entered'}
                </p>
                <p>
                  <span className="editProfileTitle">Email: </span>
                  {user.parent2_email !== undefined
                    ? user.parent2_email
                    : 'No Email Entered'}
                </p>
                <hr />
              </>
            )}
          </section>
          {user.accountType === 'adoptiveParent' ? (
            <>
              <div>
                <Invitations />
              </div>
              <div style={{ marginBottom: '180px' }}>
                <TwoWayCommunicationToggle />
              </div>
            </>
          ) : null}
        </div>
      ) : null}
    </Container>
  );
};

EditProfile.propTypes = {
  createProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  getMyInvites: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  invitations: PropTypes.object.isRequired,
  history: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  user: state.user,
  auth: state.auth,
  invitations: state.invitations
});

export default connect(mapStateToProps, {
  createProfile,
  getCurrentProfile,
  getMyInvites,
  deleteAccount
})(withRouter(EditProfile));
