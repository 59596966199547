import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card, CardHeader, CardBody, Button, Spinner } from 'reactstrap';
import { getDocuments, deleteDocument } from '../../actions/document';
import DocumentUploader from './DocumentUploader';
import Moment from 'react-moment';
import { Helmet } from 'react-helmet';

const Documents = ({
  user,
  documents: { documents, documentsLoading },
  deleteDocument,
  getDocuments,
  uploadMedia
}) => {
  useEffect(() => {
    getDocuments();
  }, [getDocuments]);

  function removeDocument(id) {
    console.log(id);
    deleteDocument(id);
    getDocuments();
  }

  if (documentsLoading) {
    return (
      <center>
        <Spinner />
        <br />
        <h3>Loading</h3>
      </center>
    );
  } else {
    return (
      <center>
        <Helmet>
          <title>AdoptConnect - Documents</title>
          <meta name="description" content="Adopt Connect Blog" />
        </Helmet>
        <Card className="col-md-8">
          <div className="spacer-top"> </div>
          <CardHeader className="panel-header">Documents</CardHeader>
          <CardBody style={{ textAlign: 'left' }}>
            <center>
              {user?.accountType === 'birthParent' &&
              user?.settings.twowaycommunication === false ? null : (
                <DocumentUploader />
              )}
            </center>
            <table className="table table-hover">
              <thead>
                <tr>
                  <th style={{ width: '2%' }}></th>
                  <th style={{ width: '4%' }}></th>
                  <th style={{ width: '70%' }}></th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {documents.length !== 0
                  ? documents.map((e) => (
                      <>
                        <tr key={e._id}>
                          <td />
                          <td>
                            <img
                              src="https://res.cloudinary.com/puget-sound-networks/image/upload/f_auto/c_scale,w_42/v1594174340/1200px-PDF_file_icon.svg_son0bu.png"
                              alt="PDF Logo"
                              style={{ minWidth: '40px' }}
                            />
                          </td>
                          <td>
                            <a
                              href={e.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <div className="ac-text">{e.fileName}</div>
                              <div>
                                Uploaded by{' '}
                                {e.user._id === user._id ? 'you' : e.user.name}{' '}
                                {<Moment fromNow>{e.createdAt}</Moment>}{' '}
                              </div>
                            </a>
                          </td>
                          <td></td>
                          {e.user._id === user._id ? (
                            <td>
                              <Button
                                onClick={(doc) => removeDocument(e._id)}
                                color="primary"
                              >
                                Delete
                              </Button>
                            </td>
                          ) : (
                            <td></td>
                          )}
                        </tr>
                      </>
                    ))
                  : null}
              </tbody>
            </table>
            {documents.length === 0 ? (
              user?.accountType === 'birthParent' &&
              user?.settings.twowaycommunication === false ? (
                <center>
                  <p className="ac-text">
                    You'll receive a notification each time a new document is
                    shared with you.
                  </p>
                </center>
              ) : (
                <center>
                  <p className="ac-text">
                    You haven't posted a document yet. <br /> To get started,
                    click "Upload Document".
                  </p>
                </center>
              )
            ) : null}
          </CardBody>
        </Card>
      </center>
    );
  }
};
Documents.propTypes = {
  documents: PropTypes.object.isRequired,
  getDocuments: PropTypes.func.isRequired,
  deleteDocument: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user,
  documents: state.documents
});

export default connect(mapStateToProps, { getDocuments, deleteDocument })(
  Documents
);
