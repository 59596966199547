export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILES = 'GET_PROFILES';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED';
export const GET_POSTS = 'GET_POSTS';
export const GET_POST = 'GET_POST';
export const ADD_POST = 'ADD_POST';
export const DELETE_POST = 'DELETE_POST';
export const ADD_COMMENT = 'ADD_COMMENT';
export const REMOVE_COMMENT = 'REMOVE_COMMENT';
export const POST_ERROR = 'POST_ERROR';
export const UPDATE_LIKES = 'UPDATE_LIKES';
export const GET_ITEMS = 'GET_ITEMS';
export const ITEM_ERROR = 'ITEM_ERROR';
export const GET_INSTAFEED = 'GET_INSTAFEED';
export const INSTAFEED_ERROR = 'INSTAFEED_ERROR';
export const GET_BLOG_POST = 'GET_BLOG_POST';
export const GET_BLOG_POSTS = 'GET_BLOG_POSTS';
export const BLOG_POST_ERROR = 'BLOG_POST_ERROR';
export const CONNECTIONS_ERROR = 'CONNECTIONS_ERROR';
export const GET_CONNECTIONS = 'GET_CONNECTIONS';
export const BP_INVITATION_ERROR = 'BP_INVITATION_ERROR';
export const GET_BP_INVITATION = 'GET_BP_INVITATION';
export const EMAIL_CONFIRMED = 'EMAIL_CONFIRMED';
export const EMAIL_CONF_ERROR = 'EMAIL_CONF_ERROR';
export const PAYMENT_CONFIRMED = 'PAYMENT_CONFIRMED';
export const PAYMENT_ERROR = 'PAYMENT_ERROR';
export const GET_USER_EVENTS = 'GET_USER_EVENTS';
export const GET_CONNECTION_EVENTS = 'GET_CONNECTION_EVENTS';
export const GET_EVENTS = 'GET_EVENTS';
export const CREATE_EVENT = 'CREATE_EVENT';
export const DELETE_EVENT = 'DELETE_EVENT';
export const MEDIA_UPLOADED = 'MEDIA_UPLOADED';
export const UPDATE_UPLOADING_PROGRESS = 'UPDATE_UPLOADING_PROGRESS';
export const UPDATE_UPLOADED_MEDIA = 'UPDATE_UPLOADED_MEDIA';
export const MEDIA_ERROR = 'MEDIA_ERROR';
export const DELETE_MEDIA = 'DELETE_MEDIA';
export const ADD_POST_REPORT = 'ADD_POST_REPORT';
export const INVITATION_RETRIEVED = 'INVITATION_RETRIEVED';
export const INVITATION_ERROR = 'INVITATION_ERROR';
export const INVITATIONS_RETRIEVED = 'INVITATIONS_RETRIEVED';
export const SUBSCRIBED_TO_NEWSLETTER = 'SUBSCRIBED_TO_NEWSLETTER';
export const UNSUBSCRIBED_TO_NEWSLETTER = 'UNSUBSCRIBED_TO_NEWSLETTER';
export const NEWSLETTER_ERROR = 'NEWSLETTER_ERROR';
export const GET_EVENT = 'GET_EVENT';
export const OPEN_EVENT_MODAL = 'OPEN_EVENT_MODAL';
export const CLOSE_EVENT_MODAL = 'CLOSE_EVENT_MODAL';
export const OPEN_EDIT_EVENT_MODAL = 'OPEN_EDIT_EVENT_MODAL';
export const CLOSE_EDIT_EVENT_MODAL = 'CLOSE_EDIT_EVENT_MODAL';
export const CREATE_TICKET = 'CREATE_TICKET';
export const TICKET_ERROR = 'TICKET_ERROR';
export const DEMO_REQUEST = 'DEMO_REQUEST';
export const PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST';
export const PASSWORD_SET = 'PASSWORD_SET';
export const GET_DOCUMENTS = 'GET_DOCUMENTS';
export const DOCUMENTS_ERROR = 'DOCUMENTS_ERROR';
export const ADD_DOCUMENT = 'ADD_DOCUMENT';
export const DOCUMENT_DELETED = 'DOCUMENT_DELETED';
export const PASSWORD_SET_CLEANUP = 'PASSWORD_SET_CLEANUP';
export const INVITATION_UPDATED = 'INVITATION_UPDATED';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const SET_NO_NOTIFICATIONS = 'SET_NO_NOTIFICATIONS';
