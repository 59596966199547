import React, { Fragment, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Col, Button, Row, Modal, ModalBody, ModalHeader } from 'reactstrap';

import hiwBackground from '../../assets/img/hiwBackground.png';
import hiw1 from '../../assets/img/hiwbp1.png';
import hiw2 from '../../assets/img/hiwbp2.png';
import hiw3 from '../../assets/img/hiwbp3.png';
import NewsletterSignup from '../forms/newsletterSignup';

const HiwBirthParent = (pathname) => {
  const [modalStatus, setModalStatus] = useState(false);

  function toggle() {
    setModalStatus(!modalStatus);
  }

  var mainSectionStyle = {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${hiwBackground})`
  };

  return (
    <Fragment>
      <Helmet>
        <title>Adopt Connect - How It Works - Adoptive Parent</title>
      </Helmet>
      <Modal
        isOpen={modalStatus}
        toggle={toggle}
        style={{ maxWidth: '1200px' }}
      >
        <ModalHeader toggle={toggle}>
          Professional Dashboard Preview
        </ModalHeader>
        <ModalBody>
          <img
            src="https://adoptconnect.b-cdn.net/sampleTimeline.gif"
            alt="Adoption Professional Dashboard Preview"
            width="100%"
          />
        </ModalBody>
      </Modal>
      <div style={mainSectionStyle} className="banner">
        <h1 className="banner banneroverlay">How it Works</h1>
      </div>
      <section className="container sec-pad-adjust">
        <h4 className="panel-header">
          AdoptConnect makes it easy for Birth Parents to view and save the
          updates, photos and video they receive from their child’s Adoptive
          Parent throughout the years.
        </h4>
        <div className="row">
          <Col className="hiwAPcol" md="6" xs="6" sm="4">
            <img className="hiwAPImg" src={hiw1} alt=""></img>
          </Col>
          <Col
            className="hiwAPcol hiwColText ac-text d-flex"
            md="6"
            xs="6"
            sm="4"
          >
            With an AdoptConnect account, Birth Parents may view content at any
            time, from any device, by simply logging on to their free account.
          </Col>
          <Col
            className="hiwAPcol hiwColText ac-text d-flex"
            md="6"
            xs="6"
            sm="4"
          >
            AdoptConnect sends reminders to Adoptive Parents before each update
            is due. If the update is not posted as agreed, the Adoption
            Professional is notified and may follow up with the Adoptive
            Parents.*
          </Col>
          <Col className="hiwAPcol" md="6" xs="6" sm="4">
            <img className="hiwAPImg" src={hiw2} alt=""></img>
          </Col>
          <Col className="hiwAPcol" md="6" xs="6" sm="4">
            <img className="hiwAPImg" src={hiw3} alt=""></img>
          </Col>
          <Col
            className="hiwAPcol hiwColText ac-text d-flex"
            md="6"
            xs="6"
            sm="4"
          >
            Birth Parents receive a notification each time something new is
            posted to their AdoptConnect Timeline.©
          </Col>
        </div>
        <Col md="12" xs="auto">
          <p>* Applies to professional accounts</p>
          <center>
            <Button onClick={toggle} className="btn-ac-green btn-preview-popup">
              View Sample AdoptConnect Timeline
            </Button>
          </center>
          <h4 className="panel-header" style={{ fontSize: '2.3em' }}>
            “I like being notified when a new update is posted. Instead of
            photos and updates just showing up on my phone, I can login to my
            AdoptConnect account when I’m in the right frame of mind and look at
            everything there.”
          </h4>
          <h4
            className="panel-header-2"
            style={{ fontSize: '2em', color: 'black', paddingTop: 0 }}
          >
            - Jessica, Birth Mother
          </h4>
        </Col>
        <Row style={{ border: '1px solid black', margin: '2em 2em' }}>
          <Col md="9" style={{ paddingLeft: '10px' }}>
            <center>
              <h4
                className="panel-header-3 text-left"
                style={{ paddingTop: '0px', paddingBottom: '0px' }}
              >
                Share AdoptConnect!
              </h4>
              <p
                className="ac-text text-left"
                style={{ paddingBottom: '10px' }}
              >
                Click this link to tell your Adoption Professional or your
                child’s Adoptive Parents about AdoptConnect.
              </p>
            </center>
          </Col>
          <Col md="3">
            <a href="mailto:?subject=Check%20out%20AdoptConnect!&amp;body=Hello!%20I%20wanted%20to%20let%20you%20know%20about%20this%20online%20platform%20called%20AdoptConnect%20that%20helps%20adoptive%20parents%20and%20birth%20parents%20stay%20connected%20after%20adoption.%20Instead%20of%20posting%20updates%20on%20social%20media%2C%20sending%20them%20through%20an%20agency%20or%20in%20the%20mail%2C%20adoptive%20parents%20can%20now%20safely%20and%20easily%20share%20photos%2C%20video%2C%20and%20written%20updates%20with%20their%20child%27s%20birth%20parents%20on%20AdoptConnect.%20Find%20Out%20More%20-%20www.adoptconnect.com">
              <Button
                rounded
                color="#47604e"
                className="ralewayBold btn-share btn-center"
                style={{
                  backgroundColor: '#56786c',
                  color: 'white',
                  width: '200px'
                }}
              >
                Share
              </Button>
            </a>
          </Col>
        </Row>
      </section>
      <div style={{ margin: '60px 0px 0px 0px' }}>
        <hr width="60%" />
        <NewsletterSignup />
      </div>
    </Fragment>
  );
};

HiwBirthParent.propTypes = {};

export default HiwBirthParent;
