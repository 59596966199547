import {
    GET_BLOG_POSTS,
    GET_BLOG_POST,
    BLOG_POST_ERROR
} from '../actions/types'

const initialState = {
    blogposts: [],
    blogpost: null,
    loading: true,
    error: {}
}

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case GET_BLOG_POSTS:
            return {
                ...state,
                blogposts: payload,
                loading: false
            };
        case GET_BLOG_POST:
            return {
                ...state,
                blogpost: payload,
                loading: false
            };
        case BLOG_POST_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            };
        default: 
            return state;
            }
}