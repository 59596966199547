import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

const ProfileTop = ({
    profile: {
        location,
        bio,
        user: { name, avatar }
    }
}) => {
    return (
        <Fragment>
<section className="bg--secondary space--sm">
<div className="container">
<div className="row justify-content-center">
<div className="col-lg-8">
<div className="boxed boxed--lg boxed--border">

<div className="text-block text-center">
    <img alt="avatar" src={avatar} className="image--md" />
    <span className="h5">{name}</span>
</div>

<div className="text-block clearfix text-center">
    <ul className="row row--list">
        <li className="col-md-6">
            <span className="type--fine-print block">Location:</span>
            {location && <span>{location}</span>}
        </li>
        <li className="col-md-6">
            <span className="type--fine-print block">Member Since:</span>
            <span>June 2016</span>
        </li>
    </ul>
    <p>{bio && <span>{bio}</span>}</p>
</div>
</div>
</div>
</div>
</div>
</section>
</Fragment>
    );
};

ProfileTop.propTypes = {
    profile: PropTypes.object.isRequired
};

export default ProfileTop;