import React, { useEffect, useState } from 'react';
import { Progress, Collapse } from 'reactstrap';

const UploadProgress = ({ progress }) => {
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState('');
  const [percentage, setPercentage] = useState('');

  useEffect(() => {
    const zero = !isNaN(progress) && progress !== null && +progress === 0;
    const inactive =
      (!progress && !zero) ||
      isNaN(progress) ||
      +progress < 0 ||
      +progress > 100;
    const percentage = !inactive && +progress | 0;
    const inProgress = +progress > 0 && +progress < 100;
    const done = +progress === 100;
    const status = done
      ? 'Done!'
      : inProgress
      ? 'Uploading'
      : inactive
      ? ''
      : 'Ready to Upload';
    setOpen(!inactive);
    setStatus(status);
    setPercentage(`${percentage}%`);
    if (done) {
      setTimeout(() => {
        setOpen(false);
      }, 1500);
    }
  }, [progress]);

  return (
    <Collapse isOpen={open}>
      <div className="progress-container progress-success m-5 w-100">
        <span className="progress-badge">{status}</span>
        <Progress value={progress} max="100">
          <span className="progress-value">{percentage}</span>
        </Progress>
      </div>
    </Collapse>
  );
};

export default UploadProgress;
