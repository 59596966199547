import {
  formatRelative,
  differenceInDays,
  max,
  min,
  isEqual,
  format,
  isSameDay
} from 'date-fns';

export function formatWithYear(date) {
  const thisYear = format(new Date(), 'yyyy');
  const dateYear = format(date, 'yyyy');
  const yearFormat = thisYear === dateYear ? '' : ', yyyy';
  return `${format(date, `MMM do${yearFormat} 'at' h:mma`)}`;
}

export function relativeDate(date) {
  const now = new Date();
  const formattedDate = new Date(date);
  return Math.abs(differenceInDays(formattedDate, now)) < 6
    ? formatRelative(formattedDate, now)
    : formatWithYear(formattedDate);
}

export function formatDateRange(...args) {
  const startDate = min(args);
  const endDate = max(args);
  if (isEqual(startDate, endDate)) return format(startDate, 'MMM do, h:mma');
  if (isEqual(startDate, new Date(0))) return format(endDate, 'MMM do');
  if (isSameDay(startDate, endDate)) {
    const startMinute = format(startDate, 'mm');
    const startMeridiem = format(startDate, 'a');
    const endMinute = format(endDate, 'mm');
    const endMeridiem = format(endDate, 'a');
    return `${format(
      startDate,
      `MMM do, h${startMinute === '00' ? '' : ':mm'}${
        startMeridiem === endMeridiem ? '' : 'a'
      }`
    )}-${format(endDate, `h${endMinute === '00' ? '' : ':' + endMinute}a`)}`;
  } else {
    const startYear = format(startDate, 'yyyy');
    const endYear = format(endDate, 'yyyy');
    const yearFormat = startYear === endYear ? '' : ' yyyy';
    return `${format(startDate, `MMM do,${yearFormat} h:mma`)} to ${format(
      endDate,
      `MMM do,${yearFormat} h:mma`
    )}`;
  }
}
