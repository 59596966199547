import React from 'react';
import { Redirect } from 'react-router';
import { useConfirmUser } from '../../hooks/useConfirmUser';
import { useParams, useLocation } from 'react-router-dom';

const ConfirmUser = () => {
  const { id } = useParams();
  const { search } = useLocation();
  const { redirect, error, confirm } = useConfirmUser(
    id,
    search.replace('?hash=', '')
  );
  React.useEffect(() => {
    confirm();
  }, [confirm]);
  return (
    <>
      {error && `${JSON.stringify(error)}`}
      {redirect && <Redirect to={redirect} />}
    </>
  );
};

export default ConfirmUser;
