/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button
} from 'reactstrap';
import { logout } from '../../actions/auth';
import Image from '../image';
import Moment from 'react-moment';
import ReactTooltip from 'react-tooltip';
import { isMobile } from 'react-device-detect';
import NotificationItem from './notificationItem';

const MainNavbar = ({
  auth: { isAuthenticated, loading, user },
  notifications: { notifications, unread, notificationsLoading },
  logout
}) => {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          closeNavMenu();
        }
      }

      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  function closeNavMenu() {
    document.documentElement.classList.toggle('nav-open', false);
  }

  function toggleNavMenu() {
    document.documentElement.classList.toggle('nav-open');
  }

  const authLinks = (
    <>
      <ReactTooltip />
      <NavItem className="auth-nav-item">
        <NavLink
          tag={Link}
          data-tip="Timeline"
          data-place="bottom"
          to="/timeline"
          onClick={toggleNavMenu}
        >
          <i
            alt="notifications"
            className="material-icons mi-24"
            style={{ color: 'black' }}
          >
            home
          </i>
        </NavLink>
      </NavItem>
      {user?.accountType !== 'adoptiveParent' ? null : (
        <NavItem className="auth-nav-item">
          <NavLink
            tag={Link}
            data-tip="Reminders"
            data-place="bottom"
            to="/calendar"
            onClick={toggleNavMenu}
          >
            <i
              alt="notifications"
              className="material-icons mi-24"
              style={{ color: 'black' }}
            >
              calendar_today
            </i>
          </NavLink>
        </NavItem>
      )}
      <NavItem className="auth-nav-item">
        <NavLink
          tag={Link}
          data-tip="Documents"
          data-place="bottom"
          to="/documents"
          onClick={toggleNavMenu}
        >
          <i
            alt="notifications"
            className="material-icons mi-24"
            style={{ color: 'black' }}
          >
            article
          </i>
        </NavLink>
      </NavItem>
      <UncontrolledDropdown className="auth-nav-item" nav inNavbar>
        <DropdownToggle data-tip="Notifications" data-place="bottom" nav>
          <i
            alt="notifications"
            className="material-icons mi-24"
            style={unread > 0 ? { color: 'red' } : { color: 'black' }}
          >
            notifications
          </i>
          <span>{unread > 0 ? unread : null}</span>
        </DropdownToggle>
        <DropdownMenu>
          {notifications.slice(0, 5).map((e) => (
            <NotificationItem item={e} key={e.id} />
          ))}
          {notifications.length ? (
            <Link to={`/notifications`}>
              <DropdownItem className="nav-dropdown-link">
                <div className="post-user-info">
                  <center>View All</center>
                </div>
              </DropdownItem>
            </Link>
          ) : (
            <DropdownItem className="nav-dropdown-link">
              <div className="post-user-info">
                <center>No notifications</center>
              </div>
            </DropdownItem>
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav>
          <div>
            <i
              alt="notifications"
              className="material-icons mi-24"
              style={{ verticalAlign: 'middle', color: 'black' }}
            >
              account_circle
            </i>
            <span
              style={{
                verticalAlign: 'middle',
                paddingLeft: '8px',
                paddingTop: '2px'
              }}
            >
              My Account
            </span>
          </div>
        </DropdownToggle>
        <DropdownMenu>
          <Link to="/dashboard" onClick={toggleNavMenu}>
            <DropdownItem className="nav-dropdown-link">Profile</DropdownItem>
          </Link>
          <Link to="/support" onClick={toggleNavMenu}>
            <DropdownItem className="nav-dropdown-link">Support</DropdownItem>
          </Link>
          <Link to="/" onClick={toggleNavMenu && logout}>
            <DropdownItem className="nav-dropdown-link">Logout</DropdownItem>
          </Link>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );

  const mobileAuthLinks = (
    <>
      <ReactTooltip />
      <NavItem>
        <NavLink
          tag={Link}
          data-tip="Timeline"
          data-place="bottom"
          to="/timeline"
          onClick={toggleNavMenu}
        >
          Timeline
        </NavLink>
      </NavItem>
      {user?.accountType !== 'adoptiveParent' ? null : (
        <NavItem>
          <NavLink
            tag={Link}
            data-tip="Reminders"
            data-place="bottom"
            to="/calendar"
            onClick={toggleNavMenu}
          >
            Reminders
          </NavLink>
        </NavItem>
      )}
      <NavItem>
        <NavLink
          tag={Link}
          data-tip="Documents"
          data-place="bottom"
          to="/documents"
          onClick={toggleNavMenu}
        >
          Documents
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          tag={Link}
          data-tip="Documents"
          data-place="bottom"
          to="/notifications"
          onClick={toggleNavMenu}
        >
          Notifications
        </NavLink>
      </NavItem>
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav caret>
          My Account
        </DropdownToggle>
        <DropdownMenu>
          <Link to="/dashboard" onClick={toggleNavMenu}>
            <DropdownItem className="nav-dropdown-link">Profile</DropdownItem>
          </Link>
          <Link to="/support" onClick={toggleNavMenu}>
            <DropdownItem className="nav-dropdown-link">Support</DropdownItem>
          </Link>
          <Link to="/" onClick={toggleNavMenu && logout}>
            <DropdownItem className="nav-dropdown-link">Logout</DropdownItem>
          </Link>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );

  const guestLinks = (
    <>
      <NavItem>
        <NavLink tag={Link} to="/about" onClick={toggleNavMenu}>
          About Us
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink tag={Link} to="/blog" onClick={toggleNavMenu}>
          Blog
        </NavLink>
      </NavItem>
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav caret>
          How it Works
        </DropdownToggle>
        <DropdownMenu>
          {/*           <Link to="/how-it-works" onClick={toggleNavMenu}>
            <DropdownItem className="nav-dropdown-link">
              How it Works
            </DropdownItem>
          </Link> */}
          <Link to="/how-it-works-adoptive-parent" onClick={toggleNavMenu}>
            <DropdownItem className="nav-dropdown-link">
              For Adoptive Parents
            </DropdownItem>
          </Link>
          <Link to="/how-it-works-birth-parent" onClick={toggleNavMenu}>
            <DropdownItem className="nav-dropdown-link">
              For Birth Parents
            </DropdownItem>
          </Link>
          <Link
            to="/how-it-works-adoption-professional"
            onClick={toggleNavMenu}
          >
            <DropdownItem className="nav-dropdown-link last-nav-dropdown-link">
              For Adoption Professionals
            </DropdownItem>
          </Link>
        </DropdownMenu>
      </UncontrolledDropdown>
      <NavItem>
        <NavLink tag={Link} to="/contact" onClick={toggleNavMenu}>
          Contact Us
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          tag={Link}
          to="/login"
          className="btn loginBtn"
          style={{ backgroundColor: '#639C82', color: '#364f44 !important' }}
          onClick={toggleNavMenu}
        >
          User Login
        </NavLink>
      </NavItem>
      <NavItem>
        <a
          href="https://adoptionpro.adoptconnect.com/"
          className="btn loginBtn a-navItem"
          style={{
            backgroundColor: '#639C82',
            color: '#364f44 !important',
            padding: '11px 22px',
            margin: '0',
            fontSize: '16.8px'
          }}
          onClick={toggleNavMenu}
        >
          Professional Login
        </a>
      </NavItem>
    </>
  );

  return (
    <div ref={wrapperRef}>
      <Navbar className="bg-white" expand="lg">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand to="/" tag={Link} id="navbar-brand">
              <Image publicId="ac/logo" width={250} alt="AdoptConnect" />
            </NavbarBrand>
            <NavLink tag={Link} to="/notifications">
              {unread > 0 && isMobile ? (
                <>
                  <Button color="link" to="/notifications">
                    <i
                      alt="notifications"
                      className="material-icons mi-24"
                      style={unread > 0 ? { color: 'red' } : { color: 'black' }}
                    >
                      notifications
                    </i>
                    <span>{unread > 0 ? unread : null}</span>
                  </Button>
                </>
              ) : null}
            </NavLink>
            <button
              onClick={() => {
                document.documentElement.classList.toggle('nav-open');
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              className="navbar-toggler"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse isOpen={collapseOpen} navbar>
            <Nav className="ml-auto" navbar>
              {!loading && isAuthenticated
                ? isMobile
                  ? mobileAuthLinks
                  : authLinks
                : guestLinks}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

MainNavbar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  ref: PropTypes.object,
  notifications: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  notifications: state.notifications
});

export default connect(mapStateToProps, { logout })(MainNavbar);
