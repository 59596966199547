import {
  SUBSCRIBED_TO_NEWSLETTER,
  NEWSLETTER_ERROR,
  CREATE_TICKET,
  DEMO_REQUEST,
  PASSWORD_RESET_REQUEST,
  PASSWORD_SET,
  PASSWORD_SET_CLEANUP
} from '../actions/types';

const initialState = {
  subscribedToNewsletter: false,
  ticketSubmitted: false,
  demoRequest: null,
  passwordResetRequest: false,
  passwordResetStatusCode: '',
  passwordSet: false,
  data: {}
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SUBSCRIBED_TO_NEWSLETTER:
      return {
        ...state,
        subscribedToNewsletter: true,
        data: payload
      };
    case NEWSLETTER_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    case CREATE_TICKET:
      return {
        ...state,
        error: payload,
        ticketSubmitted: true
      };
    case DEMO_REQUEST:
      return {
        ...state,
        demoRequest: payload
      };
    case PASSWORD_RESET_REQUEST:
      return {
        ...state,
        passwordResetRequest: true,
        passwordSet: false,
        data: payload
      };
    case PASSWORD_SET:
      return {
        ...state,
        passwordResetRequest: false,
        passwordSet: true,
        data: {}
      };
    case PASSWORD_SET_CLEANUP:
      return {
        ...state,
        passwordResetRequest: false,
        passwordSet: false,
        data: {}
      };
    default:
      return state;
  }
}
