import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Form,
  Input,
  FormGroup,
  Label,
  ModalFooter,
  Alert,
  Spinner
} from 'reactstrap';
import { updateUserInvite, getMyInvites } from '../../actions/auth';
import * as EmailValidator from 'email-validator';

const Invitations = ({
  updateUserInvite,
  invitations: { invitations, loading }
}) => {
  const [modalStatus, setModalStatus] = useState(false);
  const [formData, setFormData] = useState({});
  const [alert, setAlert] = useState('');

  function toggleModal() {
    setAlert('');
    setModalStatus(!modalStatus);
  }

  function openModal(e) {
    setFormData(invitations[e]);
    setAlert('');
    setModalStatus(true);
  }

  function validateEmails() {
    if (!EmailValidator.validate(formData.bpEmail)) {
      setAlert(`${formData.bpEmail} is not a valid email address`);
    } else {
      updateUserInvite(formData);
      setModalStatus(false);
    }
  }

  function sendInvitation(e) {
    e.preventDefault();
    validateEmails();
  }

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  if (loading) {
    return <Spinner />;
  } else {
    return (
      <section>
        <Modal isOpen={modalStatus} toggle={toggleModal}>
          <ModalHeader>
            <h4 style={{ marginTop: '0px' }}>Resend Invitation</h4>
          </ModalHeader>
          <ModalBody>
            {alert === '' ? null : <Alert color="danger">{alert}</Alert>}
            <Form>
              <FormGroup>
                <Label>First Name</Label>
                <Input
                  type="text"
                  placeholder="Name"
                  name="bp_first_name"
                  value={formData.bp_first_name}
                  onChange={onChange}
                ></Input>
              </FormGroup>
              <FormGroup>
                <Label>Last Name</Label>
                <Input
                  type="text"
                  placeholder="Name"
                  name="bp_last_name"
                  value={formData.bp_last_name}
                  onChange={onChange}
                ></Input>
              </FormGroup>
              <FormGroup>
                <Label>Email</Label>
                <Input
                  type="email"
                  placeholder="test@email.com"
                  name="bpEmail"
                  value={formData.bpEmail}
                  onChange={onChange}
                ></Input>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button className="btn-ac-green" onClick={toggleModal}>
              Cancel
            </Button>
            <Button className="btn-ac-green" onClick={sendInvitation}>
              Save and Resend
            </Button>
          </ModalFooter>
        </Modal>
        <h4>Invitations</h4>
        <hr />
        {invitations !== null && invitations.length <= 1 ? (
          <div>
            {invitations?.map((invitation, e) => (
              <div key={invitation.id}>
                <p>
                  Name: {invitation.bp_first_name} {invitation.bp_last_name}
                </p>
                <p>Email: {invitation.bpEmail}</p>
                <p>
                  Status:{' '}
                  {invitation.completed ? 'Accepted' : 'Awaiting Acceptance'}
                </p>
                {invitation.completed ? null : (
                  <Button
                    className="btn-ac-green"
                    onClick={(a) => openModal(e)}
                  >
                    Resend Invitation
                  </Button>
                )}
              </div>
            ))}
          </div>
        ) : (
          'No Invitations Found'
        )}
        {JSON.stringify(invitations) === '[]' ? 'No Invitations Found' : null}
      </section>
    );
  }
};

Invitations.propTypes = {
  user: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  invitations: PropTypes.object.isRequired,
  updateUserInvite: PropTypes.func.isRequired,
  getMyInvites: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user,
  auth: state.auth,
  invitations: state.invitations
});

export default connect(mapStateToProps, { updateUserInvite, getMyInvites })(
  withRouter(Invitations)
);
