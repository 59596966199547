import axios from 'axios';
import { setAlert } from './alert';

import {
  GET_PROFILE,
  GET_PROFILES,
  PROFILE_ERROR,
  UPDATE_PROFILE,
  CLEAR_PROFILE,
  ACCOUNT_DELETED,
  GET_CONNECTIONS,
  USER_LOADED
} from './types';
import { API_URL } from '../config/default.json';

// Get current users profile
export const getCurrentProfile = () => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}/profile/me`);

    dispatch({
      type: GET_PROFILE,
      payload: res.data
    });
  } catch (err) {
    console.log('ERROR:', err);
    dispatch({
      type: PROFILE_ERROR,
      payload: {
        msg: err.message,
        status: err.name
      }
    });
  }
};

// Get All Profiles
export const getProfiles = () => async (dispatch) => {
  dispatch({ type: CLEAR_PROFILE });
  try {
    const res = await axios.get(`${API_URL}/profile`);

    dispatch({
      type: GET_PROFILES,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: {
        msg: err.message,
        status: err.name
      }
    });
  }
};

// Get profile by ID
export const getProfileByID = (userId) => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}/profile/user/${userId}`);

    dispatch({
      type: GET_PROFILE,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: {
        msg: err.message,
        status: err.name
      }
    });
  }
};

// Create or update profile
export const createProfile = (formData, history, edit = false) => async (
  dispatch
) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const res = await axios.post(`${API_URL}/profile`, formData, config);
    dispatch({
      type: GET_PROFILE,
      payload: res.data
    });
    dispatch({
      type: USER_LOADED,
      payload: res.data.user
    });
    if (edit) {
      dispatch(setAlert('Profile Updated', 'success'));
    }
    if (!edit) {
      history.push('/dashboard');
    }
  } catch (err) {
    console.log('err', err);
    dispatch({
      type: PROFILE_ERROR,
      payload: {
        msg: err.message,
        status: err.name
      }
    });
  }
};

// Delete Account and Profile

export const deleteAccount = (id) => async (dispatch) => {
  if (window.confirm('Are you sure? This can NOT be undone!')) {
    try {
      await axios.delete(`${API_URL}/profile/`);

      dispatch({ type: CLEAR_PROFILE });
      dispatch({ type: ACCOUNT_DELETED });

      dispatch(setAlert('Your Account has been permanently deleted'));
    } catch (err) {
      dispatch({
        type: PROFILE_ERROR,
        payload: {
          msg: err.message,
          status: err.name
        }
      });
    }
  }
};

// Add Connection
export const addConnection = (formData, history) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const res = await axios.put(
      `${API_URL}/profile/connections`,
      formData,
      config
    );

    dispatch({
      type: UPDATE_PROFILE,
      payload: res.data
    });

    dispatch(setAlert('Connection Added', 'success'));
    history.push('/dashboard');
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: PROFILE_ERROR,
      payload: {
        msg: err.message,
        status: err.name
      }
    });
  }
};

// Get Connections
export const getConnections = () => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}/user/connection`);
    dispatch({
      type: GET_CONNECTIONS,
      payload: res.data
    });
    return res.data;
  } catch (err) {
    console.error(err);
  }
};

// Update Profile Image
export const updateProfileImage = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const res = await axios.put(
      `${API_URL}/user/updateuser/`,
      formData,
      config
    );
    dispatch({
      type: USER_LOADED,
      payload: res.data
    });
    dispatch(setAlert('Photo Saved', 'success'));
    return res.data;
  } catch (err) {
    console.error(err);
  }
};
