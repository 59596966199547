import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  ACCOUNT_DELETED,
  EMAIL_CONF_ERROR,
  EMAIL_CONFIRMED,
  PAYMENT_CONFIRMED,
  GET_CONNECTIONS
} from '../actions/types';

const initialState = {
  isAuthenticated: undefined,
  loading: true,
  _id: undefined,
  name: undefined,
  accountType: undefined,
  role: undefined,
  email: undefined,
  profileImage: undefined,
  img: undefined,
  avatar: undefined,
  date: undefined,
  confirmed: undefined,
  accountActive: undefined,
  membershipType: undefined,
  membershipActive: undefined,
  confirmationtoken: undefined,
  invitationID: undefined,
  parent2_first_name: undefined,
  parent2_last_name: undefined,
  parent2_email: undefined,
  connection: [],
  connectionOptions: [],
  settings: {},
  bpinvite: undefined,
  subscriptions: {}
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case EMAIL_CONFIRMED:
      return {
        ...state,
        isAuthenticated: true,
        confirmed: true
      };
    case PAYMENT_CONFIRMED:
      return {
        isAuthenticated: true,
        subscriptions: { subscriptionactive: true }
      };
    case REGISTER_SUCCESS:
    case LOGIN_SUCCESS:
      return {
        isAuthenticated: true,
        loading: true
      };
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        ...payload
      };
    case GET_CONNECTIONS:
      return {
        ...state,
        loading: false,
        connection: payload
      };
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case REGISTER_FAIL:
    case LOGOUT:
    case ACCOUNT_DELETED:
    case EMAIL_CONF_ERROR:
      return {
        isAuthenticated: false,
        loading: false
      };
    default:
      return state;
  }
}
