import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addComment } from '../../actions/post';
import { Button } from 'reactstrap';
import TextareaAutosize from 'react-textarea-autosize';

const CommentForm = ({ postId, addComment }) => {
  const [text, setText] = useState('');

  return (
    <div className="post-form" style={{ maxHeight: '500px' }}>
      <div className="bg-primary p"></div>
      <form
        className="form my-1"
        onSubmit={(e) => {
          e.preventDefault();
          addComment(postId, { text });
          setText('');
        }}
      >
        <div className="row">
          <TextareaAutosize
            type="textarea"
            name="text"
            className="comment-input"
            placeholder="Add a comment..."
            value={text}
            onChange={(e) => setText(e.target.value)}
            rows="1"
            required
          ></TextareaAutosize>
          {text === '' ? (
            <Button
              type="submit"
              className="btn btn-dark my-1"
              color="default"
              value="Submit"
              disabled
            >
              Submit
            </Button>
          ) : (
            <Button
              type="submit"
              className="btn btn-dark my-1 btn-ac-green"
              value="Submit"
            >
              Submit
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};

CommentForm.propTypes = {
  addComment: PropTypes.func.isRequired
};

export default connect(null, { addComment })(CommentForm);
