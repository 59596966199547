import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createProfile } from '../../actions/profile';
import { CardHeader, Card, CardBody, Form, Button, Modal } from 'reactstrap';
import UploadProfileImage from '../upload-image/UploadProfileImage';

const CreateProfile = ({ createProfile, history }) => {
  const formData = {
    bio: ''
  };

  const onSubmit = (e) => {
    e.preventDefault();
    createProfile(formData, history);
  };

  return (
    <Fragment>
      <div className="fullPage">
        <div className="form-container">
          <Card className="form-card">
            <CardHeader style={{ padding: '20px 20px 0px 20px' }}>
              <h1 className="panel-header" style={{ paddingBottom: '0' }}>
                Add Profile Photo
              </h1>
              <p className="ac-text text-align-center">
                Profile photos aren’t required, but they do add a nice touch.
              </p>
            </CardHeader>
            <CardBody style={{ padding: '0px 20px 20px 20px' }}>
              <UploadProfileImage />
              <Form className="form" onSubmit={(e) => onSubmit(e)}>
                <div className="right">
                  <Button
                    className="btn btn-ac-green"
                    type="submit"
                    style={{ marginBottom: '2em' }}
                  >
                    Continue{' '}
                    <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </div>
    </Fragment>
  );
};

CreateProfile.propTypes = {
  createProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile
});

export default connect(mapStateToProps, { createProfile })(
  withRouter(CreateProfile)
);
