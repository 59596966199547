import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';

export const LargeAlert = ({ alerts }) => {
  return (
    <div>
      {alerts.map((alert) => (
        <div key={alert.id}>
          {alert.alertType === 'success' ? (
            <Alert color="success">
              <center>{alert.msg}</center>
            </Alert>
          ) : null}
          {alert.alertType === 'danger' ? (
            <Alert color="alert-custom">
              <center>{alert.msg}</center>
            </Alert>
          ) : null}
        </div>
      ))}
    </div>
  );
};

LargeAlert.propTypes = {
  alerts: PropTypes.array.isRequired
};

const mapStateToProps = (state) => ({
  alerts: state.alert
});

export default connect(mapStateToProps)(LargeAlert);
