import { combineReducers } from 'redux';
import alert from './alert';
import auth from './auth';
import media from './media';
import profile from './profile';
import post from './post';
import user from './user';
import events from './event';
import marketplace from './marketplace';
import blog from './blog';
import connections from './connections';
import invitations from './invitations';
import session from './session';
import documents from './documents';
import notifications from './notifications';

export default combineReducers({
  alert,
  auth,
  media,
  profile,
  post,
  marketplace,
  blog,
  events,
  connections,
  user,
  invitations,
  session,
  documents,
  notifications
});
