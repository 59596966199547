import axios from 'axios';
import {
  CREATE_EVENT,
  GET_EVENTS,
  GET_EVENT,
  GET_USER_EVENTS,
  GET_CONNECTION_EVENTS,
  DELETE_EVENT
} from './types';
import { API_URL } from '../config/default.json';

export const manageEventModal = ({ modal, action }) => async (dispatch) => {
  console.log(modal);
  if (modal === 'eventModal') {
    dispatch({
      type: 'OPEN_EVENT_MODAL',
      payload: null
    });
  }
};

export const getEvents = () => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}/event`);
    dispatch({
      type: GET_EVENTS,
      payload: res.data
    });
  } catch (err) {
    console.log('ERROR', err);
  }
};

export const getUserEvents = () => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}/event?type=user`);
    dispatch({
      type: GET_USER_EVENTS,
      payload: res.data
    });
  } catch (err) {
    console.log('ERROR', err);
  }
};

export const getConnectionEvents = () => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}/event?type=connection`);
    dispatch({
      type: GET_CONNECTION_EVENTS,
      payload: res.data
    });
  } catch (err) {
    console.log('ERROR', err);
  }
};

export const getEvent = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}/event/${id}`);
    dispatch({
      type: GET_EVENT,
      payload: res.data
    });
  } catch (err) {
    console.log('ERROR', err);
  }
};

export const createEvent = (event) => async (dispatch) => {
  try {
    console.log(event);
    const res = await axios.post(`${API_URL}/event`, event);
    console.log('RESPONSE:', res);
    dispatch({
      type: CREATE_EVENT,
      payload: res.data
    });
  } catch (err) {
    console.log('ERROR', err);
  }
};

/**
 * Share event with users
 * @param {Number} eventId
 * @param {userId[]} shared
 */
export const shareEvent = (eventId, shared) => async (dispatch) => {
  try {
    console.log(eventId, shared);
    const res = await axios.put(`${API_URL}/event/${eventId}`, { shared });
    console.log('RESPONSE:', res);
    dispatch({
      type: GET_USER_EVENTS,
      payload: res.data
    });
  } catch (err) {
    console.log('ERROR', err);
  }
};

export const deleteEvent = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(`${API_URL}/event/${id}`);
    dispatch({
      type: DELETE_EVENT,
      payload: res.data
    });
  } catch (err) {
    console.log('ERROR', err);
  }
};
