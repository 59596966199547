import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getItems } from '../../actions/marketplace';
import Spinner from '../layout/Spinner';
import MarketplaceItem from './Item'

const Marketplace = ({ getItems, req, marketplace: { items, loading } }) => {
    useEffect(() => {
      getItems();
    }, [getItems])
  return loading ? <Spinner /> : (<Fragment>
    <section className="container sec-pad-adjust">
    <h1 className="large text-primary">WELCOME TO THE ADOPTCONNECT MARKETPLACE</h1>
    <p className="lead">
    <i className="fas fa-user"></i>A thoughtfully curated marketplace of high-quality adoption-inspired goods. 25% of all net proceeds will be donated to support the work of important adoptive parent and birth mother support organizations.
    </p>
    <div className="row">
    {items.map(item => (<MarketplaceItem key={item.id} item={item} />))}
    </div>
    </section>
  </Fragment>)
}

Marketplace.propTypes = {
    getItems: PropTypes.func.isRequired,
    marketplace: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    marketplace: state.marketplace
});

export default connect(mapStateToProps, { getItems })(Marketplace)
