import {
  INVITATION_RETRIEVED,
  INVITATION_ERROR,
  INVITATIONS_RETRIEVED,
  INVITATION_UPDATED
} from '../actions/types';

const initialState = {
  loading: true,
  invitation: null
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case INVITATION_RETRIEVED:
      return {
        ...state,
        loading: false,
        invitation: payload
      };
    case INVITATIONS_RETRIEVED:
      return {
        ...state,
        loading: false,
        invitations: payload
      };
    case INVITATION_UPDATED:
      return {
        ...state,
        loading: false,
        invitations: [payload]
      };
    case INVITATION_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    default:
      return state;
  }
}
