import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { deleteComment } from '../../actions/post';
import Moment from 'react-moment';
// import ReactTooltip from 'react-tooltip';

const CommentItem = ({
  postId,
  comment: { _id, text, user, createdAt },
  auth,
  deleteComment
}) => (
  <div>
    <div className="card__avatar">
      <img className="round-img comment-img" src={user.profileImage} alt="" />{' '}
      <span>
        <strong>{user.name}</strong>
      </span>
    </div>
    <p>{text}</p>

    <p className="text-muted post-date">
      <Moment fromNow>{createdAt}</Moment>
      {!auth.loading && user._id === auth.user._id && (
        <>
          {'  '}|{'  '}
          <Link to="#">
            <span
              className="commentActions"
              onClick={(e) => deleteComment(postId, _id)}
            >
              Delete comment
            </span>
          </Link>
        </>
      )}
    </p>
    <hr width="80%" />
    {/* <ReactTooltip /> */}
  </div>
);

CommentItem.propTypes = {
  postId: PropTypes.string.isRequired,
  comment: PropTypes.object.isRequired,
  deleteComment: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps, { deleteComment })(CommentItem);
