import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import { isMobile } from 'react-device-detect';

const AutoplaySlider = withAutoplay(AwesomeSlider);

const HomeSlider = () => {
  if (isMobile) {
    return (
      <>
        <div className="homeSliderMobile">
          <AutoplaySlider
            play={true}
            cancelOnInteraction={true} // should stop playing on user interaction
            interval={6000}
            bullets={false}
          >
            <div data-src="https://res.cloudinary.com/puget-sound-networks/image/upload/f_auto/c_scale,w_1155/v1596653010/ac/AC_Testimonial_Jaclyn_Mobile_x8byix.png" />
            <div data-src="https://res.cloudinary.com/puget-sound-networks/image/upload/f_auto/c_scale,w_1155/v1596653025/ac/AC_Testimonial_Jessica_Mobile_wjusuq.png" />
            <div data-src="https://res.cloudinary.com/puget-sound-networks/image/upload/f_auto/c_scale,w_1155/v1596653022/ac/AC_Testimonial_Katie_Mobile_ytxu94.png" />
            <div data-src="https://res.cloudinary.com/puget-sound-networks/image/upload/f_auto/c_scale,w_1155/v1596653021/ac/AC_Testimonial_Jen___Robert_Mobile_l7veni.png" />
          </AutoplaySlider>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="homeSlider">
          <AutoplaySlider
            play={true}
            cancelOnInteraction={true} // should stop playing on user interaction
            interval={6000}
            bullets={false}
          >
            <div data-src="https://res.cloudinary.com/puget-sound-networks/image/upload/f_auto/c_scale,q_59,r_0,w_1897/v1594939409/ac/AC_Testimonial_Jen___Robert_bfkyyd.png" />
            <div data-src="https://res.cloudinary.com/puget-sound-networks/image/upload/f_auto/c_scale,q_59,r_0,w_1897/v1594939408/ac/AC_Testimonial_Jessica_f9ctpi.png" />
            <div data-src="https://res.cloudinary.com/puget-sound-networks/image/upload/f_auto/c_scale,q_59,r_0,w_1897/v1594939407/ac/AC_Testimonial_Katie_h9gomi.png" />
            <div data-src="https://res.cloudinary.com/puget-sound-networks/image/upload/f_auto/c_scale,q_59,r_0,w_1897/v1594939405/ac/AC_Testimonial_Jaclyn_wghwi3.png" />
          </AutoplaySlider>
        </div>
      </>
    );
  }
};

export default HomeSlider;
