import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Form,
  Label,
  Button,
  Spinner,
  ModalFooter,
  FormGroup
} from 'reactstrap';
import { uploadMedia } from '../../actions/media';
import { connect } from 'react-redux';
import { addDocument } from '../../actions/document';

const DocumentUploader = ({ uploadMedia, addDocument, media }) => {
  const [modalStatus, setModalStatus] = useState(false);
  const [fileName, setFileName] = useState('');
  const [files, setFiles] = useState([]);
  const [fileType, setFileType] = useState('Written Update');
  const [processing, setProcessing] = useState(false);

  function toggleModal() {
    setModalStatus(!modalStatus);
    setFiles([]);
  }

  const handleFileNameChange = ({ target }) => setFileName(target.value);
  const handleFileTypeChange = ({ target }) => setFileType(target.value);

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      setProcessing(true);
      const fileUrl = await uploadMedia(files, 'pdf');
      const body = { url: fileUrl, fileName: fileName, type: fileType };
      console.log(body);
      await addDocument(body);
      setModalStatus(false);
      setProcessing(false);
      setFiles([]);
    } catch (error) {
      console.log(error);
    }
  };

  function onChange(e) {
    setFiles(e.target.files[0]);
    setFileName(e.target.files[0].name);
  }

  return (
    <div>
      <Button onClick={toggleModal} className="btn-ac-green">
        Upload Document
      </Button>
      <div>
        <Modal isOpen={modalStatus} toggle={toggleModal}>
          <ModalHeader>
            <h3
              className="panel-header"
              style={{
                fontSize: '1.3em',
                paddingBottom: '0',
                marginBottom: '0'
              }}
            >
              Add Document
            </h3>
          </ModalHeader>
          {!processing ? (
            <>
              <Form onSubmit={onSubmit}>
                <ModalBody>
                  <Input
                    type="file"
                    accept="application/pdf"
                    onChange={onChange}
                  />
                  <br />
                  {files.name === undefined ? null : (
                    <>
                      <div>
                        <FormGroup>
                          <Label>Document Name</Label>
                          <Input
                            type="text"
                            placeholder="this is placeholder text"
                            name="name"
                            value={fileName}
                            onChange={handleFileNameChange}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="exampleSelect">Document Type</Label>
                          <Input
                            type="select"
                            name="fileType"
                            id="exampleSelect"
                            onChange={handleFileTypeChange}
                            value={fileType}
                          >
                            <option>Written Update</option>
                            <option>Other</option>
                          </Input>
                        </FormGroup>
                      </div>
                      <br />
                    </>
                  )}
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="primary"
                    className="btn-ac-green"
                    onClick={toggleModal}
                  >
                    Cancel
                  </Button>
                  {fileName === '' ? (
                    <Button
                      type="submit"
                      color="primary"
                      className="btn-ac-green"
                      disabled
                    >
                      Save
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      color="primary"
                      className="btn-ac-green"
                    >
                      Save
                    </Button>
                  )}
                </ModalFooter>
              </Form>
            </>
          ) : (
            <div>
              <center>
                <Spinner />
                <h4>Processing</h4>
              </center>
            </div>
          )}
        </Modal>
        {modalStatus}
      </div>
    </div>
  );
};

DocumentUploader.propTypes = {
  uploadMedia: PropTypes.func.isRequired,
  addDocument: PropTypes.func.isRequired,
  media: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user,
  documents: state.documents,
  media: state.media
});

export default connect(mapStateToProps, { uploadMedia, addDocument })(
  DocumentUploader
);
