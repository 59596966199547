import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getBlogPosts } from '../../actions/blog';
import { Helmet } from 'react-helmet';
import { Card, CardBody, CardHeader } from 'reactstrap';

const Blog = ({ getBlogPosts, blog: { blogposts, loading } }) => {
  useEffect(() => {
    getBlogPosts();
  }, [getBlogPosts]);

  var mainSectionStyle = {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url('https://res.cloudinary.com/puget-sound-networks/image/upload/f_auto/v1607150682/AC-Web-Assets/bike_saxawt.png')`
  };

  return (
    <Fragment>
      <Helmet>
        <title>AdoptConnect - Blog</title>
        <meta name="description" content="Adopt Connect Blog" />
      </Helmet>
      <div style={mainSectionStyle} className="banner">
        <h1 className="banner">
          <img
            className="banneroverlay"
            src={
              'https://res.cloudinary.com/puget-sound-networks/image/upload/f_auto/v1607150680/AC-Web-Assets/BlogBanner_kfy60q.png'
            }
            width="20%"
            alt="Blog Banner Overlay"
          ></img>
        </h1>
      </div>
      <div className="album py-5">
        <div className="container">
          <div className="row">
            {blogposts.map((post) => {
              return (
                <div key={post._id}>
                  <div className="col-md-4">
                    <div className="cta">
                      <Link to={`/blog/post/${post.slug}`}>
                        <Card
                          style={{
                            width: '300px',
                            height: '300px',
                            backgroundColor: '#e75833'
                          }}
                        >
                          <CardHeader>
                            <img
                              src={`https://blog.adoptconnect.com${post.square_image.url}`}
                              alt="blog post cover"
                            />
                          </CardHeader>
                          <CardBody
                            style={{ color: 'black', padding: '0.25em' }}
                          >
                            <h4 style={{ color: 'black', fontSize: '1.8em' }}>
                              {post.Title}
                            </h4>
                            <div style={{ height: '2px' }} />
                            <h3
                              style={{
                                color: 'black',
                                fontSize: '1.3em',
                                fontFamily: 'Raleway',
                                fontWeight: '500',
                                marginBottom: '20px'
                              }}
                            >
                              {post.Subtitle}
                            </h3>
                            <h3
                              style={{
                                color: 'orange',
                                fontSize: '1.3em',
                                fontFamily: 'Raleway',
                                fontWeight: '500',
                                marginTop: '0'
                              }}
                            >
                              Read More ->
                            </h3>
                          </CardBody>
                        </Card>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

Blog.propTypes = {
  getBlogPosts: PropTypes.func.isRequired,
  blog: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  blog: state.blog
});

export default connect(mapStateToProps, { getBlogPosts })(Blog);
