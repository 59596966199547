import axios from 'axios';
import { setAlert } from './alert';
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_PROFILE,
  EMAIL_CONFIRMED,
  EMAIL_CONF_ERROR,
  PAYMENT_CONFIRMED,
  PAYMENT_ERROR,
  INVITATION_RETRIEVED,
  INVITATIONS_RETRIEVED,
  INVITATION_UPDATED,
  INVITATION_ERROR,
  PASSWORD_RESET_REQUEST,
  PASSWORD_SET,
  PASSWORD_SET_CLEANUP,
  SET_NO_NOTIFICATIONS
} from './types';
import setAuthToken from '../utils/setAuthToken';
import { getCurrentProfile } from './profile';

import { API_URL } from '../config/default.json';
import { getNotifications } from './notifications';

// Load User
export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const res = await axios.get(`${API_URL}/auth`);

    dispatch({
      type: USER_LOADED,
      payload: res.data
    });

    dispatch(getCurrentProfile());
    dispatch(getNotifications());
  } catch (err) {
    dispatch({
      type: AUTH_ERROR
    });
  }
};

// Get Invites
export const getMyInvites = (id) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const res = await axios.get(`${API_URL}/invitation/myinvites/`);
    dispatch({
      type: INVITATIONS_RETRIEVED,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: INVITATION_ERROR
    });
  }
};

// Confirm Email
export const confirmEmail = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}/user/confirm/${id}`);

    dispatch({
      type: EMAIL_CONFIRMED,
      payload: res.data
    });

    dispatch(setAlert('Email Confirmed', 'success'));
  } catch (err) {
    dispatch({
      type: EMAIL_CONF_ERROR
    });
  }
};

// Confirm Payment
export const confirmPayment = ({ id, last4, created }) => async (dispatch) => {
  try {
    const res = await axios.post(`${API_URL}/user/subscription/payment`, {
      token: id,
      last4,
      created
    });

    dispatch({
      type: PAYMENT_CONFIRMED,
      payload: res.data
    });

    dispatch(setAlert('Payment Completed', 'success'));
  } catch (err) {
    dispatch({
      type: PAYMENT_ERROR
    });
  }
};

// Register User
export const register = ({
  parent1_first_name,
  parent1_last_name,
  parent2_first_name,
  parent2_last_name,
  parent2_email,
  email,
  password,
  bp_first_name,
  bp_last_name,
  bpemail,
  bpmessage,
  subscriptiontype,
  subscriptionactive,
  subscriptionCreation,
  privatemessages,
  twowaycommunication,
  accountType,
  invitationID
}) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({
    parent1_first_name,
    parent1_last_name,
    parent2_first_name,
    parent2_last_name,
    parent2_email,
    email,
    password,
    bp_first_name,
    bp_last_name,
    bpemail,
    bpmessage,
    subscriptiontype,
    subscriptionactive,
    subscriptionCreation,
    privatemessages,
    twowaycommunication,
    accountType,
    invitationID
  });

  try {
    const res = await axios.post(`${API_URL}/user`, body, config);

    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data
    });

    dispatch(loadUser());
    dispatch({
      type: SET_NO_NOTIFICATIONS
    });
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: REGISTER_FAIL
    });
  }
};

// Register User
export const registerWithInvitation = ({
  parent1_first_name,
  parent1_last_name,
  parent2_first_name,
  parent2_last_name,
  parent2_email,
  email,
  password,
  bp_first_name,
  bp_last_name,
  bpemail,
  bpmessage,
  subscriptiontype,
  subscriptionactive,
  subscriptionCreation,
  privatemessages,
  twowaycommunication,
  accountType,
  invitationID
}) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({
    parent1_first_name,
    parent1_last_name,
    parent2_first_name,
    parent2_last_name,
    parent2_email,
    email,
    password,
    bp_first_name,
    bp_last_name,
    bpemail,
    bpmessage,
    subscriptiontype,
    subscriptionactive,
    subscriptionCreation,
    privatemessages,
    twowaycommunication,
    accountType,
    invitationID
  });

  try {
    const res = await axios.post(
      `${API_URL}/user/register/invite
     `,
      body,
      config
    );

    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data
    });

    dispatch(loadUser());
    dispatch({
      type: SET_NO_NOTIFICATIONS
    });
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: REGISTER_FAIL
    });
  }
};

// Login User
export const login = (email, password) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({ email, password });

  try {
    const res = await axios.post(`${API_URL}/auth`, body, config);
    console.log(res);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data
    });

    dispatch(getNotifications());
    dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: LOGIN_FAIL
    });
  }
};

// Logout / Clear Profile
export const logout = () => (dispatch) => {
  dispatch({ type: CLEAR_PROFILE });
  dispatch({ type: LOGOUT });
};

export const updatePassword = async (currentPassword, newPassword) => {
  const res = await axios.put(`${API_URL}/user/updatepassword`, {
    currentPassword,
    newPassword
  });

  if (res.status === 200) {
    return res.data;
  } else {
    console.log(res);
  }
};

export const getInvitation = async (id, hash) => {
  console.log(hash);
  console.log(id);
  const userHash = hash.substring(1);
  const res = await axios.get(
    `${API_URL}/user/invitation/${id}?hash=${userHash}`
  );
  return res.data;
};

// Get Invitation Data
export const getInvitationData = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}/invitation/byID/${id}`);
    dispatch({
      type: INVITATION_RETRIEVED,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: INVITATION_ERROR
    });
  }
};

// Request Password Reset Token
export const requestPasswordReset = (email) => async (dispatch) => {
  try {
    const body = {
      email: email
    };
    const res = await axios.post(`${API_URL}/user/requestpasswordreset`, body);

    const resData = {
      status: res.status,
      email: email
    };

    dispatch({
      type: PASSWORD_RESET_REQUEST,
      payload: resData
    });

    if (res.status === 200) {
      dispatch(setAlert('Password Reset Requested', 'success'));
    }
    if (res.status === 204) {
      dispatch(setAlert('Account Not Found', 'error'));
      dispatch({ type: PASSWORD_SET_CLEANUP });
    }
  } catch (err) {
    console.log(err);
  }
};

// Request Password Reset Token
export const setNewPassword = (input) => async (dispatch) => {
  try {
    const body = {
      newPassword: input.newPassword
    };
    const token = input.id;
    console.log(token);
    const res = await axios.put(
      `${API_URL}/user/updatepassword/${token}`,
      body
    );

    dispatch({
      type: PASSWORD_SET,
      payload: res.data
    });

    dispatch(
      setAlert('Password Set! Please login with your new password', 'success')
    );
  } catch (err) {
    console.log(err);
  }
};

// Request Password Reset Token
export const passwordResetStateCleanup = () => async (dispatch) => {
  try {
    dispatch({
      type: PASSWORD_SET_CLEANUP
    });
  } catch (err) {
    console.log(err);
  }
};

export const updateTwoWayCommunication = (incoming) => async (dispatch) => {
  const body = {
    twowaycommunication: incoming
  };

  const status = incoming === true ? 'on' : 'off';

  try {
    const res = await axios.put(
      `${API_URL}/user/updatetwowaypreferences`,
      body
    );

    dispatch({
      type: USER_LOADED,
      payload: res.data
    });

    dispatch(setAlert(`Two-Way Communication is now ${status}`, 'success'));
    return res.data._id;
  } catch (err) {
    console.log(err);
  }
};

export const updateUserInvite = (incoming) => async (dispatch) => {
  try {
    const res = await axios.put(
      `${API_URL}/invitation/update/userinvite`,
      incoming
    );

    dispatch({
      type: INVITATION_UPDATED,
      payload: res.data
    });

    dispatch(setAlert(`Invitation has been resent`, 'success'));
    return res.data._id;
  } catch (err) {
    console.log(err);
  }
};
