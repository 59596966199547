import React, { Fragment, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addConnection } from '../../actions/profile';

const AddConnection = ({ addConnection, history }) => {
    const [formData, setFormData] = useState({
        firstName: '',
        connectID: '',
    });

    const {
        firstName,
        connectID
    } = formData

    const onChange = e => setFormData({
        ...formData,
        [e.target.name]: e.target.value
    });

    return (
        <Fragment>
            <h1 className="large text-primary">
                Add An Experience
      </h1>
            <p className="lead">
                <i className="fas fa-code-branch"></i> Add any developer/programming
                positions that you have had in the past
      </p>
            <small>* = required field</small>
            <form className="form" onSubmit={e => {
                e.preventDefault();
                addConnection(formData, history);
            }}>
                <div className="form-group">
                    <input type="text" placeholder="* First Name" name="firstName" onChange={e => onChange(e)} value={firstName} required />
                </div>
                <div className="form-group">
                    <input type="text" placeholder="* ConnectID" name="connectID" onChange={e => onChange(e)} value={connectID} required />
                </div>
                <input type="submit" className="btn btn-primary my-1" />
                <Link to="btn btn-light my-1" href="/dashboard">Go Back</Link>
            </form>
        </Fragment>
    )
}

AddConnection.propTypes = {
    addConnection: PropTypes.func.isRequired
}

export default connect(null, { addConnection })(withRouter(AddConnection));
