// Presents users profile, connections, posts and user data.

import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { ProfileImage } from './../image';
import { getCurrentProfile } from '../../actions/profile';
import { getUserPosts } from '../../actions/post';
import Spinner from '../layout/Spinner';
import PostViewer from './PostViewer';
import Toaster from '../../functions/Toaster';
import { Card, CardBody, CardHeader, Button, Col } from 'reactstrap';
import { Redirect } from 'react-router';

const Dashboard = ({
  getCurrentProfile,
  getUserPosts,
  auth: { user },
  profile: { profile, loading },
  post: { posts }
}) => {
  const [redirect, setRedirect] = useState(null);
  useEffect(() => {
    getCurrentProfile();
    getUserPosts();
  }, [getCurrentProfile, getUserPosts]);
  useEffect(() => {
    console.log(user);
  }, [user]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }
  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      {profile !== null ? (
        <Fragment>
          <Toaster />
          <Helmet>
            <title>AdoptConnect - Dashboard</title>
            <meta name="description" content="Adopt Connect Blog" />
          </Helmet>
          <div className="container centered">
            <div className="row">
              <Card className="col-md-12 no-background">
                <center>
                  <CardHeader>
                    <Col md="12">
                      <div className="userProfilePic">
                        <ProfileImage
                          publicId={user?.profileImage}
                          alt={`${user?.name} profile image`}
                        ></ProfileImage>
                      </div>
                      {user?.parent2_first_name === '' ? (
                        <h4>
                          {user?.parent1_first_name +
                            ' ' +
                            user?.parent1_last_name}
                        </h4>
                      ) : (
                        <h4>
                          {user?.parent1_first_name +
                            ' ' +
                            user?.parent1_last_name +
                            ' and ' +
                            user?.parent2_first_name +
                            ' ' +
                            user?.parent2_last_name}
                        </h4>
                      )}
                      <Button
                        onClick={(input) => setRedirect('/edit-profile')}
                        className="btn-ac-green"
                      >
                        View Profile
                      </Button>{' '}
                    </Col>
                  </CardHeader>
                </center>
              </Card>
              <hr width="100%" className="dashboard-separator"></hr>
              <Card className="col-md-12 no-background">
                <CardHeader>
                  <h3>My Posts</h3>
                </CardHeader>
                <CardBody>
                  <PostViewer posts={posts} />
                </CardBody>
              </Card>
            </div>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <center>
            <div className="boxed boxed--border col-md-8">
              <p>You have not yet setup a profile, please add some info</p>
              <Link to="/create-profile" className="btn btn-primary my-1">
                Create Profile
              </Link>
            </div>
          </center>
        </Fragment>
      )}
    </Fragment>
  );
};

Dashboard.propTypes = {
  post: PropTypes.object.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  getUserPosts: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
  post: state.post
});

export default connect(mapStateToProps, {
  getCurrentProfile,
  getUserPosts
})(Dashboard);
