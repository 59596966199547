import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import { STRIPE_P_KEY } from '../../config/default.json';
// import StripeCheckout from 'react-stripe-checkout';
// import { confirmPayment } from '../../actions/auth';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';

const stripePromise = loadStripe(STRIPE_P_KEY);

const Payment = ({ auth: { user, loading }, confirmPayment }) => {
  // const onToken = ({ id, card, created }) => {
  //   confirmPayment({ id, last4: card.last4, created });
  // };
  if (
    !loading &&
    user.subscriptions.subscriptionActive === true &&
    user.requirePayment === false
  ) {
    return <Redirect to="/timeline" />;
  }
  if (!loading) {
    return (
      <Fragment>
        <section className="container sec-pad-adjust">
          <Elements stripe={stripePromise}>
            <CheckoutForm />
          </Elements>
        </section>
      </Fragment>
    );
  } else {
    return <Spinner />;
  }
};

Payment.propTypes = {
  isAuthenticated: PropTypes.bool,
  auth: PropTypes.object.isRequired,
  confirmPayment: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  auth: state.auth,
  user: state.auth.user
});

export default connect(mapStateToProps)(Payment);
