import React, { useEffect, useRef, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { uploadMedia, deleteMedia } from '../../actions/media';
import { updateProfileImage } from '../../actions/profile';
import { loadUser } from '../../actions/auth';
import { Card, CardBody, Button } from 'reactstrap';

import './UploadImage.scss';

const UploadProfileImage = ({
  uploadMedia,
  deleteMedia,
  updateProfileImage,
  media: { progress },
  media,
  user,
  initialState
}) => {
  const [formData, setFormData] = useState({
    profileImage:
      user.profileImage === undefined
        ? 'https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png'
        : user.profileImage
  });
  const preservedState = useRef();
  const imageRef = useRef();
  const inputRef = useRef();

  useEffect(() => {
    loadUser();
    setFormData({ profileImage: user.profileImage });
    preservedState.current = undefined;
  }, [initialState, user.profileImage]);

  const handleChange = (e) => {
    const reader = new FileReader();
    if (e.target.files?.[0]) {
      reader.onload = function (e) {
        imageRef.current.src = e.target.result;
      };
      reader.readAsDataURL(e.target.files[0]);
      handleSubmit(e.target.files[0]);
    } else {
      imageRef.current.src = preservedState.current;
    }
  };
  const handleSubmit = (photo) => {
    (async () => {
      const url = await uploadMedia(photo, 'profile,delete');
      setFormData((data) => ({ ...data, profileImage: url }));
    })();
  };
  const handleUpload = () => {
    updateProfileImage(formData);
  };
  return (
    <Fragment>
      <Card className="no-background">
        <CardBody>
          <div className="upload-container w-100">
            <div className="form-group">
              <div className="d-flex justify-content-center">
                <div className="img-container">
                  <img
                    ref={imageRef}
                    src={formData.profileImage}
                    className="round-img img-with-overlay"
                    alt="profile pic preview"
                  />
                  <div
                    className={
                      'round-img ' +
                      (progress > 0 && progress < 100
                        ? 'img-overlay'
                        : 'hidden')
                    }
                  >
                    <progress
                      id="progress"
                      value={progress}
                      max="100"
                    ></progress>
                  </div>
                </div>
                {user.profileImage ===
                'https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png' ? (
                  <>
                    <input
                      type="file"
                      id="img-upload"
                      style={{ display: 'block' }}
                      accept="image/jpeg,image/png"
                      ref={inputRef}
                      onChange={handleChange}
                    />
                    <Button className="btn-ac-green">
                      Upload Profile Photo
                    </Button>
                  </>
                ) : (
                  <>
                    <input
                      type="file"
                      id="img-upload"
                      style={{ display: 'block' }}
                      accept="image/jpeg,image/png"
                      ref={inputRef}
                      onChange={handleChange}
                    />
                    <Button className="btn-ac-green">
                      Change Profile Photo
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
          <center>
            {formData.profileImage !== user.profileImage ? (
              <>
                <button className="btn btn-primary" onClick={handleUpload}>
                  Save Photo
                </button>
              </>
            ) : null}
          </center>
        </CardBody>
        <hr />
      </Card>
    </Fragment>
  );
};

UploadProfileImage.propTypes = {
  media: PropTypes.object.isRequired,
  uploadMedia: PropTypes.func.isRequired,
  deleteMedia: PropTypes.func.isRequired,
  updateProfileImage: PropTypes.func.isRequired,
  loadUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  media: state.media,
  user: state.user
});

export default connect(mapStateToProps, {
  uploadMedia,
  deleteMedia,
  updateProfileImage,
  loadUser
})(UploadProfileImage);
