import { createBrowserHistory } from 'history';

const redirector = createBrowserHistory({
    forceRefresh: true
  });

  // Listen for changes to the current location.
  redirector.listen((location, action) => {
    // location is an object like window.location
    console.log(action, location.pathname, location.state);
  });

  export default redirector;