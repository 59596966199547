import React, { useState, useEffect } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  requestPasswordReset,
  passwordResetStateCleanup
} from '../../actions/auth';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Button,
  Modal,
  ModalBody,
  Spinner,
  Row,
  Col
} from 'reactstrap';

const ResetPassword = ({
  requestPasswordReset,
  passwordResetStateCleanup,
  isAuthenticated,
  auth,
  session
}) => {
  const [formData, setFormData] = useState({
    email: ''
  });

  let history = useHistory();

  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (processing && session.passwordResetRequest) {
      setProcessing(false);
    }
  }, [processing, session.passwordResetRequest]);

  const { email } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);
    requestPasswordReset(email);
    setFormData({ email: '' });
  };

  function resetPasswordState() {
    passwordResetStateCleanup();
    history.push('/login');
  }

  // Redirect to the timeline if logged in
  if (isAuthenticated) {
    return <Redirect to="/timeline" />;
  }

  return (
    <div className="fullPage">
      <div className="content">
        <Container className="login-container">
          <Modal isOpen={processing}>
            <ModalBody>
              <center>
                <Spinner />
              </center>
              <p className="ac-text">Processing Password Reset</p>
            </ModalBody>
          </Modal>
          <Row>
            <center>
              <Col md="10">
                <Card className="card-plain reset-password-card">
                  {session?.passwordResetRequest ? null : (
                    <>
                      <Form
                        onSubmit={onSubmit}
                        className="form reset-password-form"
                      >
                        <CardHeader className="text-center">
                          <h1
                            className="panel-header"
                            style={{ paddingBottom: '0px' }}
                          >
                            Forgot Your Password?
                          </h1>
                          <h3
                            style={{
                              fontSize: '1.4em',
                              fontFamily: 'Source Sans Pro'
                            }}
                          >
                            To create a new password, enter the e-mail address
                            you used to create your account. We’ll send you a
                            link to reset your password.
                          </h3>
                        </CardHeader>
                        <CardBody style={{ padding: 0 }}>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="now-ui-icons users_circle-08"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Email Address"
                              type="email"
                              name="email"
                              value={email}
                              onChange={(e) => onChange(e)}
                              required
                            ></Input>
                          </InputGroup>
                        </CardBody>
                        <CardFooter className="text-center">
                          <Button
                            className="btn"
                            type="submit"
                            value="login"
                            style={{
                              backgroundColor: '#56776C',
                              fontFamily: 'Source Sans Pro',
                              fontSize: '1.3em'
                            }}
                          >
                            Send Password Reset Link
                          </Button>
                          <br></br>
                          <u
                            style={{
                              fontFamily: 'Source Sans Pro',
                              fontWeight: '400',
                              fontSize: '1em'
                            }}
                          >
                            <Link to="/login">Back to Login</Link>
                          </u>
                        </CardFooter>
                      </Form>
                    </>
                  )}
                  {session.data?.status === 200 ? (
                    <CardHeader
                      className="text-center"
                      style={{ padding: '0px 20px' }}
                    >
                      <h4>
                        <p>Password Reset Link Sent!</p>A password reset link
                        was sent to: {session.data?.email} from
                        support@adoptconnect.com. If you don’t see it in a
                        couple of minutes, please check your spam folder.
                        <br />
                        <div style={{ marginTop: '15px' }}>
                          <Button
                            style={{
                              backgroundColor: '#56776C',
                              fontFamily: 'Source Sans Pro'
                            }}
                            onClick={resetPasswordState}
                          >
                            Back to Login
                          </Button>
                        </div>
                        <div style={{ marginTop: '15px' }}>
                          Still having difficulty logging in?{' '}
                          <u>
                            <Link to="/support">Contact Support</Link>
                          </u>
                        </div>
                        <div style={{ marginTop: '15px' }}>
                          <p>Don’t have an account yet?</p>
                          <Button
                            className="btn"
                            style={{
                              backgroundColor: '#56776C',
                              fontFamily: 'Source Sans Pro'
                            }}
                          >
                            Sign Up
                          </Button>
                        </div>
                      </h4>
                    </CardHeader>
                  ) : null}
                </Card>
              </Col>
            </center>
          </Row>
        </Container>
      </div>
    </div>
  );
};

ResetPassword.propTypes = {
  requestPasswordReset: PropTypes.func.isRequired,
  passwordResetStateCleanup: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  auth: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  auth: state.auth,
  session: state.session
});

export default connect(mapStateToProps, {
  requestPasswordReset,
  passwordResetStateCleanup
})(ResetPassword);
