import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Col,
  Row,
  Input,
  FormGroup,
  Form,
  Label,
  Button,
  Spinner
} from 'reactstrap';
import { createTicket } from '../../actions/newsletter';
import { isMobile } from 'react-device-detect';

const Contact = ({ createTicket, session, auth }) => {
  const [seconds, setSeconds] = useState(0);
  const [inProgress, setInProgress] = useState(false);

  const initial = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function toggle() {
    setInProgress(!inProgress);
  }

  function reset() {
    setSeconds(0);
    setInProgress(false);
  }

  useEffect(() => {
    let interval = null;
    if (inProgress && seconds === 7) {
      toggle();
      reset();
    } else if (inProgress) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds + 1);
      }, 1000);
    } else if (!inProgress && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [inProgress, seconds, toggle]);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
    type: 'support'
  });

  useEffect(() => {
    if (auth.isAuthenticated) {
      setFormData({
        firstName: auth.user?.parent1_first_name,
        lastName: auth.user?.parent1_last_name,
        email: auth.user?.email
      });
    }
  }, [auth.isAuthenticated, auth.user]);

  const { email, firstName, lastName, message, phone } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    createTicket(formData);
    setFormData(initial);
    toggle();
  };

  return (
    <div style={{ paddingBottom: '3em' }}>
      <Helmet>
        <title>Adopt Connect - Contact Us</title>
        <meta name="description" content="Adopt Connect Blog" />
      </Helmet>
      <section className="container sec-pad-adjust">
        <div className="spacer"></div>
        <h1 className="panel-header" style={{ fontSize: '3em' }}>
          Need Tech Support?{' '}
        </h1>
        <center>
          <img
            src="https://res.cloudinary.com/puget-sound-networks/image/upload/f_auto/c_scale,w_180/v1594852204/ac_dev/Tech_Support_Icon_o6mzc7.png"
            alt="tech support icon"
          ></img>
          <h4>
            {inProgress ? (
              session.ticketSubmitted ? (
                'Form Submitted. Thank you!'
              ) : (
                <Spinner />
              )
            ) : null}
          </h4>
          <div className="supportPage" style={{ marginBottom: '4em' }}>
            <Form>
              <Row>
                <Col md="12" xs="12">
                  <FormGroup>
                    <Label>First Name</Label>
                    <Input
                      type="text"
                      name="firstName"
                      value={firstName}
                      onChange={(e) => onChange(e)}
                    />
                  </FormGroup>
                </Col>
                <Col md="12" xs="12">
                  <FormGroup>
                    <Label>Last Name</Label>
                    <Input
                      type="text"
                      name="lastName"
                      value={lastName}
                      onChange={(e) => onChange(e)}
                    />
                  </FormGroup>
                </Col>
                <Col md="12" xs="12">
                  <FormGroup>
                    <Label>Email</Label>
                    <Input
                      type="email"
                      name="email"
                      value={email}
                      onChange={(e) => onChange(e)}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md="12" xs="12">
                  <FormGroup>
                    <Label>Phone</Label>
                    <Input
                      type="phone"
                      name="phone"
                      value={phone}
                      onChange={(e) => onChange(e)}
                    />
                  </FormGroup>
                </Col>
                <Col md="12" xs="12">
                  <FormGroup>
                    <Label>Please briefly describe problem below:</Label>
                    <Input
                      type="textarea"
                      placeholder="Type Here"
                      name="message"
                      value={message}
                      onChange={(e) => onChange(e)}
                    />
                  </FormGroup>
                </Col>
                <Link to="/timeline" style={{ marginLeft: '20px' }}>
                  <Button
                    className="btn-ac-green"
                    style={{
                      display: 'block',
                      margin: 'auto',
                      textAlign: 'right'
                    }}
                  >
                    Cancel
                  </Button>
                </Link>
                <Button
                  className="btn-ac-green"
                  style={{
                    display: 'block',
                    margin: 'auto',
                    textAlign: 'right',
                    marginRight: '30px'
                  }}
                  onClick={onSubmit}
                >
                  Submit
                </Button>
              </Row>
            </Form>
          </div>
        </center>
        {isMobile ? (
          <div style={{ marginTop: '16em', marginBottom: '16em' }}>
            <center></center>
          </div>
        ) : (
          <div style={{ marginTop: '4em', marginBottom: '4em' }}>
            <center></center>
          </div>
        )}
      </section>
    </div>
  );
};

Contact.propTypes = {
  createTicket: PropTypes.func.isRequired,
  session: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  session: state.session,
  auth: state.auth
});

export default connect(mapStateToProps, { createTicket })(Contact);
