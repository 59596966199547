import React, { useState, useRef } from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col, Container } from 'reactstrap';
import Progress from './../upload-image/Progress';
import { uploadMedia, resetProgress, deleteMedia } from '../../actions/media';
import ImageGallery from 'react-image-gallery';

/**
 * TODO:
 *  1. Add button to "add" more instead of deleting and starting over
 *  2. Add array sorting function to make image at first index main picture
 *  3.
 *
 */

const ImageUpload = ({
  auth: { user },
  setMedia,
  uploadMedia,
  resetProgress,
  deleteMedia,
  media: { progress }
}) => {
  const fileInput = useRef();
  const [files, setFiles] = useState();
  const [imagePreviewUrls, setImagePreviewUrls] = useState([
    'https://res.cloudinary.com/puget-sound-networks/image/upload/f_auto/v1594937868/ac/AC_Photo_Placeholder_dl277h.png'
  ]);
  const [uploaded, setUploaded] = useState();

  const handleClick = () => {
    fileInput.current.click();
  };

  const handleRemove = async () => {
    setFiles(null);
    resetProgress();
    setMedia([]);
    setImagePreviewUrls(['']);
    fileInput.current.value = null;
    if (uploaded?.length) {
      await deleteMedia(uploaded.map(({ public_id }) => public_id));
    }
  };

  const handleImageChange = (e) => {
    e.preventDefault();
    resetProgress();
    setImagePreviewUrls([]);
    setMedia([]);
    Array.from(e.target.files).forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = (event) => {
        const dataUrl = event.target.result;
        const image = new Image();
        image.src = dataUrl;
        image.onload = () => {
          // const resizedDataUrl = formatImage(image);
          setImagePreviewUrls((current) => [...current, dataUrl]);
          setFiles((current) => (current ? [...current, file] : [file]));
        };
      };
      reader.readAsDataURL(file);
      handleSubmit(Array.from(e.target.files));
    });
  };

  const handleSubmit = (photos) => {
    (async () => {
      const images = await Promise.all(
        photos.map((photo) =>
          uploadMedia(photo, `post,user_${user._id},delete`)
        )
      );
      setUploaded(images);
      setMedia(images);
    })();
  };

  return (
    <Container fluid>
      <Row>
        {uploaded !== undefined ? (
          imagePreviewUrls.length === 1 ? (
            <div
              className="w-100 mb-3"
              style={{
                maxHeight: 250,
                padding: 20,
                maxWidth: '100%',
                overflow: 'auto',
                display: 'inline-flex',
                justifyContent: 'center'
              }}
            >
              <img
                src={imagePreviewUrls[0]}
                alt=""
                className="shadow mx-3"
                style={{ height: '100%' }}
              />
            </div>
          ) : (
            <div>
              <ImageGallery
                items={imagePreviewUrls.map((e) => ({
                  original: e,
                  thumbnail: e
                }))}
              />
            </div>
          )
        ) : (
          <> </>
        )}
      </Row>

      {/*       <Row>
        <div
          className="w-100"
          style={{
            maxHeight: 140,
            maxWidth: '90vw',
            padding: 20,
            overflow: 'auto',
            display: 'inline-flex',
            justifyContent: 'center'
          }}
        >
          {imagePreviewUrls?.slice(1).map((imagePreviewUrl, index) => (
            <img
              key={'key' + imagePreviewUrl[index] + imagePreviewUrl[30] + index}
              src={imagePreviewUrl}
              alt=""
              className="shadow mx-3"
              style={{ height: '100%' }}
              // onClick={() =>
              //   setImagePreviewUrls(current => {
              //     return current;
              //   })
              // }
            />
          ))}
        </div>
      </Row> */}
      <Row>
        <Col className="d-flex justify-content-center">
          {progress !== 0 / 100 ? <Progress progress={progress} /> : null}
          <div>
            {!files?.length ? (
              <Button className="btn-round btn-ac-green" onClick={handleClick}>
                Add Photo
              </Button>
            ) : (
              <span>
                {progress === 100 ? (
                  <Button
                    color="danger"
                    className="btn-round"
                    onClick={handleRemove}
                  >
                    <i className="fa fa-times" /> Remove
                  </Button>
                ) : null}
              </span>
            )}
          </div>
        </Col>
      </Row>
      <div className="fileinput">
        <input
          type="file"
          accept="image/png, image/jpeg"
          multiple
          onChange={handleImageChange}
          ref={fileInput}
          data-max-size="10"
        />
      </div>
    </Container>
  );
};

ImageUpload.propTypes = {
  auth: PropTypes.object.isRequired,
  media: PropTypes.object.isRequired,
  deleteMedia: PropTypes.func.isRequired,
  resetProgress: PropTypes.func.isRequired,
  uploadMedia: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  media: state.media
});

export default connect(mapStateToProps, {
  uploadMedia,
  resetProgress,
  deleteMedia
})(ImageUpload);
