import axios from 'axios';
import { setAlert } from './alert';
import { USER_LOADED } from './types';
import { API_URL } from '../config/default.json';

export const paymentHandler = async (result, dispatch) => {
  try {
    if (result.error) {
      // Show error in payment form
      console.log(result.error);
    } else {
      // Otherwise send paymentMethod.id to your server
      const res = await axios.post(`${API_URL}/stripe/subscription`, {
        payment_method: result.paymentMethod.id,
        collection_method: result.collection_method
      });

      dispatch({
        type: USER_LOADED,
        payload: res.data.user
      });

      return [res.data.subscription.latest_invoice.paid, res.data.user];
    }
  } catch (error) {
    console.log('ERROR:', error, error.response);
    if (error.response.data.code === 'card_declined') {
      dispatch(setAlert('Card Declined', 'error'));
    } else if (error.response.data.code === 'incorrect_cvc') {
      dispatch(setAlert('Incorrect CVC', 'error'));
    }
    return [error.response];
  }
};

export const subscriptionHandler = async (result, dispatch) => {
  try {
    const res = await axios.patch(`${API_URL}/stripe/subscription`, result);
    dispatch({
      type: USER_LOADED,
      payload: res.data.user
    });
  } catch (error) {
    console.log('ERROR:', error, error.response);
  }
};

export const deleteSubscription = async () => {
  try {
    await axios.delete(`${API_URL}/stripe/subscription`);
  } catch (error) {
    console.log('ERROR:', error, error.response);
  }
};
