import axios from 'axios';
import {
  ADD_DOCUMENT,
  GET_DOCUMENTS,
  DOCUMENT_DELETED,
  DOCUMENTS_ERROR,
  UPDATE_UPLOADING_PROGRESS
} from './types';
import { setAlert } from './alert';
import { API_URL } from '../config/default.json';

export const resetProgress = () => async (dispatch) => {
  dispatch({
    type: UPDATE_UPLOADING_PROGRESS,
    payload: undefined
  });
};

export const addDocument = (data) => async (dispatch) => {
  try {
    console.log(data);
    const document = await axios.post(`${API_URL}/documents/addDocument`, data);

    dispatch({
      type: ADD_DOCUMENT,
      payload: document.data
    });

    dispatch(setAlert('Document Uploaded', 'success'));

    return document;
  } catch (err) {
    dispatch({
      type: DOCUMENTS_ERROR,
      payload: {
        msg: err.message,
        status: err.name
      }
    });
    dispatch(setAlert('Error uploading document', 'error'));
  }
};

// takes a single publicId or array of publicIds
export const deleteDocument = (id) => async (dispatch) => {
  try {
    const document = await axios.delete(`${API_URL}/documents/remove/${id}`);

    dispatch({
      type: DOCUMENT_DELETED,
      payload: document._id
    });
  } catch (err) {
    console.log('ERROR:', err);
    dispatch({
      type: DOCUMENTS_ERROR,
      payload: {
        msg: err.message,
        status: err.name
      }
    });
  }
};

// takes a single publicId or array of publicIds
export const getDocuments = () => async (dispatch) => {
  try {
    const documents = await axios.get(`${API_URL}/documents`);
    dispatch({
      type: GET_DOCUMENTS,
      payload: documents
    });
  } catch (err) {
    console.log('ERROR:', err);
    dispatch({
      type: DOCUMENTS_ERROR,
      payload: {
        msg: err.message,
        status: err.name
      }
    });
  }
};
