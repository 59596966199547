import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

const HiwToggler = (data) => {
  const [modal, setModal] = useState(false);

  function toggle() {
    setModal(!data.data.status);
  }

  useEffect(() => {
    setModal(data.data.status);
  }, [data.data.status]);

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader></ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12">
              <Link to="/how-it-works-adoptive-parent">
                <Button
                  className="ralewayBold btn-block"
                  style={{
                    color: 'black',
                    backgroundColor: 'white',
                    border: '1px solid black'
                  }}
                  onClick={toggle}
                >
                  I'm an Adoptive Parent
                </Button>
              </Link>
            </div>
            <div className="col-md-12">
              <Link to="/how-it-works-birth-parent">
                <Button
                  className="ralewayBold btn-block"
                  style={{
                    color: 'black',
                    backgroundColor: 'white',
                    border: '1px solid black'
                  }}
                  onClick={toggle}
                >
                  I'm a Birth Parent
                </Button>
              </Link>
            </div>
            <div className="col-md-12">
              <Link to="/how-it-works-adoption-professional">
                <Button
                  className="ralewayBold btn-block"
                  style={{
                    color: 'black',
                    backgroundColor: 'white',
                    border: '1px solid black'
                  }}
                  onClick={toggle}
                >
                  I'm an Adoption Professional
                </Button>
              </Link>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

HiwToggler.propTypes = {};

export default HiwToggler;
