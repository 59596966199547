import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Container, Col, Row } from 'reactstrap';
import { isMobile } from 'react-device-detect';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';

import acAuthFooterLogo from '../../assets/img/acFooterLogo.png';

const acFooterLogo =
  'https://res.cloudinary.com/puget-sound-networks/image/upload/f_auto/v1595280508/ac/AC_White_Logo_for_Footer_ko4suj.png';

const Footer = ({ auth: { loading, isAuthenticated } }) => {
  if (loading) {
    return null;
  } else {
    if (isAuthenticated) {
      return (
        <div id="footer" className="footer">
          <Container>
            <Col>
              <img
                src={acAuthFooterLogo}
                alt="AdoptConnect Logo"
                className="footer-img"
              ></img>
            </Col>
            <Col>
              <Link
                className="ac-text"
                style={{ paddingRight: '20px', fontSize: '15px' }}
                to="/privacy-policy"
              >
                Privacy Policy
              </Link>
              <Link
                className="ac-text"
                to="/terms-of-service"
                style={{ fontSize: '15px' }}
              >
                Terms of Service
              </Link>
            </Col>
            &nbsp;
            <Col>© {new Date().getFullYear()} AdoptConnect</Col>
          </Container>
        </div>
      );
    } else if (!isAuthenticated && !isMobile) {
      return (
        <Container
          className="preAuthFooterContainer"
          style={{
            backgroundColor: '#57786C',
            color: 'white',
            height: '14em'
          }}
        >
          <Row style={{ marginLeft: '20%' }}>
            <Col md="2" style={{ marginLeft: '20px' }}>
              <img src={acFooterLogo} alt="AdoptConnect Logo"></img>
            </Col>
            <Col md="2">
              <ul>
                <p className="heavySubTitle">ABOUT</p>
                <li>
                  <Link
                    className="ac-text-4"
                    style={{ paddingRight: '20px', fontSize: '15px' }}
                    to="/about"
                  >
                    Our Story
                  </Link>
                </li>
                <li>
                  <Link
                    className="ac-text-4"
                    to="/how-it-works"
                    style={{ fontSize: '15px' }}
                  >
                    How It Works
                  </Link>
                </li>
                <li>
                  <Link
                    className="ac-text-4"
                    style={{ paddingRight: '20px', fontSize: '15px' }}
                    to="/blog"
                  >
                    Blog
                  </Link>
                </li>
                <li>
                  <Link
                    className="ac-text-4"
                    style={{ paddingRight: '20px', fontSize: '15px' }}
                    to="/contact"
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </Col>
            <Col md="2">
              <ul>
                <p className="heavySubTitle">HELP</p>
                <li>
                  <Link
                    className="ac-text-4"
                    style={{ paddingRight: '20px', fontSize: '15px' }}
                    to="/contact"
                  >
                    Support
                  </Link>
                </li>
                <li>
                  <Link
                    className="ac-text-4"
                    to="/terms-of-service"
                    style={{ fontSize: '15px' }}
                  >
                    Terms of Service
                  </Link>
                </li>
                <li>
                  <Link
                    className="ac-text-4"
                    to="/privacy-policy"
                    style={{ fontSize: '15px' }}
                  >
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </Col>
            <Col md="2">
              <ul>
                <p className="heavySubTitle">FOLLOW US</p>
                <li>
                  <div className="row">
                    <Col style={{ paddingRight: '0' }}>
                      <a
                        className="ac-text-4"
                        style={{ fontSize: '20px' }}
                        href="https://www.instagram.com/adopt.connect/"
                        alt="AdoptConnect Instagram"
                      >
                        <FontAwesomeIcon
                          icon={faInstagram}
                          size="lg"
                          style={{ paddingRight: '0px', fontSize: '2.5em' }}
                        />
                      </a>
                    </Col>
                    <Col>
                      <a
                        className="ac-text-4"
                        style={{ fontSize: '20px' }}
                        href="https://www.facebook.com/adoptionconnect/"
                        alt="AdoptConnect Facebook"
                      >
                        <FontAwesomeIcon
                          icon={faFacebook}
                          size="lg"
                          style={{ paddingRight: '0px', fontSize: '2.5em' }}
                        />
                      </a>
                    </Col>
                  </div>
                </li>
              </ul>
            </Col>
          </Row>
          <center>
            <Container
              style={{ position: 'absolute', bottom: '10px', left: '20%' }}
            >
              © {new Date().getFullYear()} AdoptConnect
            </Container>
          </center>
        </Container>
      );
    } else if (!isAuthenticated && isMobile) {
      return (
        <div
          className="preAuthFooterContainer"
          style={{
            backgroundColor: '#57786C',
            color: 'white',
            height: '22em'
          }}
        >
          <center>
            <img
              src={acFooterLogo}
              alt="AdoptConnect Logo"
              style={{ width: '70%', marginBottom: '.5em' }}
            ></img>
            <ul
              style={{
                padding: '0px 0px 20px 0px',
                backgroundColor: '#57786C',
                margin: '0'
              }}
            >
              <li>
                <Link
                  className="ac-text-4"
                  style={{ fontSize: '20px' }}
                  to="/contact"
                >
                  Contact Us
                </Link>
              </li>
              <li>
                <Link
                  className="ac-text-4"
                  style={{ fontSize: '20px' }}
                  to="/terms-of-service"
                >
                  Terms of Service
                </Link>
              </li>
              <li>
                <Link
                  className="ac-text-4"
                  style={{ fontSize: '20px' }}
                  to="/privacy-policy"
                >
                  Privacy Policy
                </Link>
              </li>
              <h3
                className="panel-header-4"
                style={{ margin: '0', paddingTop: '20px', fontSize: '2em' }}
              >
                Get Social
              </h3>
              <li>
                <a
                  className="ac-text-4"
                  style={{ fontSize: '20px' }}
                  href="https://www.instagram.com/adopt.connect/"
                  alt="AdoptConnect Instagram"
                >
                  <FontAwesomeIcon
                    icon={faInstagram}
                    size="lg"
                    style={{ paddingRight: '10px', fontSize: '2.5em' }}
                  />
                </a>
              </li>
              <li>
                <a
                  className="ac-text-4"
                  style={{ fontSize: '20px' }}
                  href="https://www.facebook.com/adoptionconnect/"
                  alt="AdoptConnect Facebook"
                >
                  <FontAwesomeIcon
                    icon={faFacebook}
                    size="lg"
                    style={{ paddingRight: '10px', fontSize: '2.5em' }}
                  />
                </a>
              </li>
            </ul>
          </center>
        </div>
      );
    }
  }
};

Footer.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps, {})(Footer);
