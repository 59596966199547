import React, { useState } from 'react';
import HiwToggler from '../howitworks/hiwToggler';

// core components

function LandingPageHeader(options) {
  const [modal, setModal] = useState(false);

  function toggle() {
    setModal(!modal);
  }

  let pageHeader = React.createRef();
  let headerImage = options.imageUrl === null ? null : options.imageUrl;
  let headerText = options.headerText === null ? null : options.headerText;
  let subHeaderText =
    options.subHeaderText === null ? null : options.subHeaderText;
  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          'translate3d(0,' + windowScrollTop + 'px,0)';
      };
      window.addEventListener('scroll', updateScroll);
      return function cleanup() {
        window.removeEventListener('scroll', updateScroll);
      };
    }
  });
  return (
    <>
      <HiwToggler data={{ status: modal }} />
      <div
        className="page-header page-header-small"
        style={{ marginTop: '-20px' }}
      >
        <div
          className="page-header-image"
          style={{
            backgroundImage: `url('${headerImage}')`
          }}
          ref={pageHeader}
        ></div>
        <div className="header-content">
          <div className="header-content-wrapper">
            {headerText === null ? null : (
              <>
                <h3 className="subTitle">{subHeaderText} </h3>
                <div className="intro-button-wrap">
                  <div color="link" className="intro-button" onClick={toggle}>
                    <i className="fab fa-facebook-square"></i>
                    Get Started
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default LandingPageHeader;
