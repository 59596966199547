import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { relativeDate } from '../../utils/dates';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Button } from 'reactstrap';
import Image from '../image';
import { addLike, unLike, deletePost } from '../../actions/post';
import CommentItem from '../posts/CommentItem';
import CommentForm from '../posts/CommentForm';
import { Player } from 'video-react';
import ImageGallery from 'react-image-gallery';

const PostItem = ({
  addLike,
  deletePost,
  profile: { profile },
  auth,
  post: {
    _id,
    text,
    title,
    avatar,
    user,
    likes,
    comments,
    createdAt,
    media,
    mediaType
  }
}) => {
  const [showComments, setShowComments] = useState(3);
  const posterUrl = media?.[0]?.originalUrl;
  const poster = `${posterUrl?.substring(0, posterUrl.lastIndexOf('.'))}.jpg`;
  const videoUrl = `https://res.cloudinary.com/puget-sound-networks/video/upload/q_auto/v1650735958/${media?.[0]?.publicId}`;

  function showAllComments() {
    setShowComments(comments.length);
  }

  const userLikes = Array.from(
    likes.map((e) => e.user),
    (x) => x
  );
  const isLiked = userLikes.includes(auth.user._id);

  return (
    <>
      <Card className="timeline-card">
        <CardHeader className="mt-2">
          <div className="d-inline-flex post-user-info">
            <div className="circular">
              <img
                className="post-profile-pic"
                src={user.profileImage}
                alt="profile"
              ></img>
            </div>
            <h6 className="post-user-name">
              {user.parent2_first_name !== ''
                ? user.parent1_first_name + ' and ' + user.parent2_first_name
                : user.parent1_first_name}
            </h6>
          </div>
        </CardHeader>
        {media?.[0]?.resourceType === 'video' ? (
          <Player poster={poster}>
            <source src={videoUrl} />
          </Player>
        ) : null}
        {media?.[0]?.resourceType === 'image' && mediaType === 'other' ? (
          <>
            {media?.[0]?.publicId && (
              <div className="card-image">
                <Image publicId={media?.[0]?.publicId} className="img" />
              </div>
            )}
          </>
        ) : null}
        {media?.[0]?.resourceType === 'image' && mediaType === 'image' ? (
          <>
            {media?.[0]?.publicId && (
              <div className="card-image">
                <Image publicId={media?.[0]?.publicId} className="img" />
              </div>
            )}
          </>
        ) : null}
        {media?.[0]?.resourceType === 'importedImage' ? (
          <>
            {media?.[0]?.url && (
              <div className="card-image">
                <img src={media?.[0]?.url} className="img" alt="" />
              </div>
            )}
          </>
        ) : null}
        {media?.[0]?.resourceType === 'gallery' ? (
          <>
            {media?.[0]?.publicId && <ImageGallery items={media?.[0].media} />}
          </>
        ) : null}
        {media?.[0]?.resourceType === 'image' && mediaType === 'gallery' ? (
          <>
            {media?.[0]?.publicId && (
              <ImageGallery
                items={media.map((e) => ({
                  original: e.largeUrl,
                  thumbnail: e.thumbnailUrl
                }))}
              />
            )}
          </>
        ) : null}
        <CardBody className="px-3">
          <ul className="list-inline">
            <li className="list-inline-item">
              <div className="card__action">
                <Link to={'#'} data-tip="View and Add comments">
                  <i className="material-icons mi-24">comment</i>
                  {comments.length > 0 && (
                    <span className="comment-count">{comments.length}</span>
                  )}
                </Link>
              </div>
            </li>
            <li className="list-inline-item">
              <div className="card__action">
                <Link to="#" data-tip="Like Post">
                  {isLiked ? (
                    <i
                      alt="like"
                      onClick={() => addLike(_id)}
                      className="material-icons mi-24"
                      style={{ color: 'red' }}
                    >
                      favorite
                    </i>
                  ) : (
                    <i
                      alt="like"
                      onClick={() => addLike(_id)}
                      className="material-icons mi-24"
                    >
                      favorite
                    </i>
                  )}
                  <span></span>
                </Link>
                {likes.length > 0 && (
                  <span className="comment-count">{likes.length}</span>
                )}
              </div>
            </li>
            {/*             <li className="list-inline-item">
              <div className="card__action">
                <Link to="#" data-tip="Like Post">
                  <ReportPost postId={_id} />
                  <span></span>
                </Link>
              </div>
            </li> */}
            <li className="list-inline-item">
              <div className="card__action">
                {!auth.loading && user._id === auth.user._id && (
                  <Link to="#" data-tip="Delete Post">
                    <i
                      className="material-icons"
                      onClick={(e) => deletePost(_id)}
                    >
                      delete
                    </i>
                  </Link>
                )}
              </div>
            </li>
          </ul>
          <p className="card-description post-text">{text}</p>
          <p className="text-muted post-date">
            {createdAt && relativeDate(createdAt)}
          </p>
          {auth.user?.settings?.twowaycommunication === true ||
          auth.user?.accountType === 'adoptiveParent' ? (
            <div>
              <hr width="100%"></hr>
              <CommentForm postId={_id} />
            </div>
          ) : null}
          <>
            <div className="comments" style={{ marginTop: '20px' }}>
              {comments.slice(0, showComments).map((comment) => (
                <CommentItem key={comment._id} comment={comment} postId={_id} />
              ))}
            </div>
            {comments.length > 3 ? (
              showComments > 3 ? (
                <Button
                  data-tip="View and Add comments"
                  onClick={(input) => setShowComments(3)}
                >
                  Close Comments
                </Button>
              ) : (
                <Button
                  data-tip="View and Add comments"
                  onClick={showAllComments}
                >
                  Open Comments
                </Button>
              )
            ) : (
              ''
            )}
          </>
        </CardBody>
      </Card>
    </>
  );
};

PostItem.propTypes = {
  post: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  addLike: PropTypes.func.isRequired,
  unLike: PropTypes.func.isRequired,
  deletePost: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  auth: state.auth
});

export default connect(mapStateToProps, { addLike, unLike, deletePost })(
  PostItem
);
