import {
  MEDIA_ERROR,
  MEDIA_UPLOADED,
  UPDATE_UPLOADED_MEDIA,
  UPDATE_UPLOADING_PROGRESS,
  DELETE_MEDIA
} from '../actions/types';

const initialState = {
  profilePhoto: undefined,
  postMedia: [],
  temp: [],
  progress: 0
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_UPLOADING_PROGRESS:
      return {
        ...state,
        progress: payload
      };
    case MEDIA_UPLOADED:
      return {
        ...state,
        temp: [payload, ...state.temp]
      };
    case UPDATE_UPLOADED_MEDIA:
      const updatedIndex = state.temp.findIndex(
        current => current.public_id === payload.publicId
      );
      state.temp[updatedIndex] = payload;
      return state;
    case MEDIA_ERROR:
      return {
        ...state,
        posts: payload
      };
    case DELETE_MEDIA:
      return {
        ...state
        // handle
      };
    default:
      return state;
  }
}
