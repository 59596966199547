import React, { Fragment, useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Container, Button, Card } from 'reactstrap';
import YouTube from 'react-youtube';

import LandingPageHeader from '../layout/PageHeader';
import HomeSlider from '../layout/HomeSlider';
import NewsletterSignup from '../forms/newsletterSignup';
import HiwToggler from '../howitworks/hiwToggler';

const GetStarted = ({ isAuthenticated }) => {
  const [modal, setModal] = useState(false);

  function toggle() {
    setModal(!modal);
  }

  if (isAuthenticated) {
    return <Redirect to="/timeline" />;
  }

  const opts = {
    height: '650',
    width: '1066',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
      rel: 0
    }
  };

  return (
    <Fragment>
      <HiwToggler data={{ status: modal }} />
      <Helmet>
        <title>
          AdoptConnect - The safest and easiest way for adoptive parents &amp;
          birth parents to keep in touch after adoption
        </title>
      </Helmet>
      <LandingPageHeader
        imageUrl="https://res.cloudinary.com/puget-sound-networks/image/upload/f_auto/v1607150682/AC-Web-Assets/bike_saxawt.png"
        headerText="AdoptConnect"
        subHeaderText="The safest and easiest way for adoptive parents and birth parents to stay connected after adoption"
      />
      <Container style={{ paddingBottom: '30px', paddingTop: '10px' }}>
        <center>
          <div className="row">
            <div className="col-md-12">
              <div className="single-panel">
                <p
                  className="ac-text-3"
                  style={{ textAlign: 'center', paddingTop: '20px' }}
                >
                  Instead of sending updates in the mail, through an agency, or
                  posting on social media, adoptive parents may now safely and
                  securely share photos, video, and updates with their child’s
                  birth parents on AdoptConnect.
                </p>
              </div>
            </div>
          </div>
          <h2 className="panel-header-2">Watch Our Video</h2>
          <YouTube videoId="FzeF1jKsr4E" opts={opts} className="youtubeEmbed" />
          <Button
            className="ralewayBold"
            style={{
              backgroundColor: '#e75833',
              color: 'white',
              marginTop: '40px',
              fontSize: '4em',
              padding: '15px 30px'
            }}
            onClick={toggle}
          >
            Sign Up
          </Button>
        </center>
      </Container>
      <section style={{ backgroundColor: 'rgb(87, 120, 108)' }}>
        <Container style={{ padding: '20px 0px 40px 0px' }}>
          <center>
            <div className="row">
              <div className="col-md-12 topspace">
                <hr width="0px" />
                <h1
                  className="panel-header"
                  style={{ color: 'white', paddingTop: '0em' }}
                >
                  Testimonials
                </h1>
              </div>
            </div>
            <HomeSlider />
          </center>
        </Container>
      </section>
      <Container style={{ padding: '20px 0px 40px 0px' }}>
        <center>
          <h2 className="panel-header-2">Read Our Blog</h2>
          <div className="row">
            <div className="col-md-4">
              <div className="cta">
                <Link to="/blog/post/open-adoption-irl/">
                  <Card
                    style={{
                      width: '300px',
                      height: '300px',
                      backgroundColor: '#e75833',
                      backgroundImage: `url('https://res.cloudinary.com/puget-sound-networks/image/upload/f_auto/v1607150553/AC-Web-Assets/Blog1_nox6y0.png')`,
                      backgroundSize: 'contain'
                    }}
                  >
                    <h4 className="blogCardText">Open Adoption IRL</h4>
                    <div className="blogSeparator" style={{ height: '2px' }} />
                    <h3 className="blogCardSubText">
                      {' '}
                      A Birth Mother & Adoptive Mother Share Their Honest
                      Perspectives
                    </h3>
                  </Card>
                </Link>
              </div>
            </div>
            <div className="col-md-4">
              <div className="cta">
                <Link to="/blog/post/one-birth-mothers-letter-to-her-daughter">
                  <Card
                    style={{
                      width: '300px',
                      height: '300px',
                      backgroundColor: '#e75833',
                      backgroundImage: `url('https://res.cloudinary.com/puget-sound-networks/image/upload/f_auto/v1607150541/AC-Web-Assets/Blog2_muwrpp.png')`,
                      backgroundSize: 'contain'
                    }}
                  >
                    <h4 className="blogCardText">Dear Hannah</h4>
                    <div className="blogSeparator" style={{ height: '2px' }} />
                    <h3 className="blogCardSubText">
                      {' '}
                      One Birth Mother's Letter to Her Daughter
                    </h3>
                  </Card>
                </Link>
              </div>
            </div>
            <div className="col-md-4">
              <div className="cta">
                <Link to="/blog/post/research-spotlight">
                  <Card
                    style={{
                      width: '300px',
                      height: '300px',
                      backgroundColor: '#e75833',
                      backgroundImage: `url('https://res.cloudinary.com/puget-sound-networks/image/upload/f_auto/v1607150549/AC-Web-Assets/Blog3_cbcxlo.png')`,
                      backgroundSize: 'contain'
                    }}
                  >
                    <h4 className="blogCardText">Research Spotlight</h4>
                    <div className="blogSeparator" style={{ height: '2px' }} />
                    <h3 className="blogCardSubText">
                      {' '}
                      What the Experts Tell Us About Open Adoption
                    </h3>
                  </Card>
                </Link>
              </div>
            </div>
          </div>
        </center>
      </Container>
      <section
        style={{ backgroundColor: 'rgb(87, 120, 108)', marginBottom: '40px' }}
      >
        <Container style={{ padding: '4em 0em 7em 0em' }}>
          <center>
            <div className="row">
              <div className="col-md-12 topspace">
                <hr width="0px" />
                <h1
                  className="panel-header-4"
                  style={{ color: 'white', paddingTop: '0em' }}
                >
                  Our Social Impact
                </h1>
                <p className="ac-text-2">
                  At AdoptConnect, we believe in giving back. That’s why we
                  donate a portion of each AdoptConnect subscription fee to
                  non-profit organizations who provide post-adoption support
                  services.{' '}
                </p>
              </div>
            </div>
          </center>
        </Container>
      </section>
      <NewsletterSignup />
    </Fragment>
  );
};

GetStarted.propTypes = {
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(GetStarted);
