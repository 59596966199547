import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  NotificationContainer,
  NotificationManager
} from 'react-notifications';

const Alert = ({ alerts }) => {
  alerts !== null &&
    alerts.length > 0 &&
    alerts.map((alert) => (
      <div key={alert.id}>
        {alert.alertType === 'success'
          ? NotificationManager.success(alert.msg)
          : null}
        {alert.alertType === 'error'
          ? NotificationManager.error(alert.msg)
          : null}
      </div>
    ));

  return (
    <div className="alert-box">
      <NotificationContainer />
    </div>
  );
};

Alert.propTypes = {
  alerts: PropTypes.array.isRequired
};

const mapStateToProps = (state) => ({
  alerts: state.alert
});

export default connect(mapStateToProps)(Alert);
