import React, { useState } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const SaveEvent = ({ user }) => {
  const [liveModal, setLiveModal] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState('');

  return (
    <>
      {user}
      <Button color="primary" type="button" onClick={() => setLiveModal(true)}>
        <i className="now-ui-icons ui-1_simple-add pl-1"></i> Add Reminder
      </Button>
      <Modal isOpen={liveModal} toggle={() => setLiveModal(false)}>
        <div className="modal-header">
          <h5
            className="panel-header"
            style={{ fontSize: '1.5em' }}
            id="exampleModalLiveLabel"
          >
            Add Reminder
          </h5>
          <button
            aria-label="Close"
            className="close"
            type="button"
            onClick={() => setLiveModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <p style={{ padding: '0px 30px 0px 30px' }}>
          Schedule reminders about future updates. We’ll nudge you via email
          three times - two weeks, one week and three days before your update is
          due.{' '}
        </p>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <ModalBody></ModalBody>
      </Modal>
    </>
  );
};

SaveEvent.propTypes = {
  event: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps, {})(SaveEvent);
