import React from 'react';
import { Image, CloudinaryContext } from 'cloudinary-react';

// ------------------------ UNDER DEVELOPMENT: DON'T USE ------------------------

const ProtoImage = ({
  publicId,
  width = 'auto',
  height,
  crop = 'scale',
  children,
  ...props
}) => {
  return (
    <CloudinaryContext cloudName="puget-sound-networks">
      <Image
        publicId={publicId}
        quality="auto"
        fetchFormat="auto"
        dpr="auto"
        responsive={true}
        width={width}
        height={height}
        crop={crop}
        responsiveUseBreakpoints="true"
        {...props}
      >
        {children}
      </Image>
    </CloudinaryContext>
  );
};

export default ProtoImage;
