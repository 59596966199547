import React from 'react';
import { useIsMounted } from './useIsMounted';
import { updatePassword } from '../actions/auth';
import useUser from './useUser';

export const useUpdatePassword = () => {
  const isMounted = useIsMounted();
  const { mutate } = useUser();
  const [success, setSuccess] = React.useState();
  const [error, setError] = React.useState();
  const update = async (currentPw, newPw) => {
    try {
      const data = await updatePassword(currentPw, newPw);
      console.log('GOT DATA IN useUpdatePassword', data);
      data && mutate(data);
      isMounted && setSuccess(true);
    } catch (e) {
      isMounted && setError(e);
      isMounted && setSuccess(false);
    }
  };
  return { error, success, update };
};
