import axios from 'axios';
import { setAlert } from './alert';
import {
  GET_POSTS,
  GET_POST,
  POST_ERROR,
  UPDATE_LIKES,
  ADD_POST,
  DELETE_POST,
  ADD_COMMENT,
  REMOVE_COMMENT,
  ADD_POST_REPORT
} from './types';
import { API_URL } from '../config/default.json';
import { getNotifications } from './notifications';

// Get Posts
export const getPosts = () => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}/post/timeline/connectionposts`);

    dispatch({
      type: GET_POSTS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: {
        msg: err.message,
        status: err.name
      }
    });
  }
};

// Add post
export const addPost = (data) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const res = await axios.post(`${API_URL}/post`, data, config);

    dispatch({
      type: ADD_POST,
      payload: res.data
    });

    dispatch(setAlert('Post Created', 'success'));
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.message, status: err.name }
    });
    if (err.message === 'Request failed with status code 400') {
      dispatch(setAlert('Empty Posts Not Allowed', 'error'));
    }
  }
};

// Add Like
export const addLike = (postId) => async (dispatch) => {
  try {
    const res = await axios.put(`${API_URL}/post/like/${postId}`);

    dispatch({
      type: UPDATE_LIKES,
      payload: { postId, likes: res.data }
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: {
        msg: err.message,
        status: err.name
      }
    });
  }
};

// Remove Like
export const unLike = (postId) => async (dispatch) => {
  try {
    const res = await axios.put(`${API_URL}/post/unlike/${postId}`);

    dispatch({
      type: UPDATE_LIKES,
      payload: { postId, likes: res.data }
    });
  } catch (err) {
    alert(err.message);
    dispatch({
      type: POST_ERROR,
      payload: {
        msg: err.message,
        status: err.name
      }
    });
  }
};

// Get post
export const getPost = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}/post/${id}`);

    dispatch({
      type: GET_POST,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.message, status: err.name }
    });
  }
};

// Get My Posts
export const getUserPosts = () => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}/post/timeline/myposts`);

    dispatch({
      type: GET_POSTS,
      payload: res.data
    });

    dispatch(getNotifications());
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: {
        msg: err.message,
        status: err.name
      }
    });
  }
};

// Get My Connections Posts
export const getConnectionPosts = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${API_URL}/post/timeline/connectionposts/${id}`
    );

    dispatch({
      type: GET_POSTS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: {
        msg: err.message,
        status: err.name
      }
    });
  }
};

// Delete Post
export const deletePost = (id) => async (dispatch) => {
  try {
    // eslint-disable-next-line no-unused-vars
    const res = await axios.delete(`${API_URL}/post/${id}`);

    dispatch({
      type: DELETE_POST,
      payload: id
    });

    dispatch(setAlert('Post Removed', 'success'));
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: {
        msg: err.message,
        status: err.name
      }
    });
  }
};

// Add Comment
export const addComment = (postId, formData) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const res = await axios.post(
      `${API_URL}/post/comment/${postId}`,
      formData,
      config
    );

    dispatch({
      type: ADD_COMMENT,
      payload: res.data
    });

    dispatch(setAlert('Comment Added', 'success'));
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.message, status: err.name }
    });
  }
};

// Delete Comment
export const deleteComment = (postId, commentId) => async (dispatch) => {
  try {
    // eslint-disable-next-line no-unused-vars
    const res = await axios.delete(
      `${API_URL}/post/comment/${postId}/${commentId}`
    );

    dispatch({
      type: REMOVE_COMMENT,
      payload: {
        postID: postId,
        commentId: commentId,
        comments: res.data.comments
      }
    });

    dispatch(setAlert('Comment Removed', 'success'));
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.message, status: err.name }
    });
  }
};

// Add post image
export const addPostImage = (formData) => async (dispatch) => {
  try {
    const res = await axios.post(`${API_URL}/upload`, formData);

    dispatch({
      type: ADD_POST,
      payload: res.data
    });

    dispatch(setAlert('Post Created', 'success'));
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.message, status: err.name }
    });
  }
};

export const addReport = (id, report) => async (dispatch) => {
  try {
    await axios.post(`${API_URL}/post/${id}/report`, report);

    dispatch({
      type: ADD_POST_REPORT
    });

    dispatch(setAlert('Post has been reported.', 'success'));
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.message, status: err.name }
    });
  }
};
