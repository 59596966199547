import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Col,
  Row,
  Input,
  FormGroup,
  Form,
  Label,
  Button,
  Spinner
} from 'reactstrap';
import { createContact } from '../../actions/newsletter';

import facebookLogo from '../../assets/img/facebook.png';
import instagramLogo from '../../assets/img/instagram.png';
import emailLogo from '../../assets/img/mail.png';
import phoneLogo from '../../assets/img/phone.png';

const Contact = ({ createContact, session }) => {
  const [seconds, setSeconds] = useState(0);
  const [inProgress, setInProgress] = useState(false);

  const toggle = useCallback(() => {
    setInProgress(!inProgress);
  }, [inProgress]);

  function reset() {
    setSeconds(0);
    setInProgress(false);
  }

  useEffect(() => {
    let interval = null;
    if (inProgress && seconds === 7) {
      toggle();
      reset();
    } else if (inProgress) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds + 1);
      }, 1000);
    } else if (!inProgress && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [inProgress, seconds, toggle]);

  var mainSectionStyle = {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url('https://res.cloudinary.com/puget-sound-networks/image/upload/f_auto/v1594926353/about-banner_g0fznk.png')`
  };

  const initial = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
    type: 'contact'
  };

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
    type: 'contact'
  });

  const { email, firstName, lastName, message, phone } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    createContact(formData);
    setFormData(initial);
    toggle();
  };

  return (
    <div style={{ paddingBottom: '3em' }}>
      <Helmet>
        <title>Adopt Connect - Contact Us</title>
        <meta name="description" content="Adopt Connect Blog" />
      </Helmet>
      <div style={mainSectionStyle} className="banner">
        <h1 className="banner banneroverlay">Contact Us</h1>
      </div>
      <section className="container sec-pad-adjust">
        <div className="spacer"></div>
        <h1 className="panel-header" style={{ fontSize: '2em' }}>
          Have a question or a comment? <br /> Send us a message. We’ll get
          right back to you.{' '}
        </h1>
        <center>
          <h4>
            {inProgress ? (
              session.ticketSubmitted ? (
                'Contact Form Submitted. Thank you!'
              ) : (
                <Spinner />
              )
            ) : null}
          </h4>
        </center>
        <Form onSubmit={onSubmit}>
          <Row>
            <Col md="6" xs="12">
              <FormGroup>
                <Label>First Name</Label>
                <Input
                  type="text"
                  name="firstName"
                  value={firstName}
                  onChange={(e) => onChange(e)}
                />
              </FormGroup>
            </Col>
            <Col md="6" xs="12">
              <FormGroup>
                <Label>Last Name</Label>
                <Input
                  type="text"
                  name="lastName"
                  value={lastName}
                  onChange={(e) => onChange(e)}
                />
              </FormGroup>
            </Col>
            <Col md="6" xs="12">
              <FormGroup>
                <Label>Email</Label>
                <Input
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => onChange(e)}
                  required
                />
              </FormGroup>
            </Col>
            <Col md="6" xs="12">
              <FormGroup>
                <Label>Phone</Label>
                <Input
                  type="phone"
                  name="phone"
                  value={phone}
                  onChange={(e) => onChange(e)}
                />
              </FormGroup>
            </Col>
            <Col md="12" xs="12">
              <FormGroup>
                <Label>Message</Label>
                <Input
                  type="textarea"
                  placeholder="Type Message Here..."
                  name="message"
                  value={message}
                  onChange={(e) => onChange(e)}
                />
              </FormGroup>
            </Col>
            <Button
              className="btn-ac-green"
              style={{ display: 'block', margin: 'auto', textAlign: 'center' }}
            >
              Submit
            </Button>
          </Row>
        </Form>

        <h3 className="panel-header-2" style={{ marginTop: '50px' }}>
          Connect with us!
        </h3>
        <center>
          <Row>
            <Col md="6">
              <a href="mailto: hello@adoptconnect.com">
                <img
                  src={emailLogo}
                  alt="Email Us"
                  style={{ width: '100px' }}
                  className="contactUsRight"
                />
              </a>
            </Col>
            <Col md="6">
              <a href="tel:6262196266">
                <img
                  src={phoneLogo}
                  alt="Call Us"
                  style={{ width: '100px' }}
                  className="contactUsLeft"
                />
              </a>
            </Col>
            <Col md="6">
              <a href="https://www.facebook.com/adoptionconnect/">
                <img
                  src={facebookLogo}
                  alt="Facebook Logo"
                  style={{ width: '100px' }}
                  className="contactUsRight"
                />
              </a>
            </Col>
            <Col md="6">
              <a href="https://www.instagram.com/adopt.connect/">
                <img
                  src={instagramLogo}
                  alt="Instagram Logo"
                  style={{ width: '100px' }}
                  className="contactUsLeft"
                />
              </a>
            </Col>
          </Row>
        </center>
        <Row
          style={{
            padding: '20px 0%',
            border: '1px solid black',
            margin: '60px 0px'
          }}
        >
          <Col md="8">
            <p className="ac-text">
              If you're an adoption professional and would like more information
              about AdoptConnect, please schedule a 20-minute call with one of
              our founders.
            </p>
          </Col>
          <Col md="4">
            <Link to="/how-it-works-adoption-professional-2">
              <Button
                className="btn-center"
                style={{
                  marginTop: '10%',
                  width: '260px',
                  height: '',
                  backgroundColor: '#57786c',
                  border: '2px solid black'
                }}
              >
                <div className="ralewayBold">Schedule Intro Call</div>
              </Button>
            </Link>
          </Col>
        </Row>
      </section>
    </div>
  );
};

Contact.propTypes = {
  createContact: PropTypes.func.isRequired,
  session: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  session: state.session
});

export default connect(mapStateToProps, { createContact })(Contact);
