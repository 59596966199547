import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { getPosts } from '../../actions/post';
import Spinner from '../layout/Spinner';
import PostForm from '../posts/PostForm';
import redirector from '../../functions/redirector/Redirector';

const AddPost = ({ getPosts, post: { loading }, auth: { user } }) => {
  useEffect(() => {
    getPosts();
  }, [getPosts]);

  const goToTimeline = () => {
    redirector.push('/timeline');
  };

  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      {user.confirmed === false ? (
        goToTimeline()
      ) : (
        <>
          <Container>
            <Row>
              <Col>
                <h1>Create a Post</h1>
              </Col>
            </Row>
            <Row>
              <Col>
                <PostForm />
              </Col>
            </Row>
          </Container>
        </>
      )}
    </Fragment>
  );
};

AddPost.propTypes = {
  getPosts: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  post: state.post,
  auth: state.auth
});

export default connect(mapStateToProps, { getPosts })(AddPost);
