import { GET_NOTIFICATIONS, SET_NO_NOTIFICATIONS } from '../actions/types';

const initialState = {
  notifications: [],
  notificationsLoading: true,
  error: {}
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: payload.data,
        unread: payload.count,
        notificationsLoading: false
      };
    case SET_NO_NOTIFICATIONS:
      return {
        ...state,
        notifications: [],
        unread: 0,
        notificationsLoading: false
      };
    default:
      return state;
  }
}
