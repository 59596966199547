import React, { useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login } from '../../actions/auth';
import { createBrowserHistory } from 'history';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Button
} from 'reactstrap';
import LargeAlert from '../layout/LargeAlert';
import './Auth.scss';

const redirector = createBrowserHistory({
  forceRefresh: true
});

// Listen for changes to the current location.
redirector.listen((location, action) => {
  // location is an object like window.location
  console.log(action, location.pathname, location.state);
});

const Login = ({ login, isAuthenticated, auth }) => {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add('login-page');
    document.body.classList.add('sidebar-collapse');
    document.documentElement.classList.remove('nav-open');
    return function cleanup() {
      document.body.classList.remove('login-page');
      document.body.classList.remove('sidebar-collapse');
    };
  });

  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    login(email, password);
  };

  var str = window.location.href;
  var newStr = str.replace(
    'https://beta.adoptconnect.com/login?nxtpg=/confirm/',
    ''
  );

  // Redirect to confirmation url if confirmation path in URL
  if (
    isAuthenticated &&
    window.location.href.includes(
      'https://beta.adoptconnect.com/login?nxtpg=/confirm'
    )
  ) {
    return <Redirect to={'/confirm/' + newStr} />;
  }

  // Redirect to the timeline if logged in
  if (isAuthenticated) {
    return <Redirect to="/timeline" />;
  }

  return (
    <div className="fullPage">
      <div className="content">
        <Container className="login-container">
          <Card className="card-plain login-card">
            <Form onSubmit={onSubmit} className="form login-form">
              <CardHeader className="text-center">
                <div className="logo-container">
                  <img
                    alt="..."
                    src={require('../../assets/img/logo@3x.png')}
                    className="logo-image"
                  ></img>
                </div>
              </CardHeader>
              <CardBody style={{ paddingBottom: '3px' }}>
                <LargeAlert />
                <InputGroup
                  className={
                    'no-border input-lg' +
                    (firstFocus ? ' input-group-focus' : '')
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons users_circle-08"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    onFocus={() => setFirstFocus(true)}
                    onBlur={() => setFirstFocus(false)}
                    placeholder="Email Address"
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => onChange(e)}
                    required
                  ></Input>
                </InputGroup>
                <InputGroup
                  className={
                    'no-border input-lg' +
                    (lastFocus ? ' input-group-focus' : '')
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons text_caps-small"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    onFocus={() => setLastFocus(true)}
                    onBlur={() => setLastFocus(false)}
                    placeholder="Password"
                    type="password"
                    name="password"
                    minLength="6"
                    value={password}
                    onChange={(e) => onChange(e)}
                    required
                  ></Input>
                </InputGroup>
              </CardBody>
              <CardFooter className="text-center" style={{ marginTop: '0' }}>
                <Button
                  className="btn-block"
                  type="submit"
                  value="login"
                  style={{ backgroundColor: '#47604e' }}
                >
                  Login
                </Button>
                <Link to="/reset-password" style={{ color: '#e75833' }}>
                  Forgot Password?
                </Link>
              </CardFooter>
            </Form>
          </Card>
        </Container>
      </div>
    </div>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  auth: state.auth
});

export default connect(mapStateToProps, { login })(Login);
