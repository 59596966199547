import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Waiter } from 'react-wait';
import { withRouter } from 'react-router';
import ua from 'universal-analytics';
import LogRocket from 'logrocket';

// Import SCSS
import './App.scss';
import './assets/scss/bootstrap/bootstrap.scss';
import './assets/scss/now-ui-kit.scss';
import 'react-notifications/lib/notifications.css';

import { loadUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';

// Import Pages and Layout Components
// import Header from './components/layout/Header';
import Landing from './components/landing/Landing';
import Login from './components/auth/Login';
import Dashboard from './components/dashboard/Dashboard';
import CreateProfile from './components/profile-forms/CreateProfile';
import EditProfile from './components/profile-forms/EditProfile';
import Profile from './components/profile/Profile';
import Post from './components/post/Post';
import Posts from './components/posts/Posts';
import Timeline from './components/timeline/Timeline';
import Marketplace from './components/marketplace/Marketplace';
import addConnection from './components/profile-forms/addConnection';
import Footer from './components/footer/Footer';
import noMatch from './components/error-pages/noMatch';
import AddPost from './components/post/AddPost';
import GetStarted from './components/getstarted/getStarted';
import Contact from './components/contact/Contact';
import Blog from './components/blog/Blog';
import privacyPolicy from './components/privacypolicy/privacyPolicy';
import TermsAndConditions from './components/tos/termsAndConditions';
import BlogPost from './components/blog/BlogItem';
import OfflinePage from './components/offlinepage/offline';
import AnnualRegistration from './components/registration/annualMember';
import birthParent from './components/registration/birthParent';
import whoWeSupport from './components/whowesupport/whoWeSupport';
import Payment from './components/payment/payment';
import Calendar from './components/calendar';
import ConfirmPassword from './components/auth/ConfirmPassword';
import Confirm from './components/confirmation/Confirm';
import ConfirmUser from './components/auth/ConfirmAccount';
import ConfirmEmail from './components/confirmEmail/ConfirmEmail';
import ResetPassword from './components/auth/Reset-Password';
import About from './components/aboutus/About';
import howItWorks from './components/howitworks/howItWorks';
import hiwAdoptiveParent from './components/howitworks/hiwAdoptiveParent';
import hiwAdoptionProfessional from './components/howitworks/hiwAdoptionProfessional';
import hiwBirthParent from './components/howitworks/hiwBirthParent';
import ScrollToTop from './functions/scrollToTop';
import hiwAdoptionProfessional2 from './components/howitworks/hiwAdoptionProfessional2';
import AgencyDemo from './components/registration/agencyDemo';
import Documents from './components/documents/documents';
import Support from './components/support/Support';
import ResetPasswordWithToken from './components/auth/Reset-Password-With-Token';
import Navbar from './components/Navbar/Navbar';

// Initialize Special Routes
import PrivateRoute from './components/routing/PrivateRoute';

// Initialize Alerts
import Alert from './components/layout/Alert';

// Redux
import { Provider } from 'react-redux';
import store from './store';
import Notifications from './components/notifications/notifications';

//Init Environment Variables
require('dotenv').config();

// Set the token for local storage
if (localStorage.token) {
  setAuthToken(localStorage.token);
}

// Google Analytics
var visitor = ua('UA-93879568-2');
const ChangeTracker = withRouter(({ match, location, history }) => {
  visitor.pageview(location.pathname).send();
  return false;
});

LogRocket.init('buxzjb/adoptconnect');

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <Waiter>
        <Router>
          <ScrollToTop />
          <>
            <Navbar />
            <div id="page-container">
              <div id="content-wrap">
                <Alert />
                <Switch>
                  <Route exact path="/" component={Landing} />
                  <Route exact path="/get-started" component={GetStarted} />
                  <Route
                    exact
                    path="/how-it-works-adoptive-parent"
                    component={hiwAdoptiveParent}
                  />
                  <Route
                    exact
                    path="/how-it-works-birth-parent"
                    component={hiwBirthParent}
                  />
                  <Route
                    exact
                    path="/how-it-works-adoption-professional"
                    component={hiwAdoptionProfessional}
                  />
                  <Route
                    exact
                    path="/how-it-works-adoption-professional-2"
                    component={hiwAdoptionProfessional2}
                  />
                  <Route exact path="/support" component={Support} />
                  <Route exact path="/how-it-works" component={howItWorks} />
                  <Route exact path="/contact" component={Contact} />
                  <Route exact path="/landing" component={Landing} />
                  <Route
                    exact
                    path="/register/annualmember"
                    component={AnnualRegistration}
                  />
                  <Route
                    exact
                    path="/register/bp/:id"
                    component={birthParent}
                  />
                  <Route
                    exact
                    path="/reset-password/:id"
                    component={ResetPasswordWithToken}
                  />
                  <Route
                    exact
                    path="/register/demo-account"
                    component={AgencyDemo}
                  />
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/marketplace" component={Marketplace} />
                  <Route
                    exact
                    path="/marketplace/:id"
                    component={Marketplace}
                  />
                  <Route exact path="/blog" component={Blog} />
                  <Route exact path="/blog/post/:id" component={BlogPost} />
                  <Route
                    exact
                    path="/privacy-policy"
                    component={privacyPolicy}
                  />
                  <Route
                    exact
                    path="/terms-of-service"
                    component={TermsAndConditions}
                  />
                  <Route
                    exact
                    path="/who-we-support"
                    component={whoWeSupport}
                  />
                  <Route exact path="/about" component={About} />
                  <Route
                    exact
                    path="/reset-password"
                    component={ResetPassword}
                  />
                  <Route exact path="/confirm/:id" component={Confirm} />
                  <PrivateRoute path="/calendar" component={Calendar} />
                  <PrivateRoute path="/documents" component={Documents} />
                  <PrivateRoute path="/document/:id" component={Documents} />
                  <PrivateRoute exact path="/profile/:id" component={Profile} />
                  <PrivateRoute exact path="/dashboard" component={Dashboard} />
                  <PrivateRoute exact path="/profile/" component={Dashboard} />
                  <PrivateRoute
                    exact
                    path="/create-profile"
                    component={CreateProfile}
                  />
                  <PrivateRoute
                    exact
                    path="/edit-profile"
                    component={EditProfile}
                  />
                  <PrivateRoute
                    exact
                    path="/add-connection"
                    component={addConnection}
                  />

                  <PrivateRoute exact path="/posts" component={Posts} />
                  <PrivateRoute exact path="/timeline" component={Timeline} />
                  <PrivateRoute
                    exact
                    path="/timeline/:id"
                    component={Timeline}
                  />
                  <PrivateRoute exact path="/post/:id" component={Post} />
                  <PrivateRoute exact path="/add-post" component={AddPost} />
                  <PrivateRoute
                    exact
                    path="/notifications"
                    component={Notifications}
                  />
                  <PrivateRoute
                    exact
                    path="/account/payment"
                    component={Payment}
                  />
                  <PrivateRoute
                    exact
                    path="/account/password/"
                    component={ConfirmPassword}
                  />
                  <PrivateRoute
                    exact
                    path="/confirmemail"
                    component={ConfirmEmail}
                  />
                  <Route
                    exact
                    path="/invite/createaccount/:id"
                    component={ConfirmUser}
                  />
                  <Route exact path="/checkstatus" component={OfflinePage} />
                  <Route component={noMatch} />
                </Switch>
              </div>
            </div>
            <Footer />
          </>
          <ChangeTracker />
        </Router>
      </Waiter>
    </Provider>
  );
};

export default App;
