import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  Form,
  FormGroup,
  Input,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import { addPost } from '../../actions/post';
import UploadImage from './../upload-image/UploadImage';
import UploadVideo from '../upload-video/UploadVideo';

const PostForm = ({ addPost }) => {
  const [liveModal, setLiveModal] = useState(false);
  const initial = {
    text: '',
    media: []
  };
  const [post, setPost] = useState({
    text: '',
    media: []
  });
  const setMedia = (media) => setPost((current) => ({ ...current, media }));
  const handleChange = ({ target }) =>
    setPost((current) => ({ ...current, [target.name]: target.value }));
  const handleSubmit = (e) => {
    e.preventDefault();
    addPost(post);
    setLiveModal(false);
    setPost(initial);
  };
  function toggleModal() {
    setLiveModal(!liveModal);
    setPost(initial);
  }
  return (
    <>
      <Button
        type="button"
        onClick={toggleModal}
        style={{ backgroundColor: '#47604e' }}
      >
        <i
          className="now-ui-icons ui-1_simple-add pl-1"
          style={{ backgroundColor: '#47604e' }}
        ></i>{' '}
        Add Post
      </Button>
      <Modal
        className="add-post-modal"
        isOpen={liveModal}
        toggle={toggleModal}
        autoFocus={false}
      >
        <ModalHeader toggle={toggleModal}>New Post</ModalHeader>

        <Form onSubmit={handleSubmit}>
          <ModalBody>
            <FormGroup>
              <CardBody>
                <Input
                  type="textarea"
                  className="post-form-textarea"
                  name="text"
                  onChange={handleChange}
                  autoFocus={true}
                  placeholder="Enter post text here"
                />
                <UploadImage setMedia={setMedia} />
              </CardBody>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              className="btn-ac-green"
              onClick={toggleModal}
            >
              Cancel
            </Button>
            <Button type="submit" color="primary" className="btn-ac-green">
              Save
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

PostForm.propTypes = {
  addPost: PropTypes.func.isRequired
};

export default connect(null, { addPost })(PostForm);
