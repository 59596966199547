import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

export default () => (
    <Fragment>
        <div className="main-container">
            <section className="text-center">
                <div className="container pos-vertical-center">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="h1--large">404</h1>
                            <p className="lead">
                                The page you were looking for was not found.
                            </p>
                            <Link to="/">Go back to home page</Link>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </Fragment>
);