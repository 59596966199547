import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ToggleSwitch from '../layout/ToggleSwitch';
import { Button } from 'reactstrap';
import { updateTwoWayCommunication } from '../../actions/auth';

const TwoWayCommunicationToggle = ({
  auth: { user },
  updateTwoWayCommunication
}) => {
  const [twoWay, setTwoWay] = useState(true);

  useEffect(() => {
    setTwoWay(user?.settings.twowaycommunication);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function toggleTwoWayComms() {
    setTwoWay(!twoWay);
  }

  function saveTwoWayChange() {
    updateTwoWayCommunication(twoWay);
  }

  return (
    <div>
      <h4 style={{ fontSize: '1.5em' }}>Two-Way Communication</h4>
      <hr />
      <p>
        {user.subscriptionCreation === 'agency' ? (
          <>
            <h4 style={{ fontSize: '1.2em', marginTop: '0' }}>
              {user?.settings.twowaycommunication === true ? (
                <div style={{ color: '#F26347' }}> On</div>
              ) : (
                <div style={{ color: '#F26347' }}>Off</div>
              )}
            </h4>
            <hr />
            <span>
              Note: If Two-Way Communication is on, the Birth Parent will be
              able to “Like” and comment on your posts and share their own posts
              with you. If Two-Way communication is off, the Birth Parent will
              be able to “Like” your posts, but will not be able to leave
              comments or share their own posts with you.
            </span>
            <hr />
            <span>
              To request changes to this setting, please contact your Adoption
              Professional.
            </span>
          </>
        ) : (
          <>
            <span>
              Note: If Two-Way Communication is on, the Birth Parent will be
              able to “Like” and comment on your posts and share their own posts
              with you. If Two-Way communication is off, the Birth Parent will
              be able to “Like” your posts, but will not be able to leave
              comments or share their own posts with you.
            </span>
            <hr />
            <ToggleSwitch
              theme="default"
              className="d-flex"
              enabled={user?.settings.twowaycommunication}
              onStateChanged={toggleTwoWayComms}
            />
          </>
        )}
        {twoWay !== user?.settings.twowaycommunication ? (
          <Button className="btn-ac-green" onClick={saveTwoWayChange}>
            Save
          </Button>
        ) : null}
      </p>
    </div>
  );
};

TwoWayCommunicationToggle.propTypes = {
  auth: PropTypes.object.isRequired,
  updateTwoWayCommunication: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps, {
  updateTwoWayCommunication
})(withRouter(TwoWayCommunicationToggle));
