import axios from 'axios';
import { GET_NOTIFICATIONS } from './types';
import { API_URL } from '../config/default.json';

export const getNotifications = () => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}/notifications`);
    console.log(res);
    dispatch({
      type: GET_NOTIFICATIONS,
      payload: res.data
    });
  } catch (err) {
    console.log('ERROR', err);
  }
};

export const deleteNotification = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(`${API_URL}/notifications/${id}`);
    dispatch({
      type: GET_NOTIFICATIONS,
      payload: res.data
    });
  } catch (err) {
    console.log('ERROR', err);
  }
};

export const markNotificationsAsRead = () => async (dispatch) => {
  try {
    const res = await axios.post(`${API_URL}/notifications/markallread`);
    dispatch({
      type: GET_NOTIFICATIONS,
      payload: res.data
    });
  } catch (err) {
    console.log('ERROR', err);
  }
};

export const markNotificationAsRead = (id) => async (dispatch) => {
  try {
    const res = await axios.put(`${API_URL}/notifications/markasread/${id}`);
    dispatch({
      type: GET_NOTIFICATIONS,
      payload: res.data
    });
  } catch (err) {
    console.log('ERROR', err);
  }
};
