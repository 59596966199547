import axios from 'axios';
import {
  MEDIA_UPLOADED,
  UPDATE_UPLOADED_MEDIA,
  UPDATE_UPLOADING_PROGRESS,
  MEDIA_ERROR,
  DELETE_MEDIA
} from './types';
import { API_URL } from '../config/default.json';

export const resetProgress = () => async (dispatch) => {
  dispatch({
    type: UPDATE_UPLOADING_PROGRESS,
    payload: undefined
  });
};

export const uploadMedia = (files, tags) => async (dispatch) => {
  try {
    const reqs = [];
    const loadingItems = [];
    const totalItems = [];

    if (Array.isArray(files)) {
      files.forEach((file, i) => {
        const data = new FormData();
        data.append('file', file);
        tags && data.append('tags', tags);
        // data.append('return_delete_token', true);
        data.append('upload_preset', 'v73jjwfz');
        data.append('timestamp', (Date.now() / 1000) | 0);

        const req = axios({
          url: 'https://api.cloudinary.com/v1_1/puget-sound-networks/upload',
          method: 'POST',
          data,
          transformRequest: [
            function (data, headers) {
              delete headers.common['x-auth-token'];
              return data;
            }
          ],
          headers: {},
          onUploadProgress: (prog) => {
            const { loaded, total } = prog;
            loadingItems[i] = loaded;
            totalItems[i] = total;
            const progress =
              loadingItems.reduce((curr, val) => curr + val, 0) /
              totalItems.reduce((curr, val) => curr + val, 0);
            dispatch({
              type: UPDATE_UPLOADING_PROGRESS,
              payload: progress * 100
            });
          }
        });
        reqs.push(req);
      });

      return axios
        .all(reqs)
        .then(axios.spread((...images) => images.map(({ data }) => data)));

      // const res = await axios.all(reqs);
      // // dispatch({
      // //   type: MEDIA_UPLOADED,
      // //   payload: res.data
      // // });
      // console.log('RES:', res.data);
      // return res.data;
    } else {
      const data = new FormData();
      data.append('file', files);
      data.append('upload_preset', 'v73jjwfz');
      data.append('timestamp', (Date.now() / 1000) | 0);
      tags && data.append('tags', tags);

      const res = await axios({
        url: 'https://api.cloudinary.com/v1_1/puget-sound-networks/upload',
        method: 'POST',
        data,
        transformRequest: [
          function (data, headers) {
            delete headers.common['x-auth-token'];
            return data;
          }
        ],
        onUploadProgress: ({ loaded, total }) => {
          dispatch({
            type: UPDATE_UPLOADING_PROGRESS,
            payload: (loaded / total) * 100
          });
        }
      });
      dispatch({
        type: MEDIA_UPLOADED,
        payload: res.data
      });
      return res.data.secure_url;
    }
  } catch (err) {
    dispatch({
      type: MEDIA_ERROR,
      payload: {
        msg: err.message,
        status: err.name
      }
    });
  }
};

export const updateUploadedMedia = (file) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_UPLOADED_MEDIA,
      uploadedMedia: file
    });
  } catch (err) {
    dispatch({
      type: MEDIA_ERROR,
      payload: {
        msg: err.message,
        status: err.name
      }
    });
  }
};

// takes a single publicId or array of publicIds
export const deleteMedia = (publicIds) => async (dispatch) => {
  try {
    await axios.post(`${API_URL}/post/deletemedia`, { publicIds });
    dispatch({
      type: DELETE_MEDIA,
      payload: publicIds
    });
  } catch (err) {
    console.log('ERROR:', err);
    dispatch({
      type: MEDIA_ERROR,
      payload: {
        msg: err.message,
        status: err.name
      }
    });
  }
};
