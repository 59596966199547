import React, { Fragment, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Col, Button, ModalHeader, ModalBody, Modal, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

import hiwBackground from '../../assets/img/hiwBackground.png';
import hiw1 from '../../assets/img/hiwap1.png';
import hiw2 from '../../assets/img/hiwap2.png';
import hiw3 from '../../assets/img/hiwap3.png';
import hiw4 from '../../assets/img/hiwap4.png';
import NewsletterSignup from '../forms/newsletterSignup';

const HiwAdoptiveParent = (pathname) => {
  const [modalStatus, setModalStatus] = useState(false);

  function toggle() {
    setModalStatus(!modalStatus);
  }

  var mainSectionStyle = {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${hiwBackground})`
  };

  return (
    <Fragment>
      <Helmet>
        <title>Adopt Connect - How It Works - Adoptive Parent</title>
      </Helmet>
      <Modal
        isOpen={modalStatus}
        toggle={toggle}
        style={{ maxWidth: '1200px' }}
      >
        <ModalHeader toggle={toggle}>Timeline Preview</ModalHeader>
        <ModalBody>
          <img
            src="https://adoptconnect.b-cdn.net/sampleTimeline.gif"
            alt="Timeline Preview"
            width="100%"
          />
        </ModalBody>
      </Modal>
      <div style={mainSectionStyle} className="banner">
        <h1 className="banner banneroverlay">How it Works</h1>
      </div>
      <section className="container sec-pad-adjust">
        <h4 className="panel-header">
          AdoptConnect makes it easy for adoptive parents and birth parents to
          maintain secure, worry-free communication.
        </h4>
        <div
          className="raleway ac-orange"
          style={{ width: '100%', fontSize: '2.3em' }}
        >
          Straightforward Pricing
        </div>
        <div className="row">
          <Col sm="12" md="6">
            <center>
              <h3 className="panel-header">
                Individual Accounts
                <br /> $78/yr
              </h3>
            </center>
          </Col>
          <Col sm="12" md="6">
            <center>
              <h3 className="panel-header" style={{ paddingBottom: '0px' }}>
                Professional-Linked Accounts <br />
                $59/yr*
              </h3>
              <p
                style={{
                  fontSize: '1.3em',
                  width: '70%',
                  textAlign: 'center',
                  paddingTop: '0px'
                }}
              >
                *for accounts initiated by Adoption Professional
              </p>
            </center>
          </Col>
          <Col md="12" xs="auto">
            <h4
              className="ac-text"
              style={{ fontSize: '2.3em', textAlign: 'left' }}
            >
              Adoptive Parents’ subscription renews automatically each year.
              AdoptConnect is always free for Birth Parents and Adoption
              Professionals
            </h4>
          </Col>
          <Col md="12" xs="auto">
            <h4
              className="panel-header"
              style={{ fontSize: '2.3em', textAlign: 'left' }}
            >
              Features:
            </h4>
            <ul>
              <li className="ac-text">
                No Set Up or Cancellation Fees. No Contracts.
              </li>
              <li className="ac-text">Unlimited photos, videos, and updates</li>
              <li className="ac-text">
                Multiple Connections: Invite additional family members (coming
                soon!).
              </li>
              <li className="ac-text">
                24/7 Adoption-Informed Customer Service
              </li>
              <li className="ac-text">
                Social Impact: Gives back to adoption-related non-profits
              </li>
            </ul>
            <center>
              <Link to="/register/annualMember">
                <Button
                  color="#47604e"
                  className="ralewayBold"
                  style={{
                    backgroundColor: 'rgb(231, 88, 51)',
                    color: 'white',
                    width: '300px',
                    height: '60px',
                    fontSize: '3.4em',
                    border: '4px solid #F6916C',
                    borderRadius: '10px',
                    padding: '0px 20px'
                  }}
                >
                  <div style={{ letterSpacing: '3px' }}>SIGN UP</div>
                </Button>
              </Link>
            </center>
          </Col>
        </div>
        <h4 className="raleway ac-orange" style={{ fontSize: '2.3em' }}>
          Simple Set-Up
        </h4>

        <div className="row">
          <Col className="hiwAPcol" md="6" xs="6" sm="4">
            <img className="hiwAPImg" src={hiw1} alt=""></img>
          </Col>
          <Col
            className="hiwAPcol hiwColText ac-text d-flex"
            md="6"
            xs="6"
            sm="4"
          >
            Adoptive Parents confirm contact information, choose a password and
            set up Reminders. AdoptConnect reminds adoptive parents when their
            updates are coming due.
          </Col>
          <Col
            className="hiwAPcol hiwColText ac-text d-flex"
            md="6"
            xs="6"
            sm="4"
          >
            Adoptive Parents are billed annually until their child turns 18,
            unless they choose to cancel earlier.
          </Col>
          <Col className="hiwAPcol" md="6" xs="6" sm="4">
            <img className="hiwAPImg" src={hiw2} alt=""></img>
          </Col>
          <Col className="hiwAPcol" md="6" xs="6" sm="4">
            <img className="hiwAPImg" src={hiw3} alt=""></img>
          </Col>
          <Col
            className="hiwAPcol hiwColText ac-text d-flex"
            md="6"
            xs="6"
            sm="4"
          >
            AdoptConnect sends the Birth Parent an invitation to create an
            account and notifies the Adoptive Parents as soon as all accounts
            are verified and linked.{' '}
          </Col>
          <Col
            className="hiwAPcol hiwColText ac-text d-flex"
            md="6"
            xs="6"
            sm="4"
          >
            Adoptive Parents post unlimited photos, videos and updates to share
            with their child’s Birth Parents.
          </Col>
          <Col className="hiwAPcol" md="6" xs="6" sm="4">
            <img className="hiwAPImg" src={hiw4} alt=""></img>
          </Col>
        </div>
        <center>
          <Button onClick={toggle} className="btn-ac-green btn-preview-popup">
            View Sample AdoptConnect Timeline
          </Button>
        </center>
        <Col md="12" xs="auto">
          <h4
            className="panel-header"
            style={{ fontSize: '2.3em', lineHeight: '1.3em' }}
          >
            “We started out using Facebook to share updates with our childrens'
            birth parents, but worried about maintaining privacy. AdoptConnect
            was the perfect solution.”
          </h4>
          <h4
            className="panel-header-2"
            style={{ fontSize: '2em', color: '#3a3e47', paddingTop: 0 }}
          >
            - Jen + Robert, Adoptive Parents
          </h4>
        </Col>
        <h4 className="panel-header-2" style={{ marginBottom: '0' }}>
          Ready to Connect?
        </h4>
        <center>
          <Link to="/register/annualmember">
            <Button
              color="#47604e"
              className="ralewayBold"
              style={{
                backgroundColor: 'rgb(231, 88, 51)',
                color: 'white',
                width: '300px',
                height: '60px',
                fontSize: '3.4em',
                border: '4px solid #F6916C',
                borderRadius: '10px',
                padding: '0px 20px'
              }}
            >
              <div style={{ letterSpacing: '3px' }}>SIGN UP</div>
            </Button>
          </Link>
        </center>
        <Row style={{ border: '1px solid black', margin: '4em 2em 2em 2em' }}>
          <Col md="9" style={{ paddingLeft: '10px' }}>
            <center>
              <h4
                className="panel-header-3 text-left"
                style={{ paddingTop: '0px', paddingBottom: '0px' }}
              >
                Share AdoptConnect!
              </h4>
              <p
                className="ac-text text-left"
                style={{ paddingBottom: '10px' }}
              >
                Use this link to tell your Adoption Professional about
                AdoptConnect.
              </p>
            </center>
          </Col>
          <Col md="3">
            <a href="mailto:?subject=Check%20out%20AdoptConnect!&amp;body=Hello!%20I%20wanted%20to%20let%20you%20know%20about%20this%20online%20platform%20called%20AdoptConnect%20that%20helps%20adoptive%20parents%20and%20birth%20parents%20stay%20connected%20after%20adoption.%20Instead%20of%20posting%20updates%20on%20social%20media%2C%20sending%20them%20through%20an%20agency%20or%20in%20the%20mail%2C%20adoptive%20parents%20can%20now%20safely%20and%20easily%20share%20photos%2C%20video%2C%20and%20written%20updates%20with%20their%20child%27s%20birth%20parents%20on%20AdoptConnect.%20Find%20Out%20More%20-%20www.adoptconnect.com">
              <Button
                rounded
                color="#47604e"
                className="ralewayBold btn-share btn-center"
                style={{
                  backgroundColor: '#56786c',
                  color: 'white',
                  width: '200px'
                }}
              >
                Share
              </Button>
            </a>
          </Col>
        </Row>
      </section>
      <div style={{ margin: '60px 0px 0px 0px' }}>
        <hr width="60%" />
        <NewsletterSignup />
      </div>
    </Fragment>
  );
};

HiwAdoptiveParent.propTypes = {};

export default HiwAdoptiveParent;
