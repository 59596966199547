import {
    GET_ITEMS,
    ITEM_ERROR
} from '../actions/types'

const initialState = {
    items: [],
    loading: true,
    error: {}
}

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case GET_ITEMS:
            return {
                ...state,
                items: payload,
                loading: false
            };
        case ITEM_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            };
        default: 
            return state;
            }
}