import {
  GET_USER_EVENTS,
  GET_CONNECTION_EVENTS,
  GET_EVENTS,
  GET_EVENT,
  CREATE_EVENT,
  DELETE_EVENT,
  OPEN_EVENT_MODAL,
  CLOSE_EVENT_MODAL,
  OPEN_EDIT_EVENT_MODAL,
  CLOSE_EDIT_EVENT_MODAL
} from '../actions/types';

const initialState = {
  loading: true,
  userEventsLoading: true,
  connectionEventsLoading: true,
  userEvents: [],
  connectionEvents: [],
  events: [],
  event: [],
  modals: {
    eventModal: false,
    editEventModal: false
  }
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_EVENTS:
      return {
        ...state,
        loading: false,
        events: payload
      };
    case GET_EVENT:
      return {
        ...state,
        loading: false,
        event: payload
      };
    case CREATE_EVENT:
    case GET_USER_EVENTS:
      return {
        ...state,
        userEventsLoading: false,
        userEvents: payload
      };
    case GET_CONNECTION_EVENTS:
      return {
        ...state,
        connectionEventsLoading: false,
        connectionEvents: payload
      };
    case OPEN_EDIT_EVENT_MODAL:
      return {
        modals: {
          editEventModal: true
        }
      };
    case CLOSE_EDIT_EVENT_MODAL:
      return {
        modals: {
          editEventModal: false
        }
      };
    case OPEN_EVENT_MODAL:
      return {
        ...state,
        modals: {
          eventModal: true
        }
      };
    case CLOSE_EVENT_MODAL:
      return {
        ...state,
        modals: {
          eventModal: false
        }
      };
    case DELETE_EVENT:
      return {
        ...state,
        loading: false,
        connectionEvents: state.connectionEvents.filter(
          ({ _id }) => _id !== payload
        ),
        userEvents: state.userEvents.filter(({ _id }) => _id !== payload),
        events: state.events.filter(({ _id }) => _id !== payload)
      };
    default:
      return state;
  }
}
