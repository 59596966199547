import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import notificationItem from './notificationItem';
import { Container, Card, Button } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import { markNotificationsAsRead } from '../../actions/notifications';

const Notifications = ({
  auth,
  notifications: { notifications, notificationsLoading, unread },
  markNotificationsAsRead
}) => {
  return (
    <Container style={isMobile ? {} : { width: '60%' }}>
      <Card style={{ padding: '20px' }}>
        <div style={{ marginBottom: '4.5em' }}>
          <Button
            color="link"
            className="right"
            onClick={(e) => markNotificationsAsRead()}
          >
            <u>Mark all as read</u>
          </Button>
        </div>
        <hr />
        {notifications.map((e) => notificationItem(e))}
      </Card>
    </Container>
  );
};

Notifications.propTypes = {
  notifications: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  markNotificationsAsRead: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  notifications: state.notifications
});

export default connect(mapStateToProps, { markNotificationsAsRead })(
  Notifications
);
