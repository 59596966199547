import React from 'react';
import { useDispatch } from 'react-redux';
import { useIsMounted } from './useIsMounted';
import { getInvitation } from '../actions/auth';
import setAuthToken from '../utils/setAuthToken';
import { logout } from '../actions/auth';
import { USER_LOADED } from '../actions/types';

/**
 * Account setup from email click:
 *  1. GET /user/invitation/:inviteId
 *  2. Create user with inviteId as password, requirePasswordChange = true
 *  3. Mark invite as completed
 *  4. Login and send JWT
 *  5. Forward to pw change page with inviteId as prefilled password
 *  5a. Make sure requirePasswordChange = true users are redirected to change pw page
 *  6. Allow user to update password
 *  7. Send to create profile?
 */

export const confirmUser = async (inviteId, hash) => {
  const { token, user } = await getInvitation(inviteId, hash);
  if (token) {
    setAuthToken(token);
    localStorage.setItem('token', token);
    return user;
  }
  throw new Error('Invalid token.');
};

export const useConfirmUser = (id, hash) => {
  const isMounted = useIsMounted();
  const dispatch = useDispatch();
  const [error, setError] = React.useState();
  const [redirect, setRedirect] = React.useState();

  const confirm = async () => {
    try {
      const user = await confirmUser(id, hash);
      dispatch({
        type: USER_LOADED,
        payload: user
      });
      isMounted && setRedirect(`/account/password/`);
    } catch (e) {
      console.log('ERROR IN HOOK', e, e.response);
      if (isMounted) {
        setError(e);
        logout();
        setRedirect('/login');
      }
    }
  };

  return { redirect, error, confirm };
};
