import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Modal, ModalBody, Spinner } from 'reactstrap';
import { useWait, Waiter } from 'react-wait';

const PrivateRoute = ({
  auth: { isAuthenticated, user, loading },
  profile: { profile, profileLoading, profileComplete },
  ...rest
}) => {
  const location = useLocation();

  const { isWaiting } = useWait();

  if (!isAuthenticated) {
    return <Redirect to="/" />;
  }
  if (isWaiting('creating user')) {
    return (
      <Modal isOpen={true}>
        <ModalBody>
          <h3>Creating Account</h3>
          <Spinner />
        </ModalBody>
      </Modal>
    );
  }
  if (
    isAuthenticated &&
    !loading &&
    location.pathname !== '/account/password' &&
    user?.requirePasswordChange
  ) {
    return <Redirect to="/account/password" />;
  }
  if (
    isAuthenticated &&
    !loading &&
    location.pathname !== '/account/payment' &&
    location.pathname !== '/account/password' &&
    user?.requirePayment
  ) {
    return <Redirect to="/account/payment" />;
  }
  console.log(`${profileComplete}`);
  if (
    isAuthenticated &&
    !loading &&
    !profileLoading &&
    location.pathname !== '/account/payment' &&
    location.pathname !== '/account/password' &&
    location.pathname !== '/create-profile' &&
    profileComplete === false
  ) {
    return <Redirect to="/create-profile" />;
  }
  if (
    isAuthenticated &&
    !loading &&
    !profileLoading &&
    location.pathname !== '/account/payment' &&
    location.pathname !== '/account/password' &&
    location.pathname !== '/create-profile' &&
    profileComplete === false
  ) {
    return <Redirect to="/create-profile" />;
  }
  if (
    isAuthenticated &&
    !loading &&
    !user.confirmed &&
    location.pathname !== '/account/payment' &&
    location.pathname !== '/account/password' &&
    location.pathname !== '/create-profile' &&
    location.pathname !== '/confirmemail'
  ) {
    return <Redirect to="/confirmemail" />;
  }
  if (
    isAuthenticated &&
    !loading &&
    user.confirmed &&
    profile !== null &&
    location.pathname !== '/account/payment' &&
    location.pathname !== '/account/password' &&
    location.pathname !== '/create-profile' &&
    location.pathname === '/confirmemail'
  ) {
    return <Redirect to="/timeline" />;
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuthenticated && !loading ? (
          <Redirect to={'/login?nxtpg=' + window.location.pathname} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  component: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile
});

export default connect(mapStateToProps)(PrivateRoute);
