/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { DropdownItem } from 'reactstrap';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import {
  markNotificationsAsRead,
  markNotificationAsRead
} from '../../actions/notifications';

export const NotificationItem = ({ item, markNotificationAsRead }) => {
  function notificationString() {
    if (item.type === 'post') {
      return `${item.creator.name} posted an update`;
    }
    if (item.type === 'document') {
      return `${item.creator.name} uploaded a new document`;
    }
  }

  return (
    <Link
      style={{ color: 'white !important' }}
      to={`/${item.type}/${item.object}`}
      key={item.object}
      onClick={(e) => markNotificationAsRead(item.id)}
    >
      <DropdownItem
        className="nav-dropdown-link"
        style={{ paddingRight: '2rem' }}
      >
        <div className="d-inline-flex post-user-info">
          <div className="circular">
            <img
              className="post-profile-pic"
              src={item.creator.profileImage}
              alt="profile"
            ></img>
          </div>
          <h6 className="post-user-name">{notificationString()}</h6>
          {item.read === false ? (
            <span className="badge badge-danger ml-2">new</span>
          ) : null}
        </div>

        <div style={{ marginLeft: '14px' }}>
          {<Moment fromNow>{item.createdAt}</Moment>}
        </div>
      </DropdownItem>
    </Link>
  );
};

NotificationItem.propTypes = {
  item: PropTypes.object.isRequired,
  markNotificationAsRead: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  notifications: state.notifications
});

export default connect(mapStateToProps, { markNotificationAsRead })(
  NotificationItem
);
