import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

import whoWeSupportBanner from '../../assets/img/whoWeSupportBanner2.png';
import whoWeSupportBannerOverlay from '../../assets/img/whoWeSupportBannerOverlay.png';
// import statesLaws from '../../assets/img/states-laws.png';

const whoWeSupport = () => {
  var mainSectionStyle = {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${whoWeSupportBanner})`
  };

  return (
    <Fragment>
      <Helmet>
        <title>Adopt Connect - Who We Support</title>
      </Helmet>
      <div style={mainSectionStyle} className="banner">
        <h1 className="banner">
          <img
            className="banneroverlay"
            src={whoWeSupportBannerOverlay}
            width="20%"
            alt="Who We Support Banner Overlay"
          ></img>
        </h1>
      </div>
      <section className="container sec-pad-adjust">
        <div className="spacer"></div>
        <hr></hr>
        <center>
          <h3>
            <b>THE ADOPTCONNECT MARKETPLACE BENEFICIARIES</b>
          </h3>
          <hr></hr>
          <h3 className="gi-paragraph-heading bold">Shop With Purpose</h3>
          <p className="gi-paragraph-text">
            A portion of all AdoptConnect Marketplace proceeds will be donated
            to organizations that are doing an amazing job of providing support
            to adoptive families and birth parents. Read below and learn more
            about each of them and their positive impact on the adoption
            community. You can join in their efforts by shopping the
            AdoptConnect Marketplace now (yes, it’s that easy!).
          </p>
          <Link to="/marketplace">
            <Button className="ac-button">Shop the Marketplace</Button>
          </Link>
        </center>
      </section>
    </Fragment>
  );
};

whoWeSupport.propTypes = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(whoWeSupport);
