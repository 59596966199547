import React, { Fragment } from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';

const notificationItem = (item) => {
  function notificationString() {
    if (item.type === 'post') {
      return `${item.creator.name} posted an update`;
    }
    if (item.type === 'document') {
      return `${item.creator.name} uploaded a new document`;
    }
  }

  return (
    <Fragment>
      <Link to={`/${item.type}/${item.object}`}>
        <div className="media">
          <div className="avatar" style={{ marginRight: '0' }}>
            <img
              alt={`${item.creator.name} profile`}
              src={item.creator.profileImage}
              className="media-object"
              style={{ verticalAlign: 'middle', width: '40px' }}
            />
          </div>
          <div className="media-body">
            <h5 className="media-heading">{notificationString()}</h5>
            <small className="text-muted" style={{ fontSize: '1em' }}>
              <Moment fromNow>{item.createdAt}</Moment>
            </small>
          </div>
        </div>
      </Link>
      <hr />
    </Fragment>
  );
};

export default notificationItem;
