import React from 'react';

const termsAndConditions = (props) => {
  return (
    <div>
      <section className="container sec-pad-adjust">
        <h4>Terms of Service</h4>
        <hr />
        <p>
          LAST UPDATED: April 26, 2020.
          <br />
          These Terms of Use contain the legal terms and conditions that govern
          your use of and access to AdoptConnect websites, (&ldquo;Site) as well
          as our provision of products and services. Certain services,
          promotions and features may have additional terms and conditions, and
          those additional terms and conditions are incorporated herein by
          reference. By registering with our Site, you are signifying your
          assent to these Terms and our Privacy Policy, which is incorporated
          herein by reference.
          <br />
          Any services used through our Site are also governed by these Terms.
          We may revise these Terms from time to time by posting a revised
          version. YOUR CONTINUED USE OF OUR SITE AFTER WE POST ANY CHANGES WILL
          CONSTITUTE YOUR ACCEPTANCE OF SUCH CHANGES. IN ADDITION, BY USING OUR
          SERVICES, YOU ACKNOWLEDGE THAT YOU HAVE READ AND REVIEWED THESE TERMS
          IN THEIR ENTIRETY, YOU AGREE TO THESE TERMS AND THE PRIVACY POLICY AND
          THESE TERMS CONSTITUTE BINDING AND ENFORCEABLE OBLIGATIONS ON YOU.
          NOTE: THIS TERMS OF USE CONTAINS AN ARBITRATION CLAUSE AND CLASS
          ACTION WAIVER PROVISION IN THE &ldquo;ARBITRATION&rdquo; SECTION BELOW
          THAT AFFECTS YOUR RIGHTS UNDER THIS TERMS OF USE WITH RESPECT TO ANY
          DISPUTE BETWEEN YOU AND US OR OUR AFFILIATES.
          <br />
          1. YOUR USE OF SITE
          <br />
          Individual users may access and use Site solely for your personal,
          noncommercial use. Adoption Professional (&ldquo;Partner&rdquo;) users
          may access and use Site solely for the purpose of facilitating
          communication for their own clients and not for any kind of public or
          commercial use. Except as expressly authorized hereunder, Site may not
          be reproduced, duplicated, copied, sold, resold, visited,
          reverse-engineered or otherwise exploited for any commercial purpose
          without our prior written authorization. AdoptConnect reserves the
          right to alter or discontinue Site, in whole or in part, at any time
          in our sole discretion.
          <br />
          Subject to and conditioned upon your compliance with these Terms, we
          grant to you a non-exclusive, non-transferable, limited right and
          license, without right of sublicense, to access and use Site,
          including any images, text, graphics, sounds, data, links and other
          materials incorporated into Site (other than User Submitted Materials
          as defined below), solely as made available by us and solely for your
          own personal purposes, or, in the case of Partners, professional
          purposes. Except as expressly authorized by these Terms, you may not
          use, reproduce, distribute, modify, transmit or publicly display any
          portion of Site or create derivative works of any portion of Site
          without our written consent. While using any of our Site, you agree
          not to:
          <br />
          &middot; Defame, abuse, harass, stalk, threaten or otherwise violate
          the rights of others, including, without limitation, others&rsquo;
          privacy rights or rights of publicity;
          <br />
          &middot; Impersonate any person or entity or use any fraudulent,
          misleading or inaccurate email address or other contact information;
          <br />
          &middot; Restrict or inhibit any other user from using any of our
          Site, including, without limitation, by means of &ldquo;hacking&rdquo;
          or defacing any portion our Site;
          <br />
          &middot; Violate any applicable laws or regulations;
          <br />
          &middot; Upload to, transmit through, or display on any of Site (a)
          any material that is unlawful, fraudulent, threatening, abusive,
          libelous, defamatory, obscene or otherwise objectionable, or infringes
          our or any third party&rsquo;s intellectual property or other rights;
          (b) any confidential, proprietary or trade secret information of any
          third party; or (c) any advertisements, solicitations, chain letters,
          pyramid schemes, investment opportunities or other unsolicited
          commercial communication (except as otherwise expressly permitted by
          us);
          <br />
          &middot; Engage in spamming;
          <br />
          &middot; Transmit any software or other materials that contain any
          viruses, worms, Trojan horses, defects, or other destructive items;
          <br />
          &middot; Modify, adapt, translate, distribute, reverse engineer,
          decompile or disassemble any portion of Site;
          <br />
          &middot; Remove any copyright, trademark or other proprietary rights
          notices contained in or displayed on any portion of Site.
          <br />
          &middot; Display adult nudity or inappropriate child nudity.
          <br />
          &middot; Post objectionable material, such as material containing
          illegal or malicious content or offers for adult services, or material
          inciting or advocating terrorism or violence.
          <br />
          If you fail to comply with the above rules, such failure will
          constitute a violation of these Terms, and in addition to any other
          rights or remedies we may have, we may immediately terminate your
          access to and use of Site and we may remove the violating content from
          Site.
          <br />
          <br />
          You will not share your password or sign-in information with anyone,
          and you must protect the security of your account and your password.
          You are solely responsible for any activity associated with your
          account.
          <br />
          Any information or content publicly posted or privately transmitted
          through Site is the sole responsibility of the person from whom such
          content originated, and you access all such information and content at
          your own risk, and AdoptConnect isn&rsquo;t liable for any errors or
          omissions in that information or content or for any damages or loss
          you might suffer in connection with it. We cannot control and have no
          duty to take any action regarding how you may interpret and use the
          Content or what actions you may take as a result of having been
          exposed to the Content, and you hereby release us from all liability
          for you having acquired or not acquired Content through the Site. We
          can&rsquo;t guarantee the identity of any users with whom you interact
          in using the Site and are not responsible for which users gain access
          to the Site. It is your responsibility to make sure that all invites
          you send to your account are to the intended user. Agency and
          Professional &ldquo;Partners&rdquo; who create accounts for users are
          responsible to verify that they have the correct email addresses and
          other contact information for users. AdoptConnect has no
          responsibility for misdirected email invitations resulting from the
          incorrect entry of contact information.
          <br />
          You are responsible for all Content you contribute, in any manner, to
          the Site, and you represent and warrant you have all rights necessary
          to do so, in the manner in which you contribute it. You will keep all
          your registration information accurate and current. You are
          responsible for all your activity in connection with the Site.
          <br />
          Some features of Site may enable you to send and receive
          transmissions. You acknowledge that we have no responsibility or
          liability for any transmissions, and/or any content included in such
          transmissions, sent or received by you.
          <br />
          2. YOUR MEMBER ACCOUNT
          <br />
          Individual users may create a member account with Site by registering
          name, providing certain personal information, and creating a password.
          You agree that you will provide complete, current and accurate
          information about yourself as requested, and that you will keep that
          information up to date. You are responsible for safeguarding the
          confidentiality of your username and password that you use to access
          your member account on our Site. You agree not to disclose your
          username or password to any third party, including your Adoption
          Professional. YOU AGREE THAT YOU ARE SOLELY AND FULLY RESPONSIBLE FOR
          ANY ACTIVITIES OR ACTIONS TAKEN UNDER YOUR ACCOUNT, WHETHER OR NOT YOU
          HAVE AUTHORIZED SUCH ACTIVITIES OR ACTIONS. You will immediately
          notify us of any unauthorized use of your account on any of our Site.
          <br />
          <br />
          Adoption Professionals (&ldquo;Partners&rdquo; ) who create an account
          for their clients are responsible for keeping that account information
          confidential and secure and sharing it only with the intended users.
          &ldquo;Partners&rdquo; do not create passwords and should not attempt
          to access client passwords or accounts. <br />
          <br />
          In order to create a member account with any of our Sites, you must be
          at least 13 years of age. You represent to us that you are at least 13
          years old. If you are not, you are not authorized to set up an account
          on our Site.
          <br />
          3. MAKING PURCHASES
          <br />
          If you wish to purchase any products or services through our Site, we
          will ask you to supply certain information applicable to your
          purchase, including, and without limitation, payment and other
          information. Any such information will be treated as described in our
          Privacy Policy. All information that you provide to us or our third
          party payment processor must be accurate, current and complete. YOU
          REPRESENT AND WARRANT THAT YOU HAVE THE LEGAL RIGHT TO USE ANY CREDIT
          CARD(S) OR OTHER PAYMENT MEANS USED TO INITIATE ANY TRANSACTION. You
          agree to pay all charges incurred by you or any users of your account
          and credit card (or other applicable payment mechanism) at the prices
          in effect when such charges are incurred. You will also be responsible
          for paying any applicable taxes relating to your purchases.
          Verification of information applicable to a purchase may be required
          prior to our acceptance of any order.
          <br />
          Descriptions, images, references, features, content, specifications,
          products, price and availability of any products or services are
          subject to change without notice, and our current prices can be found
          on our Site. We make reasonable efforts to accurately display the
          attributes of our products, including the applicable colors; however,
          the actual color you see will depend on your computer system, and we
          cannot guarantee that your computer will accurately display such
          colors. The inclusion of any products or services on our Site at a
          particular time does not imply or warrant that these products or
          services will be available at any time. It is your responsibility to
          ascertain and obey all applicable local, state, federal and
          international laws (including minimum age requirements) in regard to
          the possession, use and sale of any item purchased through our Site.
          By placing an order, you represent that the products ordered will be
          used only in a lawful manner. We reserve the right, with or without
          prior notice, to limit the available quantity of or discontinue any
          product or service; to honor, or impose conditions on the honoring of,
          any coupon, coupon code, promotional code or other similar promotions;
          to bar any user from making any or all purchase(s); and/or to refuse
          to provide any user with any product or service.
          <br />
          4. ADOPTCONNECT: INDIVIDUAL PLAN
          <br />
          AdoptConnect&rsquo;s Individual Plan (&ldquo;Individual Plan&rdquo;)
          is a subscription-based program designed to enable customers to post
          and share photos, videos, messages, and updates with their connected
          account. <br />
          Subscribers to the Individual Plan are entitled to the benefits
          outlined below, subject to the requirements and limitations of these
          Terms. If you subscribe to the Individual Plan or use the Individual
          Plan, you accept these terms. Individual Plan allows for the storage
          and playback of video files (&ldquo;Video Content&rdquo;). Video
          sharing is intended for customary usage related sharing videos of the
          child and family events and video storage capacity may be limited to
          such usage. AdoptConnect reserves the right to limit Video Content to
          reasonable and intended use, in its discretion. In the event that you
          store, upload, or share video content in excess of intended use, you
          will be prompted to delete content to free up storage. If you do not
          delete sufficient video content, we reserve the right to suspend or
          terminate your account. <br />
          <br />
          AdoptConnect&rsquo;s Individual Plan includes the features outlined at
          adoptconnect.com/Howitworks.
          <br />
          <br />
          By enrolling in Individual Plan, you expressly allow us to charge a
          fee to the payment method (&ldquo;Payment Method&rdquo;) you selected
          during registration (or to a different Payment Method if you change
          your billing information) at the then-current rate, and any other
          charges or taxes that may apply in connection with your use of the
          service. As used in these Terms, &ldquo;billing&rdquo; will indicate
          either a charge or debit, as applicable, against your Payment Method.
          You will be billed at the start of your Individual membership and each
          year thereafter, unless and until you cancel your plan. In the event
          we are unable to charge an applicable renewal fee to your Payment
          Method for more than thirty (30) days after the beginning of a renewal
          term of your plan to Individual Plan, AdoptConnect reserves the right
          to terminate, suspend or restrict your membership in Individual Plan.
          We will inform you by email before automatically renewing your
          Individual Plan. Each Individual Plan will renew for an additional
          term at the end of the current term. Unless otherwise specified, the
          automatically renewed plan will be for the same term and will be at
          the same price and at the same service level as your prior plan. Each
          automatic renewal and corresponding payment will be processed on the
          first day of the new term. AdoptConnect may, in its sole discretion,
          and without notice modify the terms and conditions of the Individual
          Plan. We also reserve the right at any time to change the pricing
          and/or subscription fee as well as plan benefits offered such as
          storage capacity or features. Changes in the annual fee for Individual
          Plan will be applied for renewal periods; however, you will not be
          charged any new or different fees unless we obtain your prior
          agreement to pay such additional fees. From time to time, we may offer
          you promotions that provide a discount on Individual Plan or make
          Individual Plan free for a period of time. Depending on the terms of
          your promotional offer, you may be enrolled in an Individual Plan
          following the expiration of your promotional period unless you cancel
          prior to the end of the promotional period.
          <br />
          You must have a current account with us in order to use Individual
          Plan. AdoptConnect reserves the right to accept or reject your
          Individual Plan in our sole discretion. You may not transfer or assign
          your Individual Plan, allow third parties to use your Individual Plan,
          or use your Individual Plan to export videos on behalf of third
          parties. You agree that it is your sole responsibility to review
          updated terms and conditions of an Individual Plan posted by us at
          www.adoptconnect.com. If any such change is found to be unenforceable,
          void, invalid, it is severable and does not affect the validity and
          enforceability of any remaining changes or conditions. YOUR CONTINUED
          PARTICIPATION IN THE PROGRAM CONSTITUTES YOUR ACCEPTANCE OF ANY SUCH
          CHANGES. IF YOU DO NOT AGREE TO ANY SUCH CHANGES, YOU MUST CANCEL YOUR
          plan.
          <br />
          AdoptConnect reserves the right to terminate, suspend or restrict your
          membership and Individual Plan with or without notice, for any or no
          reason whatsoever. Without limiting the foregoing, AdoptConnect may
          terminate your Individual Plan if you violate the Terms or any
          applicable law, terms and conditions of any services offered by us, or
          if your use of our services or Individual is determined, in our
          discretion, to involve fraud or misuse. Failure to enforce any
          termination rights for any conduct shall not be deemed a waiver of any
          of our termination rights.
          <br />
          In the event of cancellation, expiration, downgrade or termination of
          your Individual Plan, you may be unable to access your Content and you
          may no longer be allowed to upload additional Content. You may also
          lose access to certain features or functionality only available to
          current subscribers.
          <br />
          IN THE EVENT OF CANCELLATION, EXPIRATION, DOWNGRADE, OR TERMINATION OF
          YOUR INDIVIDUAL PLAN, WE MAY ALSO DELETE SOME OR ALL OF YOUR USER
          SUBMITTED MATERIALS (CONTENT). IT IS YOUR RESPONSIBILITY TO BACK-UP
          YOUR CONTENT PRIOR TO THE CANCELLATION, EXPIRATION, DOWNGRADE OR
          TERMINATION. <br />
          5. ADOPTCONNECT PARTNER PLAN
          <br />
          AdoptConnect&rsquo;s Partner Plan (&ldquo;Partner Plan&rdquo;) is
          available to users working with agencies, attorneys, and other
          adoption professionals registered with AdoptConnect to manage
          post-adoption contact through AdoptConnect. Users registered through
          the partner plan enjoy the same benefits of membership as those
          enrolled in Individual and are bound by all of the terms and
          conditions described in item four (4) above, (&ldquo;AdoptConnect
          Individual Plan&rdquo;), as well as the other terms and conditions
          herein. The user, not the adoption professional, is solely responsible
          for all payment and fees associated with their account and will input
          the payment information themselves. The user, not the adoption
          professional, is responsible for keeping payment information current,
          as detailed in Item 4, above. Partner differs from Individual Plan
          only to the extent that the Partner Plan adds limited agency oversight
          to the connection created by plan to AdoptConnect defined as follows:{' '}
          <br />
          <br />
          AdoptConnect&rsquo;s Partner Plan includes the features outlined at
          adoptconnect.com/howitworks.
          <br />
          When subscribing to Partner Plan, users agree that the adoption
          professional will be responsible for initiation of user&rsquo;s
          account setup and designation of email addresses for all parties,
          including birth parents and adoptive parents. Users also agree that
          the adoption professional will have access to checking whether posts
          have been made, including the date and time of the posts, and viewing
          comments on the posts, but not the content of the posts. The adoption
          professional may also receive notifications if adoptive parent users
          fail to post updates in a timely manner. Adoption Professionals will
          receive an email notification in the event of Plan cancellation,
          termination, or downgrade for any reason. <br />
          <br />
          Adoption professionals utilizing Site are bound by the Terms and
          Conditions herein, as well as AdoptConnect&rsquo;s Privacy Policy.{' '}
          <br />
          <br />
          <br />
          6. THIRD-PARTY SITES, SOFTWARE AND SERVICES
          <br />
          Our Site may direct you to Sites, software or services owned or
          operated by third parties (&ldquo;Third Party Properties&rdquo;). We
          have not reviewed all of the Third Party Properties to which you may
          be directed and we have no control over such Third Party Properties.
          We have no control over and are not responsible for (a) the content
          and operation of such Third Party Properties, or (b) the privacy or
          other practices of such Third Party Properties. The fact that our Site
          directs you to such Third Party Properties does not indicate any
          approval or endorsement of any such Third Party Properties. We direct
          you to such Third Party Properties only as a convenience. You are
          responsible for the costs associated with such Third Party Properties,
          including any applicable license fees and service charges.
          Accordingly, we strongly encourage you to become familiar with the
          terms of use and practices of any such Third Party Properties.
          <br />
          Other Sites may provide links to our Site with or without our
          authorization. We do not endorse such Sites, and are not and will not
          be responsible or liable for any links from those Sites to our Site,
          any content, advertising, products or other materials available on or
          through such other Sites, or any loss or damages incurred in
          connection therewith.
          <br />
          YOU AGREE THAT YOUR USE OF THIRD PARTY SITES, SOFTWARE AND SERVICES
          INCLUDING, WITHOUT LIMITATION, YOUR USE OF ANY CONTENT, INFORMATION,
          DATA, ADVERTISING, PRODUCTS, OR OTHER MATERIALS ON OR AVAILABLE
          THROUGH SUCH SITES, SOFTWARE AND SERVICES, IS AT YOUR OWN RISK AND IS
          SUBJECT TO THE TERMS AND CONDITIONS OF USE APPLICABLE TO SUCH SITES,
          SOFTWARE AND SERVICES.
          <br />
          We will have the right, at any time and in our sole discretion, to
          block links to our Site through technological or other means without
          prior notice.
          <br />
          7. MATERIALS SUBMITTED BY USER
          <br />
          Any materials submitted by user, including, without limitation,
          photographs, videos, images, text, graphics and other materials
          (collectively, &ldquo;User Submitted Materials&rdquo;) are subject to
          the following terms and conditions:
          <br />
          &middot; User wholly retains ownership of such User Submitted
          Materials, and you grant us and our designees a worldwide,
          non-exclusive, transferable, royalty-free, perpetual irrevocable right
          and license, to use, reproduce, transmit, such User Submitted
          Materials solely in connection with the provision of any product or
          service you request; specifically, for the sole purpose of
          transmission to your intended recipient.
          <br />
          &middot; User represents and warrant that you own or otherwise possess
          all necessary rights with respect to the User Submitted Materials, and
          that the User Submitted Materials do not and will not infringe,
          misappropriate, use or disclose without authorization or otherwise
          violate any copyright, trade secret right or other intellectual
          property or other property right of any third party, and that the User
          Submitted Materials are not unlawful, fraudulent, threatening,
          abusive, libelous, defamatory, obscene or otherwise objectionable.
          <br />
          &middot; User agrees to indemnify and hold us, our directors,
          officers, shareholders, employees, contractors, agents,
          representatives, affiliates, and third party users harmless from and
          against any and all claims, causes of action, liabilities, damages,
          losses, expenses and costs (including, without limitation,
          attorneys&rsquo; fees) that arise directly or indirectly out of or
          from: (i) your violation of these Terms, any other agreement or terms
          of use with us, any representation or warranty contained herein or
          therein or any applicable law; (ii) your User Submitted Materials;
          (iii) your activities in connection with obtaining any products or
          services from us, or (iv) any activity related to access to or use of
          your account by you or any other person.
          <br />
          &middot; User Submitted Materials that violate these Terms may be
          removed from our Site; provided, however, that we have no obligation
          to remove User Submitted Materials in response to user reports or
          requests. We are not responsible for, and will have no liability for,
          the removal, non-removal or loss of any User Submitted Materials from
          our Site. We recommend User keeps back-up copies of your User
          Submitted Materials on your hard drive or other personal system.
          <br />
          8. COPYRIGHT ISSUES
          <br />
          While we are not obligated to review User Submitted Materials for
          copyright infringement, we are committed to protecting copyrights and
          expect users of our SITE to do the same. The Digital Millennium
          Copyright Act of 1998 (the &ldquo;DMCA&rdquo;) provides recourse for
          copyright owners who believe that material appearing on the Internet
          infringes their rights under U.S. copyright law. If you believe in
          good faith that any material used or displayed on or through our SITE
          infringes your copyright, you (or your agent) may send us a notice
          requesting that the material be removed, or access to it blocked. The
          notice must include the following information: (a) a physical or
          electronic signature of a person authorized to act on behalf of the
          owner of an exclusive right that is allegedly infringed; (b)
          identification of the copyrighted work claimed to have been infringed
          (or if multiple copyrighted works are covered by a single
          notification, a representative list of such works); (c) identification
          of the material that is claimed to be infringing or the subject of
          infringing activity, and information reasonably sufficient to allow us
          to locate the material on our SITE; (d) the name, address, telephone
          number and email address (if available) of the complaining party; (e)
          a statement that the complaining party has a good faith belief that
          use of the material in the manner complained of is not authorized by
          the copyright owner, its agent or the law; and (f) a statement that
          the information in the notification is accurate and, under penalty of
          perjury, that the complaining party is authorized to act on behalf of
          the owner of an exclusive right that is allegedly infringed. If you
          believe in good faith that a notice of copyright infringement has been
          wrongly filed against you, the DMCA permits you to send us a
          counter-notice. Notices and counter-notices must meet the then-current
          statutory requirements imposed by the DMCA; see
          http://www.copyright.gov for details. DMCA notices and counter-notices
          regarding our SITE should be sent to:
          <br />
          AdoptConnect, Inc.
          <br />
          Attn: Legal
          <br />
          140 S. Lake Ave., Ste. 348
          <br />
          Pasadena, CA. 91001
          <br />
          Email: team@adoptconnect.com
          <br />
          9. DISCLAIMER OF WARRANTIES
          <br />
          EXCEPT FOR THE EXPRESS WARRANTIES CONTAINED IN THESE TERMS, OUR
          PRODUCTS AND SERVICES, INCLUDING ALL MATERIALS INCORPORATED THEREIN,
          ARE PROVIDED &ldquo;AS IS&rdquo; AND WITHOUT WARRANTIES OF ANY KIND,
          EITHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE BY
          APPLICABLE LAW, WE AND OUR DIRECTORS, OFFICERS, SHAREHOLDERS,
          EMPLOYEES, CONTRACTORS, AGENTS, REPRESENTATIVES AND AFFILIATES
          DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, WITHOUT
          LIMITATION, IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, ACCURACY,
          MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, AND ANY
          WARRANTIES THAT MAY ARISE FROM COURSE OF DEALING, COURSE OF
          PERFORMANCE OR USAGE OF TRADE. NOTE: CERTAIN APPLICABLE LAW MAY NOT
          ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO SOME OF THE ABOVE
          EXCLUSIONS MAY NOT APPLY TO YOU. NO STATEMENT OF OURS OR ANY OF OUR
          DIRECTORS, OFFICERS, SHAREHOLDERS, EMPLOYEES, CONTRACTORS, AGENTS,
          REPRESENTATIVES AND AFFILIATES SHALL CREATE ANY WARRANTY OTHER THAN
          THOSE EXPRESSLY CONTAINED IN THESE TERMS.
          <br />
          10. LIMITATION OF LIABILITY
          <br />
          TO THE FULLEST EXTENT PERMISSIBLE BY APPLICABLE LAW, NEITHER WE NOR
          ANY OF OUR DIRECTORS, OFFICERS, SHAREHOLDERS, EMPLOYEES, CONTRACTORS,
          AGENTS, REPRESENTATIVES, OR AFFILIATES (THE &ldquo;ADOPTCONNECT
          PARTIES&rdquo;) SHALL BE LIABLE FOR ANY INDIRECT, INCIDENTAL,
          CONSEQUENTIAL, SPECIAL, EXEMPLARY OR PUNITIVE DAMAGES (INCLUDING,
          WITHOUT LIMITATION, DAMAGES FOR LOSS OF BUSINESS, PROFITS, USE OR
          DATA), WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING, WITHOUT
          LIMITATION, NEGLIGENCE AND STRICT LIABILITY) OR ANY OTHER LEGAL
          THEORY, EVEN IF THE ADOPTCONNECT PARTIES HAVE BEEN ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES, ARISING OUT OF OR RELATING IN ANY WAY TO
          OUR PROVISION OF (OR FAILURE TO PROVIDE) PRODUCTS OR SERVICES, OR FROM
          UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR SUBMISSIONS OR DATA, EVEN
          IF A REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED ITS ESSENTIAL
          PURPOSE. YOU SPECIFICALLY ACKNOWLEDGE THAT THE ADOPTCONNECT PARTIES
          ARE NOT LIABLE FOR ANY DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF
          OTHER USERS OR THIRD PARTIES AND THAT THE RISK OF INJURY FROM THE
          FOREGOING RESTS ENTIRELY WITH YOU. FURTHERMORE, THE ADOPTCONNECT
          PARTIES WILL HAVE NO LIABILITY TO YOU OR TO ANY THIRD PARTY FOR ANY
          THIRD-PARTY CONTENT UPLOADED. YOUR SOLE AND EXCLUSIVE REMEDY FOR
          DISSATISFACTION WITH PRODUCTS IS TO OBTAIN A REFUND, AND YOUR SOLE AND
          EXCLUSIVE REMEDY FOR DISSATISFACTION WITH SERVICES IS TO STOP USING
          THE SERVICES. TO THE FULLEST EXTENT PERMISSIBLE BY APPLICABLE LAW, THE
          MAXIMUM LIABILITY OF THE ADOPTCONNECT PARTIES ARISING OUT OF OR
          RELATING IN ANY WAY TO OUR PROVISION OF (OR FAILURE TO PROVIDE)
          PRODUCTS OR SERVICES SHALL BE THE ACTUAL PRICE PAID THEREFORE BY YOU.
          NOTE: CERTAIN JURISDICTIONS MAY NOT ALLOW THE EXCLUSION OR LIMITATION
          OF INCIDENTAL, CONSEQUENTIAL OR CERTAIN OTHER TYPES OF DAMAGES, SO
          SOME OF THE ABOVE EXCLUSIONS OR LIMITATIONS MAY NOT APPLY TO YOU.
          <br />
          11. NOTICE FOR CALIFORNIA USERS
          <br />
          This notice is for our California users: The Complaint Assistance Unit
          of the Division of Consumer Services of the California Department of
          Consumer Affairs may be contacted by mail at:
          <br />
          1625 North Market Blvd.
          <br />
          Sacramento, CA 95834
          <br />
          or by telephone at (916) 445-1254 or (800) 952-5210.
          <br />
          12. INTELLECTUAL PROPERTY NOTICES
          <br />
          AdoptTech LLC is a corporation registered in California, trademark
          pending. All trademarks and service marks on any of our Site not owned
          by us are the property of their respective owners. The trade names,
          trademarks and service marks owned by us, whether registered or
          unregistered, may not be used in connection with any product or
          service that is not ours, in any manner that is likely to cause
          confusion. Nothing contained on any of our Site should be construed as
          granting, by implication, estoppel or otherwise, any license or right
          to use any of our trade names, trademarks or service marks without our
          express prior written consent.
          <br />
          13. JURISDICTIONAL ISSUES
          <br />
          Our Site is controlled and operated from the United States. Our Site
          is not subject to the laws or jurisdiction of any state, country or
          territory other than that of the United States. We do not represent or
          warrant that any of our Site, products, and/or services or any part
          thereof is appropriate or available for use in any particular
          jurisdiction. Those who choose to access AdoptConnect do so on their
          own initiative and at their own risk, and are responsible for
          complying with all applicable laws, rules and regulations. We may
          limit the availability of our Site, in whole or in part, to any
          person, geographic area or jurisdiction we choose, at any time and in
          our sole discretion.
          <br />
          14. MISCELLANEOUS
          <br />
          These Terms are governed by and construed in accordance with the laws
          of the State of California, United States of America, without regard
          to its conflict of law provisions. By using this Site, You agree to
          submit to the exclusive jurisdiction of any State or Federal court
          located in the County of Los Angeles, California, United States of
          America, and waive any jurisdictional, venue or inconvenient forum
          objections to such courts. If any provision of these Terms, or the
          application thereof to any person, place or circumstance, will be held
          by a court of competent jurisdiction to be invalid, void or otherwise
          unenforceable, such provision will be enforced to the maximum extent
          possible, or, if incapable of such enforcement, will be deemed to be
          deleted from these Terms, and the remainder of these Terms and such
          provisions as applied to other persons, places and circumstances will
          remain in full force and effect. No waiver by either party of any
          breach or default hereunder will be deemed to be a waiver of any
          preceding or subsequent breach or default, nor will any delay or
          omission on the part of either party to exercise or avail itself of
          any right or remedy that it has or may have hereunder operate as a
          waiver of any right or remedy. This is the entire agreement between us
          relating to the subject matter herein and supersedes any and all prior
          or contemporaneous written or oral agreements between us with respect
          to such subject matter. These Terms are not assignable, transferable
          or sublicenseable by you except with our prior written consent. These
          Terms may not be modified or amended except as set forth in the
          introductory section of these Terms. Any heading, caption or section
          title contained in these Terms is inserted only as a matter of
          convenience and in no way defines or explains any section or provision
          hereof. If you have any questions or comments regarding these Terms,
          please contact: team@adoptconnect.com.
          <br />
          15. TERMINATION
          <br />
          Either you or we may terminate your access to our Site and to your
          account at any time, with or without cause, and with or without prior
          notice. Without limiting the foregoing, we may terminate your access
          if you violate these Terms. Upon termination for any reason, your
          right to access and/or use our Site will immediately cease.
          <br />
          Account termination may result in destruction of any Content
          associated with your account, so keep that in mind before you decide
          to terminate your account. We will try to provide advance notice to
          you prior to our terminating your account so that you are able to
          retrieve any important User Submitted Materials you may have stored in
          your account (to the extent allowed by law and these Terms), but we
          may not do so if we determine it would be impractical, illegal, not in
          the interest of someone&rsquo;s safety or security, or otherwise
          harmful to the rights or property of AdoptConnect.
          <br />
          Provisions that, by their nature, should survive termination of these
          Terms shall survive termination. By way of example, all of the
          following will survive termination: any obligation you have to pay us
          or indemnify us, any limitations on our liability, any terms regarding
          ownership or intellectual property rights, and terms regarding
          disputes between us.
          <br />
          16. ARBITRATION AGREEMENT
          <br />
          If you are a AdoptConnect customer in the United States (including its
          possessions and territories), you and AdoptConnect agree that any
          dispute, claim or controversy arising out of or relating in any way to
          the AdoptConnect service, these Terms and Conditions and this
          Arbitration Agreement, shall be determined by binding arbitration or
          in small claims court. Arbitration is more informal than a lawsuit in
          court. Arbitration uses a neutral arbitrator instead of a judge or
          jury, allows for more limited discovery than in court, and is subject
          to very limited review by courts. Arbitrators can award the same
          damages and relief that a court can award. You agree that, by agreeing
          to these Terms and Conditions, the U.S. Federal Arbitration Act
          governs the interpretation and enforcement of this provision, and that
          you and AdoptConnect are each waiving the right to a trial by jury or
          to participate in a class action. This arbitration provision shall
          survive termination of this Agreement and the termination of your
          AdoptConnect account.
          <br />
          If you elect to seek arbitration or file a small claim court action,
          you must first send to AdoptConnect, by certified mail, a written
          Notice of your claim (&ldquo;Notice&rdquo;). The Notice to
          AdoptConnect must be addressed to: General Counsel, AdoptConnect,
          Inc., 140 S. Lake Ave., Ste. 348, Pasadena, CA 91001 (&ldquo;Notice
          Address&rdquo;). If AdoptConnect initiates arbitration, it will send a
          written Notice to the email address used for your account. A Notice,
          whether sent by you or by AdoptConnect, must (a) describe the nature
          and basis of the claim or dispute; and (b) set forth the specific
          relief sought (&ldquo;Demand&rdquo;). If AdoptConnect and you do not
          reach an agreement to resolve the claim within 30 days after the
          Notice is received, you or AdoptConnect may commence an arbitration
          proceeding or file a claim in small claims court.
          <br />
          You may download or copy a form Notice and a form to initiate
          arbitration at www.adr.org. If you are required to pay a filing fee,
          after AdoptConnect receives notice at the Notice Address that you have
          commenced arbitration, AdoptConnect will promptly reimburse you for
          your payment of the filing fee, unless your claim is for greater than
          US$10,000, in which event you will be responsible for filing fees. The
          arbitration will be governed by the Commercial Arbitration Rules and
          the Supplementary Procedures for Consumer Related Disputes
          (collectively, &ldquo;AAA Rules&rdquo;) of the American Arbitration
          Association (&ldquo;AAA&rdquo;), as modified by this Agreement, and
          will be administered by the AAA. The AAA Rules and Forms are available
          online at www.adr.org, by calling the AAA at 1-800-778-7879, or by
          writing to the Notice Address. The arbitrator is bound by the terms of
          this Agreement. All issues are for the arbitrator to decide, including
          issues relating to the scope and enforceability of this arbitration
          agreement. Unless AdoptConnect and you agree otherwise, any
          arbitration hearings will take place in the county of your residence.
          <br />
          If your claim is for US $10,000 or less, we agree that you may choose
          whether the arbitration will be conducted solely on the basis of
          documents submitted to the arbitrator, through a telephonic hearing,
          or by an in-person hearing as established by the AAA Rules. If your
          claim exceeds US$10,000, the right to a hearing will be determined by
          the AAA Rules. Regardless of the manner in which the arbitration is
          conducted, the arbitrator shall issue a reasoned written decision
          sufficient to explain the essential findings and conclusions on which
          the award is based. If the arbitrator issues you an award that is
          greater than the value of AdoptConnect&rsquo;s last written settlement
          offer made before an arbitrator was selected (or if AdoptConnect did
          not make a settlement offer before an arbitrator was selected), then
          AdoptConnect will pay you the amount of the award or US$1,000,
          whichever is greater. Except as expressly set forth herein, the
          payment of all filing, administration and arbitrator fees will be
          governed by the AAA Rules.
          <br />
          YOU AND ADOPTCONNECT AGREE THAT EACH MAY BRING CLAIMS AGAINST THE
          OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF
          OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING.
          Further, unless both you and AdoptConnect agree otherwise, the
          arbitrator may not consolidate more than one person&rsquo;s claims
          with your claims, and may not otherwise preside over any form of a
          representative or class proceeding. If this specific provision is
          found to be unenforceable, then the entirety of this arbitration
          provision shall be null and void. The arbitrator may award declaratory
          or injunctive relief only in favor of the individual party seeking
          relief and only to the extent necessary to provide relief warranted by
          that party&rsquo;s individual claim. <br />
        </p>
      </section>
    </div>
  );
};

termsAndConditions.propTypes = {};

export default termsAndConditions;
