import React from 'react';
import PostItem from '../posts/PostItem';
import './Dashboard.scss';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

const PostViewer = ({ posts }) => {
  return (
    <div>
      <Row>
        {posts.map((post) => (
          <Col md="4" xs="12" key={post._id}>
            <PostItem post={post} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

PostViewer.propTypes = {
  posts: PropTypes.object.isRequired
};

export default PostViewer;
