import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AddEvent from './SaveEvent';
import {
  getEvent,
  getEvents,
  getConnectionEvents,
  getUserEvents,
  createEvent,
  deleteEvent
} from '../../actions/event';
import Helmet from 'react-helmet';
import {
  Spinner,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from 'reactstrap';
import FullCalendar from '@fullcalendar/react';
import listPlugin from '@fullcalendar/list';
import rrulePlugin from '@fullcalendar/rrule';
import './Calendar.scss';
import Moment from 'react-moment';

const Calendar = ({
  getEvents,
  getConnectionEvents,
  getUserEvents,
  getEvent,
  deleteEvent,
  events: { userEvents: events, connectionEvents, event },
  user,
  auth
}) => {
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    getEvents();
  }, [getEvents]);

  useEffect(() => {
    getUserEvents();
  }, [getUserEvents]);

  useEffect(() => {
    getConnectionEvents();
  }, [getConnectionEvents]);

  function toggleModal() {
    setSelected(!selected);
  }

  function eventDelete(id) {
    setSelected(false);
    deleteEvent(id);
  }

  function handleDateClick(arg) {
    getEvent(arg.event.id);
    setSelected(true);
  }

  if (
    !user.loading &&
    !events.loading &&
    !events.userEventsLoading &&
    !events.connectionEventsLoading
  ) {
    return (
      <>
        <Helmet>
          <title>Adopt Connect - Upcoming Reminders</title>
        </Helmet>
        <section className="text-center">
          <Modal isOpen={selected} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>
              <h4>Event Details</h4>
            </ModalHeader>
            <ModalBody style={{ paddingTop: '0px' }}>
              <h4 style={{ marginTop: '5px' }}>{event.title}</h4>
              <p>
                {event.note === '' ? (
                  'No Note'
                ) : (
                  <Fragment>Note: {event.note}</Fragment>
                )}
              </p>
              <p>
                {event.recurring ? (
                  <Fragment>
                    {' '}
                    {event.rrule?.interval ? (
                      <Fragment>
                        Recurring every {event.rrule?.interval} months beginning
                        on{' '}
                        <Moment format="MM/DD/YYYY">
                          {event.rrule?.dtstart}
                        </Moment>{' '}
                        until{' '}
                        {event.rrule?.until ? (
                          <Moment format="MM/DD/YYYY">
                            {event.rrule?.until}
                          </Moment>
                        ) : (
                          'forever'
                        )}
                      </Fragment>
                    ) : (
                      'Not Recurring'
                    )}{' '}
                  </Fragment>
                ) : (
                  'Not Recurring'
                )}
              </p>
              {auth.user.subscriptionCreation === 'agency' ? null : (
                <Button onClick={(e) => eventDelete(event._id)} color="red">
                  Delete Event
                </Button>
              )}
            </ModalBody>
            <ModalFooter>
              <Button onClick={toggleModal}>Close</Button>
            </ModalFooter>
          </Modal>
          <Card className="col-md-8">
            <div className="spacer-top"> </div>
            <h2>My Reminders</h2>
            <CardHeader>
              {user.subscriptionCreation === 'agency' ? null : <AddEvent />}
            </CardHeader>
            <CardBody>
              <FullCalendar
                defaultView="listYear"
                timeZone="UTC"
                plugins={[listPlugin, rrulePlugin]}
                events={events}
                eventClick={(arg) => handleDateClick(arg)}
                noEventsMessage="No reminders to display"
              />
            </CardBody>
          </Card>
        </section>
      </>
    );
  } else {
    return (
      <center>
        <Spinner color="blue"></Spinner>
        <h4>Loading Events</h4>
      </center>
    );
  }
};

Calendar.propTypes = {
  auth: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  events: PropTypes.object.isRequired,
  getEvent: PropTypes.func.isRequired,
  getEvents: PropTypes.func.isRequired,
  getUserEvents: PropTypes.func.isRequired,
  getConnectionEvents: PropTypes.func.isRequired,
  createEvent: PropTypes.func.isRequired,
  deleteEvent: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user,
  events: state.events,
  auth: state.auth
});

export default connect(mapStateToProps, {
  getEvents,
  getEvent,
  deleteEvent,
  getUserEvents,
  getConnectionEvents,
  createEvent
})(Calendar);
