import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import './Payment.scss';
import PropTypes from 'prop-types';

// stripe.com/docs/stripe-js/react#element-components
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
};

function CardSection(props) {
  function handleChange(event) {
    props.onChange(event);
  }

  return (
    <div
      className="mx-auto"
      style={{ width: '60%', minWidth: 320, maxWidth: 800 }}
    >
      <CardElement options={CARD_ELEMENT_OPTIONS} onChange={handleChange} />
    </div>
  );
}

CardSection.propTypes = {
  onChange: PropTypes.object.isRequired
};

export default CardSection;
