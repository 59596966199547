import axios from 'axios';
import { GET_BLOG_POST, GET_BLOG_POSTS, BLOG_POST_ERROR } from './types';
import { BLOG_URL } from '../config/default.json';

// Get Blog Posts
export const getBlogPosts = () => async (dispatch) => {
  try {
    const res = await axios.get(`${BLOG_URL}/blogs/`);

    dispatch({
      type: GET_BLOG_POSTS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: BLOG_POST_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status
      }
    });
  }
};

// Get blog post by ID
export const getBlogPostByID = (slug) => async (dispatch) => {
  try {
    const res = await axios.get(`${BLOG_URL}/blogs?slug=${slug}`);

    dispatch({
      type: GET_BLOG_POST,
      payload: res.data[0]
    });
  } catch (err) {
    dispatch({
      type: BLOG_POST_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status
      }
    });
  }
};
