import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import {
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import { Link } from 'react-router-dom';

import hiw1 from '../../assets/img/hiw1.png';
import hiw2 from '../../assets/img/hiw2.png';
import hiw3 from '../../assets/img/hiw3.png';
import hiw4 from '../../assets/img/hiw4.png';
import hiw5 from '../../assets/img/hiw5.png';
import hiwBackground from '../../assets/img/hiwBackground.png';
import NewsletterSignup from '../forms/newsletterSignup';

const HowItWorks = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  var mainSectionStyle = {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${hiwBackground})`
  };

  return (
    <Fragment>
      <Helmet>
        <title>Adopt Connect - Get Informed</title>
      </Helmet>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}></ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12">
              <Link to="/how-it-works-adoptive-parent">
                <Button
                  className="ralewayBold btn-block"
                  style={{
                    color: 'black',
                    backgroundColor: 'white',
                    border: '1px solid black'
                  }}
                  onClick={toggle}
                >
                  I'm an Adoptive Parent
                </Button>
              </Link>
            </div>
            <div className="col-md-12">
              <Link to="/how-it-works-birth-parent">
                <Button
                  className="ralewayBold btn-block"
                  style={{
                    color: 'black',
                    backgroundColor: 'white',
                    border: '1px solid black'
                  }}
                  onClick={toggle}
                >
                  I'm a Birth Parent
                </Button>
              </Link>
            </div>
            <div className="col-md-12">
              <Link to="/how-it-works-adoption-professional">
                <Button
                  className="ralewayBold btn-block"
                  style={{
                    color: 'black',
                    backgroundColor: 'white',
                    border: '1px solid black'
                  }}
                  onClick={toggle}
                >
                  I'm an Adoption Professional
                </Button>
              </Link>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <div style={mainSectionStyle} className="banner">
        <h1 className="banner banneroverlay">How it Works</h1>
      </div>
      <section className="container sec-pad-adjust">
        <div className="spacer"></div>
        <div className="row">
          <Col>
            <img className="hiwImg" src={hiw1} alt=""></img>
            <p className="hiwTxt">
              Adoptive parents sign up for an Individual or Partner* account and
              invite Birth Parents to create a free account.
            </p>
            <img className="hiwImg1" src={hiw3} alt=""></img>
            <p className="hiwTxt">
              AdoptConnect sends friendly reminders to Adoptive Parents when
              their updates are coming due.*
            </p>
            <img className="hiwImg1" src={hiw5} alt=""></img>
            <p className="hiwTxt">
              Birth Parents are notified each time something new is posted to
              their AdoptConnect Timeline.©
            </p>
          </Col>
          <Col>
            <img className="hiwImg2" src={hiw2} alt=""></img>
            <p className="hiwTxt">
              AdoptConnect links the Adoptive Parent and Birth Parent accounts.{' '}
            </p>
            <img className="hiwImg2" src={hiw4} alt=""></img>
            <p className="hiwTxt">
              Adoptive Parents post photos, videos and updates to share with
              their child’s Birth Parents.
            </p>
            <p className="hiwTxt small-grey-text">
              *Individual Accounts are created by Adoptive Parents directly.{' '}
              <br />
              *Professional Accounts are initiated by your Adoption
              Professional. <br />
              *Adoption Professionals initiate Partner Accounts and are notified
              if update isn’t posted.
            </p>
          </Col>
        </div>
        <div>
          <h4
            className="panel-header-2"
            style={{
              fontSize: '3.4em',
              padding: '0% 5% 0% 5%',
              color: '#47604e'
            }}
          >
            Ready to Connect?
          </h4>
          <center>
            <Button
              onClick={toggle}
              color="#47604e"
              className="ralewayBold"
              style={{
                backgroundColor: 'rgb(231, 88, 51)',
                color: 'white',
                width: '300px',
                height: '60px',
                fontSize: '3.4em',
                border: '4px solid #F6916C',
                borderRadius: '10px',
                padding: '0px 20px'
              }}
            >
              <div style={{ letterSpacing: '3px' }}>GET STARTED</div>
            </Button>
          </center>
        </div>
      </section>
      <div style={{ margin: '60px 0px 0px 0px' }}>
        <hr width="60%" />
        <NewsletterSignup />
      </div>
    </Fragment>
  );
};

HowItWorks.propTypes = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(HowItWorks);
