import React, { Fragment, useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getPosts } from '../../actions/post';
import Spinner from '../layout/Spinner';
import PostItem from '../posts/PostItem';
import Toaster from '../../functions/Toaster';
import { Card, CardBody, CardHeader, Button } from 'reactstrap';
import PostForm from '../posts/PostForm';
import VideoPostForm from '../posts/VideoPostForm';
import { getEvents } from '../../actions/event';
import FullCalendar from '@fullcalendar/react';
import listPlugin from '@fullcalendar/list';
import rrulePlugin from '@fullcalendar/rrule';
import { getCurrentProfile } from '../../actions/profile';

const Timeline = ({
  getPosts,
  getEvents,
  getCurrentProfile,
  post: { posts, loading },
  auth: { user, userLoading },
  events,
  profile,
  auth
}) => {
  const [limit, setLimit] = useState(5);

  let visiblePosts = posts.slice(0, limit);

  function viewMore() {
    setLimit(limit + 5);
  }

  useEffect(() => {
    getPosts();
    getEvents();
    getCurrentProfile();
  }, [getPosts, getEvents, getCurrentProfile]);

  if (
    !loading &&
    !userLoading &&
    auth.isAuthenticated &&
    user.subscriptions.subscriptionActive === false
  ) {
    return <Redirect to={'/account/payment'} />;
  }

  return loading || userLoading || events?.loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <Helmet>
        <title>AdoptConnect - My Timeline</title>
        <meta name="description" content="Adopt Connect Blog" />
      </Helmet>
      <Toaster />
      <section className="container sec-pad-adjust">
        <h1
          className="panel-header"
          style={{ fontSize: '2.3em', padding: '0px 0px', textAlign: 'left' }}
        >
          My AdoptConnect Timeline
        </h1>
        <div className="row">
          <div className="col-md-6 col-xs-12 col-sm-12">
            {posts.length > 0 ? (
              <>
                {visiblePosts.map((post) => (
                  <div key={post._id} id={`#${post._id}`}>
                    <PostItem post={post} />
                  </div>
                ))}

                {visiblePosts.length >= posts.length ? (
                  <div style={{ marginBottom: 400 }}></div>
                ) : (
                  <center>
                    <Button
                      style={{ backgroundColor: '#47604e', marginBottom: 400 }}
                      onClick={viewMore}
                      active
                    >
                      <i
                        className="now-ui-icons ui-1_simple-add pl-1"
                        style={{ backgroundColor: '#47604e' }}
                      ></i>{' '}
                      View More Posts
                    </Button>
                  </center>
                )}
              </>
            ) : (
              <Card className="timeline-card">
                <CardBody className="px-3">
                  <h4>Welcome to AdoptConnect!</h4>
                  {user.accountType === 'birthParent' &&
                  user.settings?.twowaycommunication === false ? null : (
                    <h5>
                      You haven't posted on your timeline yet. To get started,
                      select "Add Post" or "Add Video".
                    </h5>
                  )}
                </CardBody>
              </Card>
            )}
          </div>
          <div className="col-md-4 col-xs-12 col-sm-12 order-first order-md-last">
            <Card>
              <CardHeader>
                <div
                  className="d-inline-flex post-user-info"
                  style={{ padding: '10px 10px' }}
                >
                  <div className="circular">
                    <img
                      className=""
                      width="10%"
                      src={user?.profileImage}
                      alt=""
                    />
                  </div>
                  <Link to="/dashboard">
                    <h6 className="post-user-name">{user?.name}</h6>
                  </Link>
                </div>
              </CardHeader>
              <hr></hr>
              <CardBody>
                {auth.user?.accountType === 'birthParent' &&
                auth.user?.settings?.twowaycommunication === false ? null : (
                  <>
                    <PostForm /> <VideoPostForm />
                  </>
                )}
              </CardBody>
            </Card>
            {auth.user?.accountType === 'birthParent' ? null : (
              <Card className="timelineCalendar">
                <CardHeader>
                  <div
                    className="timeline-user-info"
                    style={{ flex: '1 1 auto', paddingLeft: '1.25em' }}
                  >
                    <strong>Upcoming Reminders (90-Day Snapshot)</strong>
                  </div>
                  {user.subscriptionCreation === 'agency' ? null : (
                    <div style={{ flex: '1 1 auto', paddingLeft: '1.25em' }}>
                      <Link to="/calendar">
                        <Button
                          type="button"
                          style={{ backgroundColor: '#47604e' }}
                        >
                          <i
                            className="now-ui-icons ui-1_simple-add pl-1"
                            style={{ backgroundColor: '#47604e' }}
                          ></i>{' '}
                          Add Reminder
                        </Button>
                      </Link>
                    </div>
                  )}

                  <div>
                    <FullCalendar
                      defaultView="list"
                      views={{
                        list: {
                          type: 'list',
                          duration: { months: 3 }
                        }
                      }}
                      timeZone="UTC"
                      plugins={[listPlugin, rrulePlugin]}
                      events={events}
                      noEventsMessage="No reminders to display"
                      header={false}
                      listDayAltFormat={true}
                      eventBorderColor="white"
                      height="auto"
                    />
                  </div>
                </CardHeader>
              </Card>
            )}
          </div>
        </div>
      </section>
    </Fragment>
  );
};

Timeline.propTypes = {
  isAuthenticated: PropTypes.bool,
  getPosts: PropTypes.func.isRequired,
  getEvents: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  events: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  auth: state.auth,
  post: state.post,
  profile: state.profile,
  events: state.events
});

export default connect(mapStateToProps, {
  getPosts,
  getEvents,
  getCurrentProfile
})(Timeline);
