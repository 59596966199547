import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { requestDemoAccount } from '../../actions/demo';
import PropTypes from 'prop-types';
import {
  Card,
  CardBody,
  Form,
  Input,
  InputGroup,
  Button,
  Label,
  FormGroup
} from 'reactstrap';
import './Registration.scss';

const AgencyDemo = ({ requestDemoAccount, isAuthenticated, session }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: ''
  });

  const { firstName, lastName, email } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const [firstFocus, setFirstFocus] = React.useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    requestDemoAccount({
      firstName,
      lastName,
      email
    });
  };

  if (isAuthenticated) {
    return <Redirect to="/timeline" />;
  }

  if (session.demoRequest !== null) {
    return (
      <Fragment>
        <div className="fullPage">
          <div className="text-center form-container">
            <Card className="form-card">
              <CardBody>
                <h3 className="ac-text">
                  Thank you for submitting a request for us to set up a new
                  AdoptConnect account. You will receive an email shortly with
                  instructions about how to activate your account and begin
                  connecting!
                </h3>
              </CardBody>
            </Card>
          </div>
        </div>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <div className="fullPage">
          <div className="text-center form-container">
            <Card className="form-card">
              <CardBody>
                <Form
                  className="form registration-form center-form"
                  onSubmit={(e) => onSubmit(e)}
                >
                  <center>
                    <h1
                      className="panel-header"
                      style={{ paddingBottom: '0px' }}
                    >
                      Request an Account
                    </h1>
                    <p className="lead">
                      <i
                        className="fas fa-user"
                        style={{ paddingTop: '0px' }}
                      ></i>{' '}
                      Thank you for previewing AdoptConnect. <br /> Please enter
                      your information below to receive an account activation
                      link.
                    </p>
                    <div className="separator" style={{ paddingTop: '0px' }}>
                      Your Info
                    </div>
                    <FormGroup>
                      <Label className="inputLabel">First Name</Label>
                      <InputGroup
                        className={
                          'no-border Input-lg registration-input-group' +
                          (firstFocus ? ' Input-group-focus' : '')
                        }
                      >
                        <Input
                          onFocus={() => setFirstFocus(true)}
                          onBlur={() => setFirstFocus(false)}
                          type="text"
                          name="firstName"
                          id="apFirstName"
                          value={firstName}
                          onChange={(e) => onChange(e)}
                          required
                        />
                      </InputGroup>
                    </FormGroup>
                    <Label className="inputLabel">Last Name</Label>
                    <InputGroup
                      className={'no-border Input-lg registration-input-group'}
                    >
                      <Input
                        type="text"
                        name="lastName"
                        value={lastName}
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </InputGroup>
                    <Label className="inputLabel">Email Address</Label>
                    <InputGroup
                      className={'no-border Input-lg registration-input-group'}
                    >
                      <Input
                        onFocus={() => setFirstFocus(true)}
                        onBlur={() => setFirstFocus(false)}
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => onChange(e)}
                        required
                      ></Input>
                    </InputGroup>
                    <InputGroup
                      className={'no-border Input-lg registration-input-group'}
                    ></InputGroup>
                    <hr style={{ width: '70%' }} />
                    <div className="row">
                      <div className="col">
                        <Link to="/">
                          <Button
                            className="btn btn-gray checkout-button"
                            value="Cancel"
                          >
                            Cancel
                          </Button>
                        </Link>
                      </div>
                      <div className="col">
                        <Button
                          type="submit"
                          className="btn btn-primary checkout-button btn-ac-green"
                          value="Submit and Check Out >>"
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  </center>
                </Form>
              </CardBody>
            </Card>
          </div>
        </div>
      </Fragment>
    );
  }
};

AgencyDemo.propTypes = {
  setAlert: PropTypes.func.isRequired,
  requestDemoAccount: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  session: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  session: state.session
});

export default connect(mapStateToProps, { requestDemoAccount })(AgencyDemo);
