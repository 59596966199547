import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { USER_LOADED } from '../actions/types';
import { loadUser } from '../actions/auth';

const useUser = (userState) => {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const mutate = React.useCallback(
    (state) => {
      dispatch({
        type: USER_LOADED,
        payload: state
      });
    },
    [dispatch]
  );

  const updateUser = React.useCallback(() => {
    dispatch(loadUser());
  }, [dispatch]);

  if (userState) {
    mutate(userState);
  }

  return { user, mutate, updateUser };
};

export default useUser;
