import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

const GetStarted = () => {
  var mainSectionStyle = {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url('https://res.cloudinary.com/puget-sound-networks/image/upload/f_auto/v1607150682/AC-Web-Assets/get-started_gdvait.jpg')`
  };

  return (
    <Fragment>
      <Helmet>
        <title>Adopt Connect - Get Started</title>
      </Helmet>
      <div style={mainSectionStyle} className="banner">
        <h1 className="banner">
          <img
            className="banneroverlay"
            src="https://res.cloudinary.com/puget-sound-networks/image/upload/f_auto/v1607150682/AC-Web-Assets/GetStartedBanner_sqe88u.png"
            alt="Get Started Banner Overlay"
          ></img>
        </h1>
      </div>
      <section className="container sec-pad-adjust">
        <div className="spacer"></div>
        <center>
          <div style={{ width: '70%' }}>
            <p className="lead">
              <i className="fas fa-user">
                “I like that I receive notifications from AdoptConnect when each
                post is uploaded by my daughter’s parents. I can choose when I
                want to open it or save it for later.” <br></br>
              </i>
            </p>
            <p className="" style={{ textAlign: 'right' }}>
              -Jessica, Birth Mom
            </p>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="boxed boxed--border  bg--secondary boxed--lg box-shadow box-plans">
                <h2>Annual</h2>
                <h4>Share photos and updates for just $51/year.</h4>
                <Link to="/register/annualmember">
                  <Button
                    to="/register/annualmember"
                    className="btn-lg ac-button"
                  >
                    Get Started
                  </Button>
                </Link>
              </div>
            </div>
          </div>
          <hr />
          <div className="col-md-8">
            <h3>Features</h3>
            <p className="gs_feature_text">
              <i className="material-icons md-24 gs-icon">check</i>Always free
              for Birth Parents. After signing up Birth Parents will be emailed
              a link to create their connected account where they can view
              photos and updates at any time of their choosing.
            </p>
            <p className="gs_feature_text">
              <i className="material-icons md-24 gs-icon">check</i>Sharing
              Photos and Updates. Adoptive Parents can post photos and updates
              that Birth Parents can login to view at any time of their
              choosing.
            </p>
            <p className="gs_feature_text">
              <i className="material-icons md-24 gs-icon">check</i>Video
              uploading capabilities. Adoptive Parents have the capacity to
              upload and share an unlimited number of videos (maximum size
              1GB/video).
            </p>
            <p className="gs_feature_text">
              <i className="material-icons md-24 gs-icon">check</i>Lasting,
              Secure Storage. All videos, pictures and updates are stored on a
              secure server and can be accessed by both Adoptive and Birth
              Parents for years to come.
            </p>
            <p className="gs_feature_text">
              <i className="material-icons md-24 gs-icon">check</i>Calendar
              Reminders. AdoptConnect’s Calendar enables users to schedule
              reminders for upcoming due dates for sharing updates and special
              occasions.
            </p>
            <p className="gs_feature_text">
              <i className="material-icons md-24 gs-icon">check</i>How we give
              back. 5% of all proceeds from AdoptConnect Lifetime Memberships
              will be donated to our AdoptConnect Marketplace Beneficiaries
              consisting of adoptive parent and birth parent support
              organizations. Don’t forget to also shop the AdoptConnect
              Marketplace where 25% of all proceeds are donated to the same
              organizations.{' '}
            </p>
            <p className="gs_feature_text">
              <i className="material-icons md-24 gs-icon">check</i>Contact your
              adoption agency to ask about our discount code for annual and/or
              lifetime subscriptions.{' '}
            </p>
          </div>
          <hr></hr>
          <h3>
            <b>Just Sign Up, Post and Share. That's it!</b>
          </h3>
          <hr></hr>
        </center>
      </section>
    </Fragment>
  );
};

GetStarted.propTypes = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(GetStarted);
